import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { get_storage } from '../../helpers'
import Wrapper from '../layout/Wrapper'
import SubNav from '../SubNav'

const AiWrapper = () => {
  const { pathname, state } = useLocation()
  const is_vbc = get_storage('uuid')
  const get_url = () => {
    if (is_vbc) {
      return '/card'
    } else if (pathname !== '/user/marketing-lobby') {
      return '/user/marketing-lobby'
    } else {
      return '/user'
    }
  }
  return (
    <Wrapper>
      <SubNav />
      <Outlet />
    </Wrapper>
  )
}

export default AiWrapper
