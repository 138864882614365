import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useVBCs } from '../../../contexts/VBCContext'
import {
  formatPhoneNumber,
  set_storage,
  validate_number,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const ShareAll = ({ state, setState, setShowModal }) => {
  const navigate = useNavigate()
  const { VBCs } = useVBCs()
  const { axiomFetch } = useAxiomFetch()
  const [loading, setLoading] = useState()
  useEffect(() => {
    setState((prev) => {
      const event_tour_info = prev?.data?.event_tour_info?.map((item) => {
        if (
          !item?.mls_num &&
          validate_number(item?.cont_id) > 0 &&
          item?.evt_id?.toString() ===
            (state?.buyer_tours?.home_edit?.event_id?.toString() ||
              state?.buyer_tours?.event_edit?.event_id?.toString())
        ) {
          item.send_all = 'Y'
        }
        return item
      })

      set_storage('listing_tours', {
        ...prev?.data,
        event_tour_info,
      })
      return {
        ...prev,
        data: { ...prev?.data, event_tour_info },
      }
    })
  }, [])

  const buyers = state?.data?.event_tour_info
    ?.filter(
      (item) =>
        !item?.mls_num &&
        validate_number(item?.cont_id) > 0 &&
        item?.evt_id?.toString() ===
          (state?.buyer_tours?.home_edit?.event_id?.toString() ||
            state?.buyer_tours?.event_edit?.event_id?.toString())
    )
    ?.sort(
      (a, b) => validate_number(a?.tour_order) - validate_number(b?.tour_order)
    )

  console.log('buyers', buyers)

  const share_vbcs = async () => {
    setLoading(true)
    const vbc = VBCs?.cards?.find((item) => item?.RECEIVER_TYPE_ID === 2)
    const sendId = vbc?.ID
    for (const buyer of buyers) {
      if (buyer.send_all === 'Y') {
        let body = {
          cellPhone: buyer?.agent_cell_phone?.toString(),
          name: buyer?.agent_name || '',
          person_type_id: vbc?.RECEIVER_TYPE_ID,
          origin_id: vbc?.ORIGIN_ID,
          market_uuids: '',
          vbc_msg: `Hey ${
            buyer?.agent_name || ''
          } Check out my custom Realty Smart Business Card where I have your tour of homes ready to go.`,
          no_vbc: false,
          send_tel_link: false,
        }
        await axiomFetch(`/products/vbc/${sendId}/send`, 'POST', body)
      }
    }
    setLoading(false)
    return navigate('/user/buyer_tours')
  }

  return (
    <div className={'flex flex-col justify-between h-[495px]'}>
      <div>
        <h1>
          {!buyers?.length
            ? 'Make sure to add buyers for this tour!'
            : 'Would you like to share the tour with your Buyers?'}
        </h1>
        <div className='flex flex-col gap-[10px]'>
          {buyers?.map((item) => {
            return (
              <div
                key={item.cont_id + item?.evt_id}
                className={`${uiStyles.card_gray_gradient} relative cursor-pointer`}
                onClick={() => {
                  console.log(
                    'test',
                    item,
                    item?.evt_id,
                    state?.buyer_tours?.home_edit?.event_id,
                    state?.buyer_tours?.event_edit?.event_id
                  )
                  setState((prev) => {
                    const event_tour_info = [
                      ...(prev?.data?.event_tour_info || [])?.filter(
                        (filter_item) =>
                          !(
                            filter_item?.evt_id === item?.evt_id &&
                            filter_item?.cont_id === item?.cont_id
                          )
                      ),
                      { ...item, send_all: item?.send_all === 'Y' ? 'N' : 'Y' },
                    ]

                    set_storage('listing_tours', {
                      ...prev?.data,
                      event_tour_info,
                    })
                    return {
                      ...prev,
                      data: { ...prev?.data, event_tour_info },
                    }
                  })
                }}
              >
                <div className='absolute right-0 top-[5px]'>
                  <div className='flex items-center justify-center'>
                    <div
                      className={`h-[16px] w-[16px] rounded-full bg-gradient-to-br ${
                        item?.send_all === 'Y'
                          ? 'from-accent-600 to-accent-150'
                          : 'from-gray-400 to-gray-400'
                      } p-[1px]`}
                    >
                      <div className='flex h-[14px] w-[14px] rounded-full items-center justify-center'>
                        {item?.send_all === 'Y' ? (
                          <svg
                            width='10'
                            height='8'
                            viewBox='0 0 10 8'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9 1L3.5 6.5L1 4'
                              stroke='url(#paint0_linear_1338_30152)'
                              strokeWidth='1.6666'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_1338_30152'
                                x1='-1.58407'
                                y1='2.77655'
                                x2='1.30771'
                                y2='9.28756'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#fff' />
                                <stop offset='1' stopColor='#fff' />
                              </linearGradient>
                            </defs>
                          </svg>
                        ) : (
                          <div className='h-full w-full rounded-full bg-gradient-to-br from-white to-white'></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p>Name: {item?.agent_name} </p>
                <p>Phone: {`${formatPhoneNumber(item?.agent_cell_phone)}`}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className='flex justify-end gap-[10px]'>
        <button
          className={uiStyles.white_btn}
          onClick={() => {
            return navigate('/user/buyer_tours')
          }}
        >
          Done For Now
        </button>
        {loading ? (
          <SmallLoadingRel />
        ) : (
          <>
            {buyers?.length ? (
              <button className={uiStyles.hover_btn} onClick={share_vbcs}>
                Share
              </button>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ShareAll
