import React, { useEffect, useState } from 'react'
import {
  HiPhone,
  HiChatBubbleBottomCenterText,
  HiEnvelope,
} from 'react-icons/hi2'
// import { BsBoxArrowRight } from 'react-icons/bs';
import {
  useLocation,
  useParams,
  // useParams
} from 'react-router'
// import Hero from '../../components/Hero';
import SubTitle from '../../components/ui/SubTitle'
import Wrapper from '../../components/layout/Wrapper'
// import Slots from '../../components/pages/VBCDetails/Slots';
// import SubNav from '../../components/SubNav';
// import TitleIcon from '../../components/ui/TitleIcon';
// import { Link } from 'react-router-dom';
import useAxiomFetch from '../../hooks/useAxiomFetch'
import Loading from '../../components/Loading'
// import VBCDetails from '../../components/pages/VBCDetails'
import profileSilhouette from '../../assets/images/profile-silhouette.svg'
import logoMain from '../../assets/images/logoMain.png'
import vbc_images from '../../assets/images/vbc'
import ReviewItem from '../../components/ui/ReviewItem'
import SwipeConfig from '../../components/ui/SwipeConfig'
import PropertyItem from '../../components/ui/PropertyItem'
import ListItem from '../../components/ui/ListItem'
import Header2 from '../../components/Header2'
import structuredClone from '@ungap/structured-clone'
import { BsBoxArrowRight } from 'react-icons/bs'
import ReceiverDBCSend from '../../components/pages/VBCDetails/ReceiverDBCSend'
import Modal from '../../components/Modal'
import { TbWorld, TbWorldWww } from 'react-icons/tb'
import home_images from '../../assets/images/home'
import uiStyles from '../../components/ui/uiStyles'
import { useNavigate } from 'react-router'
import { useReceiver } from '../../contexts/ReceiverContext'
import SmallLoading from '../../components/ui/SmallLoading'
import { SmallLoadingRel } from '../../components/ui/SmallLoadingRel'
import { Swiper } from 'swiper/react'
import { MarketingMaterialListingSlider } from '../../components/pages/marketingMaterials/MarketingMaterialListingSlider'
import { useClaims } from '../../contexts/ClaimsContext'
import {
  cal_geo_dist,
  getDateString,
  get_storage,
  get_uuid,
  set_storage,
  validate_number,
  format_currency,
  open_url_tab,
  date_today,
} from '../../helpers'
import { ListSlider } from '../../components/pages/home/ListSlider'
import { useAi } from '../../contexts/AiContext'
import { useDistribute } from '../../contexts/DistributeContext'
import { CALCMENU, content_types } from '../../data/data'
import DBCSend from '../../components/pages/VBCDetails/DBCSend'
import { useListingTours } from '../../contexts/ListingToursContext'
import { TourSlider } from '../../components/pages/tours'
import { useProfile } from '../../contexts/UserContext'
import jwtDecode from 'jwt-decode'
import { HomeList, HomeView } from '../../components/pages/home'
import images_tours from '../../assets/images/listing_tours'
import images_homes from '../../assets/images/home'
import { useCalc } from '../../contexts/CalcContext'
import { useMktReqs } from '../../contexts/MktReqsContext'
import useHelpers from '../../hooks/useHelpers'
import BuyerItinerary from '../../components/pages/BuyerTours/BuyerItinerary'
// import { useAuth0 } from '@auth0/auth0-react'
// import jwtDecode from 'jwt-decode'
const DBC = ({ show_header = true }) => {
  const { claims, saveClaims } = useClaims()
  const [receiver, setReceiver] = useState(null)
  const { calc, instanciate_calc } = useCalc()
  const { axiomFetch, get_token } = useAxiomFetch()
  const params = useParams()
  const { profile, saveProfile } = useProfile()
  const { ai, saveAi } = useAi()
  const { distribute, saveDistribute } = useDistribute()
  const {
    listingTours,
    saveListingTours,
    get_tours_by_type,
    get_tour_type,
    save_home,
  } = useListingTours()

  // window.history.replaceState('', '', window.location.origin);
  // const { getAccessTokenSilently } = useAuth0()
  // const check_token = async () => {
  //   const token = await getAccessTokenSilently().catch(err => null)
  //   const claims = jwtDecode(token)
  // }
  // check_token()

  const { receiver: context_receiver, saveReceiver } = useReceiver()
  const { open_url } = useHelpers()
  const [isLoading, setIsLoading] = useState(false)
  const [addTourLoading, setAddTourLoading] = useState(false)
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const query = new URLSearchParams(search)
  const [selected, setSelected] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [showHomeModal, setShowHomeModal] = useState(false)
  const [modalType, setModalType] = useState()
  const [propertyLoad, setPropertyLoad] = useState(false)
  const [location, setLocation] = useState({})
  const {
    mktReqs,
    saveMktReqs,
    generate_flyer_session,
    replace_fields,
    generate_pdf,
    merge_pdf,
  } = useMktReqs()
  // const not_content = ['rss feed', 'listing', 'transaction', 'review']

  async function get_curr_loc() {
    let curr_lat, curr_lng
    function get_location(location, resolve) {
      curr_lat = location.coords.latitude
      curr_lng = location.coords.longitude
      saveClaims((prev) => ({
        ...prev,
        geo_loc: {
          lat: curr_lat,
          lng: curr_lng,
          status: true,
        },
      }))
      return resolve({ lat: curr_lat, lng: curr_lng, status: true })
    }
    function show_error(error, resolve) {
      saveClaims((prev) => ({
        ...prev,
        geo_loc: {
          lat: 0,
          lng: 0,
          status: false,
        },
      }))
      return resolve({ lat: 0, lng: 0, status: false })
    }
    const navigator_promise = (...args) => {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            return get_location(position, resolve)
          },
          (error) => show_error(error, resolve),
          {
            enableHighAccuracy: false,
          }
        )
      })
    }
    return await navigator_promise()
  }

  const check_sign_rider = async () => {
    if (query.get('show_sign') === 'Y') {
      const geolocation = navigator.geolocation
      let user_location = {}
      if (geolocation) {
        setIsLoading(true)
        if (claims?.geo_loc?.lat && claims?.geo_loc?.lng) {
          user_location = claims?.geo_loc
        } else {
          user_location = await get_curr_loc()
        }
        setIsLoading(false)
      }
      const user_listings = context_receiver?.card?.content
        ? context_receiver?.card?.listings
        : get_storage('card')?.listings

      let listing_matches = user_listings?.filter((item) => {
        if (!user_location?.lat && !user_location?.lng) {
          return false
        }
        return (
          cal_geo_dist(
            user_location?.lat,
            user_location.lng,
            item.lat,
            item.lng
          ) < 0.8
        )
      })
      let homes = {}
      let modal_type = 'home_view'
      if (listing_matches?.length === 1) {
        homes = {
          current_home: context_receiver?.card?.content
            ? context_receiver?.card?.listings?.[0]
            : get_storage('card')?.listings?.[0],
        }
      } else if (listing_matches?.length > 1) {
        homes = {
          homes: listing_matches,
          count: listing_matches?.length,
          orig_homes: listing_matches,
        }
        modal_type = 'list_view'
      } else {
        homes = {
          homes: user_listings,
          count: listing_matches?.length,
          orig_homes: user_listings,
        }
        modal_type = 'list_view'
      }

      saveReceiver((prev) => {
        return {
          ...prev,
          homes: {
            ...prev?.homes,
            ...homes,
          },
        }
      })
      setModalType(modal_type)
      setShowModal(true)
    }
  }

  useEffect(() => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((permissionStatus) => {
        permissionStatus.onchange = () => {
          setLocation((prev) => ({
            ...prev,
            status: permissionStatus.state == 'granted',
          }))
          if (permissionStatus.state == 'granted') {
            check_sign_rider()
          }
        }
      })
  }, [])

  useEffect(() => {
    const check_tour = async () => {
      if (
        listingTours?.data?.event_pages
          ?.filter((item) => item?.event_type_id === 162)
          ?.some(
            (item) => date_today(item?.event_date) && item.tour_complete === 0
          ) &&
        !listingTours.hit_buyer_itinerary
      ) {
        setShowModal(true)
        saveListingTours((prev) => ({ ...prev, hit_buyer_itinerary: true }))
        setModalType('buyer_itinerary')
      }
    }
    check_tour()
  }, [listingTours?.data?.event_pages])

  const get_receiver_type = () => {
    if (card?.vbc_instance?.RECEIVER_TYPE_ID === 2) {
      return 'buyer'
    } else {
      // return 'seller'
      return 'buyer'
    }
  }
  const modals = {
    user_send: pathname.includes('/card') ? (
      <ReceiverDBCSend
        showModal={showModal}
        setShowModal={setShowModal}
        sendId={
          receiver?.VBC_INSTANCE_ID || get_storage('card')?.vbc_instance?.ID
        }
      />
    ) : (
      <DBCSend
        showModal={showModal}
        setShowModal={setShowModal}
        sendId={
          receiver?.VBC_INSTANCE_ID || get_storage('card')?.vbc_instance?.ID
        }
      />
    ),
    buyer_itinerary: (
      <BuyerItinerary types={[162]} setShowModal={setShowModal} />
    ),
    home_view: (
      <HomeView
        state={context_receiver}
        setState={saveReceiver}
        showHomeModal={showHomeModal}
        setShowHomeModal={setShowHomeModal}
      />
    ),
    list_view: (
      <HomeList
        show_overflow={false}
        state={context_receiver}
        setState={saveReceiver}
        right_component={(item) => {
          return (
            (
              <div className='flex gap-[20px] items-center'>
                {listingTours?.buyer_tours?.property_edit?.search_type ===
                'address' ? (
                  <>
                    {addTourLoading ? (
                      <SmallLoadingRel color={'from-white to-gray-300'} />
                    ) : (
                      <div
                        className='cursor-pointer flex flex-col items-center'
                        onClick={async () => {
                          setAddTourLoading(true)
                          saveReceiver((prev) => ({
                            ...prev,
                            homes: { ...prev?.homes, current_home: item },
                          }))

                          await save_home(
                            {
                              mls_id: item?.mls_id?.toString(),
                              event_id:
                                listingTours?.buyer_tours?.event_edit?.event_id,
                              evt_id:
                                listingTours?.buyer_tours?.event_edit?.evt_id,
                            },
                            '/user/buyer_tours/add_home'
                          )
                          setAddTourLoading(false)
                        }}
                      >
                        <img
                          className='w-[25px] pb-[4px]'
                          src={images_tours.add_icon_white}
                        />
                        <p className='text-[10px]'>add to tour</p>
                      </div>
                    )}
                  </>
                ) : (
                  ''
                )}
                <div
                  className='cursor-pointer flex flex-col items-center'
                  onClick={() => {
                    saveReceiver((prev) => ({
                      ...prev,
                      homes: { ...prev?.homes, current_home: item },
                    }))
                    navigate('/homes/view')
                  }}
                >
                  <img className='w-[30px]' src={images_homes.home_icon} />
                </div>
              </div>
            ) || ''
          )
        }}
      />
    ),
  }

  useEffect(() => {
    if (claims?.card_access) {
      saveReceiver((prev) => {
        localStorage.setItem('vbc_edit_mode', 'false')
        return {
          ...prev,
          edit_mode: '',
        }
      })
    } else {
      saveReceiver((prev) => {
        return {
          ...prev,
          edit_mode:
            localStorage?.getItem('vbc_edit_mode') === 'true' ? 'view' : '',
        }
      })
    }
  }, [])

  useEffect(() => {
    const get_receiver = async () => {
      if (get_storage('vbc_receiver') || params?.id) {
        let stored_receiver = get_storage('vbc_receiver')
        if (params?.id) {
          stored_receiver = jwtDecode(await get_token(params?.id))
          stored_receiver = set_storage('vbc_receiver', stored_receiver)
          set_storage(
            'receiver_cell_phone',
            (validate_number(stored_receiver?.cell_phone) || '')?.toString()
          )
        }
        if (stored_receiver?.vbc_instance_id) {
          stored_receiver.VBC_INSTANCE_ID = stored_receiver?.vbc_instance_id
          stored_receiver.RECEIVER_TYPE_ID = stored_receiver?.receiver_type_id
        }
        if (get_storage('card')) {
          let stored_card = get_storage('card')
          if (
            stored_card?.vbc_instance?.ID !== stored_receiver?.VBC_INSTANCE_ID
          ) {
            localStorage.removeItem('card')
            localStorage.removeItem('slots')
          }
        }
        return stored_receiver
      }
    }

    const get_data = async () => {
      const updated_receiver = await get_receiver()
      setReceiver(updated_receiver)
      if (!get_storage('card')) {
        setIsLoading(true)
      }
      if (!updated_receiver?.VBC_INSTANCE_ID) {
        setIsLoading(false)
        return
      }
      let hit_sign = false
      if (get_storage('card')) {
        hit_sign = true
        await check_sign_rider()
      }
      let user_card = await axiomFetch(
        `/products/vbc/sender/card/${
          updated_receiver?.VBC_INSTANCE_ID
        }/fast?receiver_cell_phone=${
          updated_receiver?.cell_phone || get_storage('receiver_cell_phone')
        }`,
        'GET'
      )
      if (user_card?.profile?.CONT_ID) {
        saveReceiver((prev) => ({
          ...prev,
          card: user_card,
          vbc_receiver: updated_receiver,
        }))
      } else {
        saveReceiver((prev) => ({ ...prev, card: {} }))
        setIsLoading(false)
        return
      }

      let original_slots = await axiomFetch(
        `/products/vbc/${updated_receiver?.VBC_INSTANCE_ID}/slots`
      )
      original_slots = original_slots?.sort(
        (a, b) => a.SLOT_ASSIGNMENT_ID - b.SLOT_ASSIGNMENT_ID
      )
      original_slots = original_slots.filter(
        (slot) => slot.SLOT_ASSIGNMENT_ID !== 21
      )
      let actual_slots = structuredClone(original_slots)
      for (const slot of actual_slots) {
        slot.PRIORITY = null
      }
      let count = 0
      for (const original of original_slots) {
        count++
        if (original.PRIORITY !== null) {
          let found =
            actual_slots.find(
              (actual) => actual.SLOT_ASSIGNMENT_ID === original.PRIORITY
            ) || {}
          found.PRIORITY = count
        }
      }
      actual_slots = actual_slots.sort((a, b) => a.PRIORITY - b.PRIORITY)
      const all_content = []
      for (const slot of actual_slots) {
        if (
          user_card?.types?.filter(
            (type) => type.TYPE_NAME.toLowerCase() === slot?.NAME?.toLowerCase()
          ).length
        ) {
          const current_type_id = user_card?.types?.filter(
            (type) => type.TYPE_NAME.toLowerCase() === slot?.NAME?.toLowerCase()
          )?.[0]?.TYPE_ID
          let content = await axiomFetch(
            `/marketing/content/types/${current_type_id}/items?skip=0&limit=10`
          )
          content = content.filter((item) =>
            item?.STATE_STR.includes(user_card?.profile?.ST_ABBR)
          )
          all_content.push(content?.[0])
        }
      }
      saveReceiver((prev) => ({
        ...prev,
        card: { ...prev?.card, content: all_content },
      }))
      set_storage('card', { ...user_card, content: all_content })

      actual_slots.push({
        NAME: 'tours',
        PRIORITY: 0.1,
        SLOT_ASSIGNMENT_ID: 2000,
        VBC_INSTANCE_ID: receiver?.VBC_INSTANCE_ID,
      })
      actual_slots.push({
        NAME: 'calculators',
        PRIORITY: 0.2,
        SLOT_ASSIGNMENT_ID: 2001,
        VBC_INSTANCE_ID: receiver?.VBC_INSTANCE_ID,
      })
      // actual_slots.push({
      //   NAME: 'facebook_link',
      //   PRIORITY: actual_slots?.length + 2,
      //   SLOT_ASSIGNMENT_ID: 201,
      //   VBC_INSTANCE_ID: receiver?.VBC_INSTANCE_ID,
      // })
      // actual_slots.push({
      //   NAME: 'events_link',
      //   PRIORITY: actual_slots?.length + 3,
      //   SLOT_ASSIGNMENT_ID: 202,
      //   VBC_INSTANCE_ID: receiver?.VBC_INSTANCE_ID,
      //   LINK: 'https://www.driggstitle.com/whats-happening/',
      // })
      saveReceiver((prev) => ({ ...prev, slots: actual_slots }))
      set_storage('slots', actual_slots)
      if (!hit_sign) {
        await check_sign_rider()
      }

      setIsLoading(false)
    }
    get_data()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_favorited_homes = async () => {
      const homes = await axiomFetch(
        '/marketing/homes/favorite',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      let all_favorited_homes = []
      if (homes?.length) {
        all_favorited_homes = homes?.reduce((curr, val) => {
          const home_found = context_receiver?.homes?.favorited_homes?.find(
            (item) => item.mls_id === val.mls_id
          )
          if (!home_found) {
            curr.push(val)
          }
          return curr
        }, [])
      }

      if (all_favorited_homes?.length) {
        set_storage('favorited_homes', [
          ...(context_receiver?.homes?.favorited_homes || []),
          ...(all_favorited_homes || []),
        ])
      }

      saveReceiver((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          favorited_homes: [
            ...(prev?.homes?.favorited_homes || []),
            ...(all_favorited_homes || []),
          ],
        },
      }))
    }
    get_favorited_homes()

    return () => {
      controller?.abort()
    }
  }, [])

  const homes = context_receiver?.homes
    ? context_receiver?.homes
    : get_storage('homes')
  const card = context_receiver?.card?.content
    ? context_receiver?.card
    : get_storage('card')
  const slots = context_receiver?.slots?.length
    ? context_receiver?.slots
    : get_storage('slots')

  const rightConfig = (item) => () =>
    (
      <>
        <button
          onClick={() => {
            open_url(item)
          }}
        >
          View
        </button>
      </>
    )
  return (
    <>
      {isLoading ? (
        <div
          className={`${
            show_header
              ? 'font-base text-sm text-gray-500 rounded-2xl max-w-[375px]  mx-auto bg-user-wrapper h-[100vh] relative'
              : ''
          }`}
        >
          <Wrapper show={show_header}>
            {show_header ? (
              <Header2
                type='vbc'
                show_agent_toggle={false}
                navigateHome={claims?.card_access || get_storage('uuid')}
              />
            ) : (
              ''
            )}
            <SmallLoadingRel />
          </Wrapper>
        </div>
      ) : (
        <div
          className={`${
            show_header
              ? 'font-base text-sm text-gray-500 rounded-2xl max-w-[375px]  mx-auto bg-user-wrapper h-[100vh] relative'
              : ''
          }`}
        >
          <div className={`${show_header ? 'p-[15px] h-full' : ''}`}>
            <Wrapper show={show_header}>
              {show_header ? (
                <Header2
                  type='vbc'
                  show_agent_toggle={false}
                  navigateHome={claims?.card_access || get_storage('uuid')}
                />
              ) : (
                ''
              )}
              <div className='max-w-[230px] mx-auto pt-5'>
                <img
                  src={card?.profile?.LOGO_LINK || logoMain}
                  alt=''
                  className='mb-[10px] object-contain object-top w-[230px] max-h-[110px]'
                />
                <img
                  src={
                    card?.profile?.PHOTO_LINK ||
                    card?.profile?.account?.photo_url ||
                    profileSilhouette
                  }
                  alt={card?.profile?.CONT_NAME}
                  className='mx-auto block rounded-md object-contain object-top max-w-[230px] max-h-[180px]'
                />
              </div>
              {card && card?.profile && card?.profile?.CONT_NAME ? (
                <p className='text-center mt-[3px] text-[16px] font-semibold text-primary'>
                  {card?.profile?.CONT_NAME}
                </p>
              ) : (
                ''
              )}

              {card?.profile?.JOB_TITLE_SHOW === 'Y' ? (
                <p className='text-center mt-[3px] text-[16px] font-semibold text-primary'>
                  {card?.profile?.JOB_TITLE}
                </p>
              ) : (
                ''
              )}

              <div className='flex justify-center text-primary text-2xl pt-1 pb-3 mt-[15px]'>
                {card?.profile?.CONT_CELL_NUM && (
                  <a
                    href={`tel:${card?.profile?.CONT_CELL_NUM}`}
                    className='mx-4'
                  >
                    <HiPhone />
                  </a>
                )}
                {card?.profile?.CONT_CELL_NUM && (
                  <a
                    href={`sms:${card?.profile?.CONT_CELL_NUM}`}
                    className='mx-4'
                  >
                    <HiChatBubbleBottomCenterText />
                  </a>
                )}
                {card?.profile?.CONT_EMAIL && (
                  <a
                    href={`mailto:?to=${card?.profile?.CONT_EMAIL}&subject=VBC Contact&body=`}
                    target='_blank'
                    rel='noreferrer'
                    className='mx-4'
                  >
                    <HiEnvelope />
                  </a>
                )}
                {card?.profile?.WEB_SITE && (
                  <a
                    href={`https://${card?.profile?.WEB_SITE?.replace(
                      'https://',
                      ''
                    )?.replace('http://', '')}`}
                    target='_blank'
                    rel='noreferrer'
                    className='mx-4'
                  >
                    <TbWorld />
                  </a>
                )}
                <button
                  className='mx-4'
                  onClick={() => {
                    setModalType('user_send')
                    setShowModal(true)
                  }}
                >
                  <BsBoxArrowRight />
                </button>
              </div>

              <hr className='border-t-0 border-white border-b-2 ' />

              <hr className='border-t-0 border-white border-b-2 ' />
              {query?.get('show_sign') === 'Y' ? (
                <div className='flex justify-center my-[10px]'>
                  <button
                    className={`${uiStyles.hover_btn}`}
                    onClick={() => {
                      setShowModal(true)
                    }}
                  >
                    Show Property Sign
                  </button>
                </div>
              ) : (
                ''
              )}
              {get_receiver_type() === 'buyer' ? (
                <div
                  className={`${uiStyles.card_blue_gradient} 
                  cursor-pointer flex flex-col items-center gap-[10px] p-[10px] mx-3 mb-3 relative`}
                  onClick={() => {
                    navigate('/homes')
                  }}
                >
                  <img src={home_images?.search} />
                  <p className={`${uiStyles.text_white_subtitle}`}>
                    Advanced Property Search
                  </p>
                  <div
                    className='absolute flex right-[-10px] top-[-10px]'
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate('/homes/favorites')
                    }}
                  >
                    <img
                      src={
                        home_images?.[
                          get_storage('favorited_homes')?.length ||
                          homes?.favorited_homes?.length
                            ? 'heart_filled'
                            : 'heart'
                        ]
                      }
                      className='w-[35px]'
                    />
                  </div>
                </div>
              ) : (
                ''
              )}

              {(slots || [])
                ?.sort((a, b) => a.PRIORITY - b.PRIORITY)
                ?.map((slot, index) => {
                  if (slot?.PRIORITY === null) {
                    return null
                  }
                  if (
                    slot?.NAME?.toLowerCase() === 'transaction' &&
                    card?.transaction
                  ) {
                    return <></>
                  }
                  if (
                    slot?.NAME?.toLowerCase() === 'review' &&
                    card?.review?.length
                  ) {
                    return card?.review?.[0]?.POST_PATH ? (
                      <SwipeConfig
                        key={index}
                        bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                        index={index}
                        selectedIndex={selected}
                        setSelectedIndex={setSelected}
                        widthConfigRight={44}
                        RightComponents={rightConfig(
                          card?.review?.[0]?.POST_PATH
                        )}
                      >
                        <ReviewItem
                          rating={card?.review?.[0]?.ratings?.[0]?.ANSWER}
                          comment={`${card?.review?.[0]?.property}, was facilitated by Driggs Title Agency; a disinterested 3rd party. We received the following review in Goodyear about real estate agent Adam Driggs from the Buyer.`}
                        />
                      </SwipeConfig>
                    ) : null
                  } else if (
                    slot?.NAME?.toLowerCase() === 'listing' &&
                    context_receiver?.card?.listings?.length
                  ) {
                    return (
                      <>
                        {propertyLoad ? (
                          <SmallLoadingRel />
                        ) : (
                          <MarketingMaterialListingSlider
                            my_listings_look={true}
                            state={context_receiver}
                            setState={saveReceiver}
                            image_link={() => {
                              setPropertyLoad(true)
                              saveReceiver((prev) => {
                                if (pathname?.includes('my-vbc')) {
                                  localStorage.setItem('vbc_edit_mode', 'true')
                                } else {
                                  localStorage.setItem('vbc_edit_mode', 'false')
                                }
                                return {
                                  ...prev,
                                  edit_mode: pathname?.includes('my-vbc')
                                    ? 'view'
                                    : '',
                                  homes: {
                                    ...prev?.homes,
                                    current_home:
                                      context_receiver?.current_listing ||
                                      card?.listings?.[0],
                                  },
                                }
                              })

                              navigate('/homes/view', {
                                state: {
                                  parent: pathname?.includes('my-vbc')
                                    ? `/user/my-vbc/${receiver?.VBC_INSTANCE_ID}`
                                    : '/card',
                                },
                              })
                            }}
                          />
                        )}
                      </>
                    )
                  } else if (
                    slot?.NAME?.toLowerCase() === 'rss feed' &&
                    card?.rss
                  ) {
                    return (
                      <SwipeConfig
                        key={index}
                        bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                        index={index}
                        selectedIndex={selected}
                        setSelectedIndex={setSelected}
                        widthConfigRight={44}
                        RightComponents={rightConfig(card?.rss?.RSS_LINK)}
                      >
                        <ListItem
                          title={card?.rss?.HEADLINE}
                          image={card?.rss?.IMAGE_LINK}
                          content={card?.rss?.CONTENT}
                        />
                      </SwipeConfig>
                    )
                  } else if (
                    card?.types?.filter(
                      (type) =>
                        type?.TYPE_NAME?.toLowerCase() ===
                        slot?.NAME?.toLowerCase()
                    )?.length
                  ) {
                    const current_type_id = card?.types?.filter(
                      (type) =>
                        type?.TYPE_NAME?.toLowerCase() ===
                        slot?.NAME?.toLowerCase()
                    )?.[0]?.TYPE_ID
                    const card_content = card?.content?.filter(
                      (content) => content?.CONTENT_TYPE_ID === current_type_id
                    )?.[0]
                    if (!card_content) return null
                    return card_content?.ITEM_LINK ? (
                      <SwipeConfig
                        key={index}
                        bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                        index={index}
                        selectedIndex={selected}
                        setSelectedIndex={setSelected}
                        widthConfigRight={44}
                        RightComponents={rightConfig(card_content?.ITEM_LINK)}
                      >
                        <ListItem
                          title={card_content?.HEADLINE}
                          image={card_content?.IMAGE_LINK}
                          content={card_content?.CONTENT}
                        />
                      </SwipeConfig>
                    ) : null
                  } else if (slot?.NAME?.toLowerCase() === 'website') {
                    if (card?.profile?.WEB_SITE) {
                      return (
                        <SwipeConfig
                          key={index}
                          bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                          index={index}
                          selectedIndex={selected}
                          setSelectedIndex={setSelected}
                          widthConfigRight={44}
                          RightComponents={rightConfig(
                            'https://' +
                              card?.profile?.WEB_SITE?.replace(
                                'https://',
                                ''
                              )?.replace('http://', '')
                          )}
                        >
                          <ListItem
                            title={'My Website'}
                            image={vbc_images.www}
                            fill={'object-fill'}
                            content={
                              ''
                              // 'https://' +
                              // card?.profile?.WEB_SITE?.replace(
                              //   'https://',
                              //   ''
                              // )?.replace('http://', '')
                            }
                          />
                        </SwipeConfig>
                      )
                    } else {
                      return null
                    }
                  } else if (slot?.NAME?.toLowerCase() === 'facebook_link') {
                    if (card?.profile?.FACEBOOK_LINK) {
                      return (
                        <SwipeConfig
                          key={index}
                          bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                          index={index}
                          selectedIndex={selected}
                          setSelectedIndex={setSelected}
                          widthConfigRight={44}
                          RightComponents={rightConfig(
                            'https://' +
                              card?.profile?.FACEBOOK_LINK?.replace(
                                'https://',
                                ''
                              )?.replace('http://', '')
                          )}
                        >
                          <ListItem
                            title={'Facebook'}
                            image={vbc_images.facebook_dark_icon}
                            fill={'object-fill'}
                            content={
                              ''
                              // 'https://' +
                              // card?.profile?.FACEBOOK_LINK?.replace(
                              //   'https://',
                              //   ''
                              // )?.replace('http://', '')
                            }
                          />
                        </SwipeConfig>
                      )
                    } else {
                      return null
                    }
                  } else if (slot?.NAME?.toLowerCase() === 'ai prompts') {
                    return (
                      <ListSlider
                        handler={async (item) => {
                          saveAi((prev) => ({
                            ...prev,
                            nav_url: '/card/ai',
                            parent_url:
                              claims?.card_access || get_storage('uuid')
                                ? '/card'
                                : `/user/my-vbc/${receiver?.VBC_INSTANCE_ID}`,
                            assistant: {
                              ...prev?.assistant,
                              toggle_back: false,
                            },
                            slot: {
                              AI_PRPT_ID: item?.prpt_id,
                              AI_PRPT_NM: item?.prpt_name,
                              AI_PRPT_DESC: item?.prpt_desc,
                              AI_PRPT_PROMPT: item?.prpt_prompt,
                              AI_GO_PROP: 'N',
                            },
                            mls: {
                              ...prev?.mls,
                              mlsMatch: ai?.current_listing,
                              setting: 'mlsNo',
                              add_mls_to_prompt: 1,
                              start_ai: false,
                            },
                            state: {
                              run: true,
                              prompt: item?.prpt_prompt,
                            },
                          }))
                          saveAi((prev) => {
                            return {
                              ...prev,
                              image_upload: {
                                ...prev?.image_upload,
                                gallery: {
                                  ...prev?.image_upload?.gallery,
                                  active: ai?.current_listing?.home_photo1
                                    ? { url: ai?.current_listing?.home_photo1 }
                                    : { url: '' },
                                },
                              },
                            }
                          })

                          let distribute_content = distribute?.content || {}

                          for (const content_type of content_types) {
                            distribute_content[content_type] = {
                              ...distribute_content?.[content_type],
                              photo_url: ai?.current_listing?.home_photo1
                                ? ai?.current_listing?.home_photo1
                                : '',
                              image_upload: {
                                ...distribute_content?.[content_type]
                                  ?.image_upload,
                                gallery: {
                                  ...distribute_content?.[content_type]
                                    ?.image_upload?.gallery,
                                  active: ai?.current_listing?.home_photo1
                                    ? { url: ai?.current_listing?.home_photo1 }
                                    : { url: '' },
                                },
                              },
                            }
                          }
                          saveDistribute((prev) => ({
                            ...prev,
                            content: distribute_content,
                          }))
                          navigate(`/user/ai`)
                        }}
                        items={card?.ai
                          // ?.filter((item, index) => item.prpt_id === 262)
                          ?.map((item) => {
                            return {
                              title: 'AI Prompts',
                              subtitle: item.prpt_name,
                              content: item?.prpt_desc
                                ?.replaceAll('{{MARKET}}', '')
                                ?.replaceAll('{{MARKET}', ''),
                              image: item?.thumb_img,
                              ...item,
                            }
                          })}
                      />
                    )
                  } else if (slot?.NAME?.toLowerCase() === 'tours') {
                    return (
                      <TourSlider
                        state={listingTours}
                        setState={saveListingTours}
                        types={[162]}
                        slot_settings={{
                          title:
                            get_tour_type(22)?.type_name?.replace(
                              'Buyer',
                              ''
                            ) || 'Tours',
                        }}
                      />
                    )
                  } else if (slot?.NAME?.toLowerCase() === 'calculators') {
                    return (
                      <ListSlider
                        handler={async (item) => {
                          const nav_default = CALCMENU?.find(
                            (calc) => calc?.id === item?.type_id
                          )
                          instanciate_calc(item, get_uuid())
                          set_storage('vbc_calc', 'Y')
                          window.scrollTo({ top: 0 })
                          navigate(item?.calc_url || nav_default?.link)
                        }}
                        filter_strategy={(item) => {
                          return {
                            types: {
                              title: 'str',
                              content: 'str',
                              loc_mkt_id: 'options',
                              lang_id: 'options',
                              search: 'search',
                              subtitle: '$',
                            },
                            show_filters:
                              item?.topic_subtype === 61
                                ? ['loc_mkt_id', 'lang_id', 'search']
                                : ['search'],
                            search: {
                              title: 'str',
                              content: 'str',
                              subtitle: '$',
                              sub_content: 'str',
                            },
                            values: {
                              search: '',
                            },
                            names: {
                              title: '',
                              content: '',
                              search: '',
                              subtitle: '',
                            },
                            placeholders: {
                              search: 'Search...',
                            },
                            options_names: {},
                            options_values: {},
                            options_show_all: {},
                          }
                        }}
                        show_filter={true}
                        items={card?.calculators
                          // ?.filter((item, index) => item.prpt_id === 262)
                          ?.map((item) => {
                            const nav_default = CALCMENU?.find(
                              (calc) => calc?.id === item?.type_id
                            )

                            return {
                              title: item?.calc_name,
                              subtitle: `${
                                item?.sale_price ||
                                item?.home_price ||
                                item?.lbal_extra_payment ||
                                item?.lbal_home_price ||
                                item?.lbal_amount
                                  ? `$${format_currency(
                                      item?.sale_price ||
                                        item?.home_price ||
                                        item?.lbal_extra_payment ||
                                        item?.lbal_home_price ||
                                        item?.lbal_amount,
                                      false,
                                      2
                                    )}`
                                  : ''
                              }`,
                              content: item?.address ? item?.address : '',
                              content_link: item?.pdf_url,
                              content_link_name: 'View PDF',
                              sub_content: getDateString({
                                date: item?.upd_dt || item?.ins_dt,
                                format: 'mmm d, hh:mi am',
                              }),
                              image: item?.calc_thumb || nav_default?.image,
                              click_me: true,
                              select: () => {
                                const nav_default = CALCMENU?.find(
                                  (calc) => calc?.id === item?.type_id
                                )
                                instanciate_calc(item, get_uuid())
                                set_storage('vbc_calc', 'Y')
                                window.scrollTo({ top: 0 })
                                navigate(item?.calc_url || nav_default?.link)
                              },
                              ...item,
                            }
                          })}
                      />
                    )
                  } else if (
                    slot?.NAME?.toLowerCase() === 'my guys' &&
                    card?.providers?.length
                  ) {
                    return (
                      <SwipeConfig
                        key={index}
                        bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                        index={index}
                        selectedIndex={selected}
                        setSelectedIndex={setSelected}
                        widthConfigRight={44}
                        RightComponents={() => (
                          <>
                            <button
                              onClick={() => {
                                navigate('/card/services')
                              }}
                            >
                              View
                            </button>
                          </>
                        )}
                      >
                        <ListItem
                          click_me={true}
                          select={() => {
                            navigate('/card/services')
                          }}
                          title={'I got a guy!'}
                          image={vbc_images.service_providers}
                          fill={'object-cover'}
                          content={
                            'Here is my list of the best people to help with your home.'
                          }
                        />
                      </SwipeConfig>
                    )
                  } else if (slot?.NAME?.toLowerCase() === 'estimators') {
                    return (
                      <SwipeConfig
                        key={index}
                        bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                        index={index}
                        selectedIndex={selected}
                        setSelectedIndex={setSelected}
                        widthConfigRight={44}
                        RightComponents={() => (
                          <>
                            <button
                              onClick={() => {
                                saveReceiver((prev) => ({
                                  ...prev,
                                  edit_vbcid: params.vbcid,
                                }))
                                navigate('/user/calculators', {
                                  state: {
                                    parent: params?.vbcid
                                      ? `/user/my-vbc/${params.vbcid}`
                                      : '/card',
                                  },
                                })
                              }}
                            >
                              View
                            </button>
                          </>
                        )}
                      >
                        <ListItem
                          click_me={true}
                          select={() => {
                            saveReceiver((prev) => ({
                              ...prev,
                              edit_vbcid: params.vbcid,
                            }))
                            navigate('/user/calculators', {
                              state: {
                                edit_vbcid: params.vbcid,
                                parent: params?.vbcid
                                  ? `/user/my-vbc/${params.vbcid}`
                                  : '/card',
                              },
                            })
                          }}
                          title={'Estimators'}
                          image={
                            'https://driggstitle.com/reviews/Ax_Engage/dbc/buyernetsheetimg1712684603729.jpeg'
                          }
                          fill={'object-cover'}
                          content={
                            'Use these estimators to for in depth analysis on real estate costs.'
                          }
                        />
                      </SwipeConfig>
                    )
                  } else if (
                    slot?.NAME?.toLowerCase() === 'open house search'
                  ) {
                    return (
                      <>
                        {context_receiver?.load_open_house_search ? (
                          <SmallLoadingRel />
                        ) : (
                          <SwipeConfig
                            key={index}
                            bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                            index={index}
                            selectedIndex={selected}
                            setSelectedIndex={setSelected}
                            widthConfigRight={44}
                            RightComponents={() => (
                              <>
                                <button
                                  onClick={async (e) => {
                                    saveReceiver((prev) => ({
                                      ...prev,
                                      show_map: 'Y',
                                      load_open_house_search: true,
                                    }))
                                    localStorage.setItem('show_map', 'Y')
                                    const result = await get_curr_loc()
                                    saveReceiver((prev) => ({
                                      ...prev,
                                      show_map: 'Y',
                                      load_open_house_search: false,
                                      homes: {
                                        ...prev?.homes,
                                        open_house: 'Y',
                                      },
                                      place: {
                                        ...prev?.place,
                                        zoom: 11,
                                        circle: {
                                          ...prev?.place?.circle,
                                          location: {
                                            lat: () => result.lat,
                                            lng: () => result.lng,
                                          },
                                        },
                                        geometry: {
                                          ...prev?.place?.geometry,
                                          location: {
                                            lat: () => result.lat,
                                            lng: () => result.lng,
                                          },
                                        },
                                      },
                                    }))
                                    navigate('/homes')
                                  }}
                                >
                                  View
                                </button>
                              </>
                            )}
                          >
                            <ListItem
                              click_me={true}
                              select={async () => {
                                saveReceiver((prev) => ({
                                  ...prev,
                                  show_map: 'Y',
                                  load_open_house_search: true,
                                }))
                                localStorage.setItem('show_map', 'Y')
                                const result = await get_curr_loc()
                                saveReceiver((prev) => ({
                                  ...prev,
                                  show_map: 'Y',
                                  load_open_house_search: false,
                                  homes: {
                                    ...prev?.homes,
                                    open_house: 'Y',
                                  },
                                  place: {
                                    ...prev?.place,
                                    zoom: 11,
                                    circle: {
                                      ...prev?.place?.circle,
                                      location: {
                                        lat: () => result.lat,
                                        lng: () => result.lng,
                                      },
                                    },
                                    geometry: {
                                      ...prev?.place?.geometry,
                                      location: {
                                        lat: () => result.lat,
                                        lng: () => result.lng,
                                      },
                                    },
                                  },
                                }))
                                navigate('/homes')
                              }}
                              title={'Open House Search'}
                              image={
                                'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1722457785554.jpeg'
                              }
                              fill={'object-cover'}
                              content={
                                'Search your location and find open houses nearby.'
                              }
                            />
                          </SwipeConfig>
                        )}
                      </>
                    )
                  } else if (slot?.NAME?.toLowerCase() === 'buyer guide') {
                    return (
                      <>
                        {context_receiver?.load_buyer_guide ? (
                          <SmallLoadingRel />
                        ) : (
                          <SwipeConfig
                            key={index}
                            bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                            index={index}
                            selectedIndex={selected}
                            setSelectedIndex={setSelected}
                            widthConfigRight={44}
                            RightComponents={() => (
                              <>
                                <button
                                  onClick={async (e) => {
                                    saveReceiver((prev) => ({
                                      ...prev,
                                      load_buyer_guide: true,
                                    }))
                                    const layout =
                                      mktReqs?.materials?.mkt_req_layout?.find(
                                        (item) => item.layout_id === 420
                                      )
                                    const quick_flyer =
                                      await generate_flyer_session({
                                        layout: layout,
                                      })
                                    quick_flyer.html = await replace_fields(
                                      quick_flyer?.layout_text,
                                      quick_flyer?.sess_info,
                                      quick_flyer
                                    )
                                    // quick_flyer.page_ranges = 'all'
                                    quick_flyer.upload_type = 'reviews'
                                    quick_flyer.file_name =
                                      quick_flyer?.layout_name
                                        ? quick_flyer.layout_name
                                            ?.toString()
                                            ?.replace(/\s+/g, ' ')
                                            ?.trim()
                                            ?.replace(/\s/g, '_')
                                            ?.replaceAll('-', '')
                                            ?.replace(/_+/g, '_')
                                        : ''
                                    // setState((prev) => ({
                                    //   ...prev,
                                    //   create_order: { ...prev?.create_order, vbc_market_stats: false },
                                    // }))
                                    console.log('quick_flyer', quick_flyer)
                                    quick_flyer.pdf_url = await generate_pdf(
                                      quick_flyer
                                    )
                                    quick_flyer.pdf_url = await merge_pdf({
                                      urls: [
                                        quick_flyer.pdf_url,
                                        quick_flyer.append_pdf_url,
                                      ],
                                    })
                                    saveReceiver((prev) => ({
                                      ...prev,
                                      load_buyer_guide: false,
                                    }))
                                    console.log('pdf', quick_flyer.pdf_url)

                                    open_url(quick_flyer.pdf_url)
                                  }}
                                >
                                  View
                                </button>
                              </>
                            )}
                          >
                            <ListItem
                              click_me={true}
                              select={async () => {
                                saveReceiver((prev) => ({
                                  ...prev,
                                  load_buyer_guide: true,
                                }))
                                const layout =
                                  mktReqs?.materials?.mkt_req_layout?.find(
                                    (item) =>
                                      item.layout_id ===
                                      (card?.profile?.ST_ABBR?.toLowerCase() ===
                                      'nv'
                                        ? 1483
                                        : 420)
                                  )
                                const quick_flyer =
                                  await generate_flyer_session({
                                    layout: layout,
                                  })
                                quick_flyer.html = await replace_fields(
                                  quick_flyer?.layout_text,
                                  quick_flyer?.sess_info,
                                  quick_flyer
                                )
                                // quick_flyer.page_ranges = 'all'
                                quick_flyer.upload_type = 'reviews'
                                quick_flyer.file_name = quick_flyer?.layout_name
                                  ? quick_flyer.layout_name
                                      ?.toString()
                                      ?.replace(/\s+/g, ' ')
                                      ?.trim()
                                      ?.replace(/\s/g, '_')
                                      ?.replaceAll('-', '')
                                      ?.replace(/_+/g, '_')
                                  : ''
                                // setState((prev) => ({
                                //   ...prev,
                                //   create_order: { ...prev?.create_order, vbc_market_stats: false },
                                // }))
                                console.log('quick_flyer', quick_flyer)
                                quick_flyer.pdf_url = await generate_pdf(
                                  quick_flyer
                                )
                                quick_flyer.pdf_url = await merge_pdf({
                                  urls: [
                                    quick_flyer.pdf_url,
                                    quick_flyer.append_pdf_url,
                                  ],
                                })
                                saveReceiver((prev) => ({
                                  ...prev,
                                  load_buyer_guide: false,
                                }))
                                console.log('pdf', quick_flyer.pdf_url)

                                open_url(quick_flyer.pdf_url)
                              }}
                              title={'Buyer Guide'}
                              image={
                                'https://driggstitle.com/reviews/Ax_Engage/dbc/BuyersGuideEnglishv11696016411803.jpg'
                              }
                              fill={'object-cover'}
                              content={
                                'A complete guide to buying a home in your area.'
                              }
                            />
                          </SwipeConfig>
                        )}
                      </>
                    )
                  } else if (slot?.NAME?.toLowerCase() === 'seller guide') {
                    return (
                      <>
                        {context_receiver?.load_seller_guide ? (
                          <SmallLoadingRel />
                        ) : (
                          <SwipeConfig
                            key={index}
                            bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                            index={index}
                            selectedIndex={selected}
                            setSelectedIndex={setSelected}
                            widthConfigRight={44}
                            RightComponents={() => (
                              <>
                                <button
                                  onClick={async (e) => {
                                    saveReceiver((prev) => ({
                                      ...prev,
                                      load_seller_guide: true,
                                    }))
                                    const layout =
                                      mktReqs?.materials?.mkt_req_layout?.find(
                                        (item) =>
                                          item.layout_id ===
                                          (card?.profile?.ST_ABBR?.toLowerCase() ===
                                          'nv'
                                            ? 1482
                                            : 444)
                                      )
                                    const quick_flyer =
                                      await generate_flyer_session({
                                        layout: layout,
                                      })
                                    quick_flyer.html = await replace_fields(
                                      quick_flyer?.layout_text,
                                      quick_flyer?.sess_info,
                                      quick_flyer
                                    )
                                    // quick_flyer.page_ranges = 'all'
                                    quick_flyer.upload_type = 'reviews'
                                    quick_flyer.file_name =
                                      quick_flyer?.layout_name
                                        ? quick_flyer.layout_name
                                            ?.toString()
                                            ?.replace(/\s+/g, ' ')
                                            ?.trim()
                                            ?.replace(/\s/g, '_')
                                            ?.replaceAll('-', '')
                                            ?.replace(/_+/g, '_')
                                        : ''
                                    // setState((prev) => ({
                                    //   ...prev,
                                    //   create_order: { ...prev?.create_order, vbc_market_stats: false },
                                    // }))
                                    console.log('quick_flyer', quick_flyer)
                                    quick_flyer.pdf_url = await generate_pdf(
                                      quick_flyer
                                    )
                                    quick_flyer.pdf_url = await merge_pdf({
                                      urls: [
                                        quick_flyer.pdf_url,
                                        quick_flyer.append_pdf_url,
                                      ],
                                    })
                                    saveReceiver((prev) => ({
                                      ...prev,
                                      load_seller_guide: false,
                                    }))
                                    console.log('pdf', quick_flyer.pdf_url)

                                    open_url(quick_flyer.pdf_url)
                                  }}
                                >
                                  View
                                </button>
                              </>
                            )}
                          >
                            <ListItem
                              click_me={true}
                              select={async () => {
                                saveReceiver((prev) => ({
                                  ...prev,
                                  load_seller_guide: true,
                                }))
                                const layout =
                                  mktReqs?.materials?.mkt_req_layout?.find(
                                    (item) =>
                                      item.layout_id ===
                                      (card?.profile?.ST_ABBR?.toLowerCase() ===
                                      'nv'
                                        ? 1482
                                        : 444)
                                  )
                                const quick_flyer =
                                  await generate_flyer_session({
                                    layout: layout,
                                  })
                                quick_flyer.html = await replace_fields(
                                  quick_flyer?.layout_text,
                                  quick_flyer?.sess_info,
                                  quick_flyer
                                )
                                // quick_flyer.page_ranges = 'all'
                                quick_flyer.upload_type = 'reviews'
                                quick_flyer.file_name = quick_flyer?.layout_name
                                  ? quick_flyer.layout_name
                                      ?.toString()
                                      ?.replace(/\s+/g, ' ')
                                      ?.trim()
                                      ?.replace(/\s/g, '_')
                                      ?.replaceAll('-', '')
                                      ?.replace(/_+/g, '_')
                                  : ''
                                // setState((prev) => ({
                                //   ...prev,
                                //   create_order: { ...prev?.create_order, vbc_market_stats: false },
                                // }))
                                console.log('quick_flyer', quick_flyer)
                                quick_flyer.pdf_url = await generate_pdf(
                                  quick_flyer
                                )
                                quick_flyer.pdf_url = await merge_pdf({
                                  urls: [
                                    quick_flyer.pdf_url,
                                    quick_flyer.append_pdf_url,
                                  ],
                                })
                                saveReceiver((prev) => ({
                                  ...prev,
                                  load_seller_guide: false,
                                }))
                                console.log('pdf', quick_flyer.pdf_url)

                                open_url(quick_flyer.pdf_url)
                              }}
                              title={'Seller Guide'}
                              image={
                                'https://driggstitle.com/reviews/Ax_Engage/dbc/BuyersGuideEnglishv11696016411803.jpg'
                              }
                              fill={'object-cover'}
                              content={
                                'A complete guide to selling a home in your area.'
                              }
                            />
                          </SwipeConfig>
                        )}
                      </>
                    )
                  } else if (slot?.NAME?.toLowerCase() === 'market stats') {
                    return (
                      <>
                        <SwipeConfig
                          key={index}
                          bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                          index={index}
                          selectedIndex={selected}
                          setSelectedIndex={setSelected}
                          widthConfigRight={44}
                          RightComponents={() => (
                            <>
                              <button
                                onClick={async (e) => {
                                  saveMktReqs((prev) => ({
                                    ...prev,
                                    hit_create_page: true,
                                    create_order: {
                                      ...prev?.create_order,
                                      current_selection:
                                        mktReqs?.materials?.mkt_req_layout.find(
                                          (item) => item?.mkt_stats === 'Y'
                                        ),
                                    },
                                  }))
                                  return navigate(
                                    '/user/marketing-material/add-data',
                                    {
                                      state: {
                                        parent:
                                          claims?.card_access ||
                                          get_storage('uuid')
                                            ? '/card'
                                            : receiver?.VBC_INSTANCE_ID
                                            ? `/user/my-vbc/${receiver?.VBC_INSTANCE_ID}`
                                            : '/card',
                                      },
                                    }
                                  )
                                }}
                              >
                                View
                              </button>
                            </>
                          )}
                        >
                          <ListItem
                            click_me={true}
                            select={async () => {
                              saveMktReqs((prev) => ({
                                ...prev,
                                hit_create_page: true,
                              }))

                              saveMktReqs((prev) => ({
                                ...prev,
                                create_order: {
                                  ...prev?.create_order,
                                  vbc_market_stats: true,
                                  current_selection:
                                    mktReqs.materials.mkt_req_layout.find(
                                      (item) => item.mkt_stats === 'Y'
                                    ),
                                },
                              }))
                              return navigate(
                                '/user/marketing-material/add-data',
                                {
                                  state: {
                                    parent:
                                      claims?.card_access || get_storage('uuid')
                                        ? '/card'
                                        : `/user/my-vbc/${receiver?.VBC_INSTANCE_ID}`,
                                  },
                                }
                              )
                            }}
                            title={'Market Stats'}
                            image={
                              'https://driggstitle.com/reviews/Ax_Engage/dbc/mktstats21715729949820.png'
                            }
                            fill={'object-cover'}
                            content={
                              'A birds eye view of the current market trends for an area.'
                            }
                          />
                        </SwipeConfig>
                      </>
                    )
                  } else if (slot?.NAME?.toLowerCase() === 'events') {
                    return (
                      <SwipeConfig
                        key={index}
                        bg={index % 2 ? 'bg-gray-100' : 'bg-gray-100'}
                        index={index}
                        selectedIndex={selected}
                        setSelectedIndex={setSelected}
                        widthConfigRight={44}
                        RightComponents={() => (
                          <>
                            <button
                              onClick={() => {
                                navigate('/user/events')
                              }}
                            >
                              View
                            </button>
                          </>
                        )}
                      >
                        <ListItem
                          title={'Upcoming Events'}
                          image={vbc_images.events}
                          fill={'object-cover'}
                          content={
                            ''
                            // 'https://' +
                            // slot?.LINK?.replace('https://', '')?.replace(
                            //   'http://',
                            //   ''
                            // )
                          }
                        />
                      </SwipeConfig>
                    )
                  } else {
                    return null
                  }
                })}
              <div className='footer flex justify-center gap-[50px] py-[15px]'>
                {card?.profile?.FACEBOOK_LINK ? (
                  <img
                    onClick={() => {
                      open_url_tab(
                        'https://' +
                          card?.profile?.FACEBOOK_LINK?.replace(
                            'https://',
                            ''
                          )?.replace('http://', '')
                      )
                    }}
                    src={vbc_images.facebook_dark_icon}
                    className='w-[25px] cursor-pointer'
                  />
                ) : (
                  ''
                )}
                {card?.profile?.INSTAGRAM_LINK ? (
                  <img
                    onClick={() => {
                      open_url_tab(
                        'https://' +
                          card?.profile?.INSTAGRAM_LINK?.replace(
                            'https://',
                            ''
                          )?.replace('http://', '')
                      )
                    }}
                    src={vbc_images.instagram_dark_icon}
                    className='w-[25px] cursor-pointer'
                  />
                ) : (
                  ''
                )}
                {card?.profile?.TWITTER_LINK ? (
                  <img
                    onClick={() => {
                      open_url_tab(
                        'https://' +
                          card?.profile?.TWITTER_LINK?.replace(
                            'https://',
                            ''
                          )?.replace('http://', '')
                      )
                    }}
                    src={vbc_images.twitter_dark_icon}
                    className='w-[25px] cursor-pointer'
                  />
                ) : (
                  ''
                )}
              </div>
              {showModal && (
                <Modal
                  modalName={modalType}
                  showModal={showModal}
                  setShowModal={showHomeModal ? () => {} : setShowModal}
                  width={modalType.includes('view') ? 'w-[345px]' : null}
                  height={
                    pathname.includes('/card') &&
                    !modalType.includes('view') &&
                    !modalType.includes('buyer_itinerary')
                      ? 'h-[300px]'
                      : 'h-[550px]'
                  }
                >
                  {modalType && modals[modalType]}
                </Modal>
              )}
            </Wrapper>
          </div>
        </div>
      )}
    </>
  )
}

export default DBC
