import { useAuth0 } from '@auth0/auth0-react'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { SmallLoadingRel } from '../../components/ui/SmallLoadingRel'
import { useClaims } from '../../contexts/ClaimsContext'
import { useListingTours } from '../../contexts/ListingToursContext'
import { useProfile } from '../../contexts/UserContext'
import { get_storage, set_storage } from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'

const DBCWrapper = () => {
  const { axiomFetch, get_token } = useAxiomFetch()
  const { listingTours, saveListingTours } = useListingTours()
  const { claims, saveClaims } = useClaims()
  const { profile, saveProfile } = useProfile()
  const { getAccessTokenSilently } = useAuth0()
  const location = useLocation()
  const params = useParams()

  useEffect(() => {
    const controller = new AbortController()
    const get_profile = async () => {
      if (profile?.CONT_ID) {
        return
      }
      const data = await axiomFetch(
        '/client/profile',
        'GET',
        {},
        {},
        false,
        controller.signal,
        0
      )
      saveProfile((prev) => ({
        ...prev,
        ...(get_storage('profile') || {}),
        ...data?.[0],
      }))
    }
    get_profile()

    return () => {
      controller?.abort()
    }
  }, [])

  useEffect(() => {
    const get_claims = async () => {
      if (!params?.id) {
        if (claims?.cont_id) {
          return
        }
        const token = await getAccessTokenSilently().catch((err) => null)
        if (token) {
          const new_claims = jwtDecode(token)
          saveClaims((prev) => ({ ...prev, ...new_claims }))
        }
      }
    }
    get_claims()
    return () => get_claims()
  }, [])
  return (
    <div className='h-full'>
      <Outlet />
    </div>
  )
}

export default DBCWrapper
