import { UserProvider } from './UserContext'
import { TransactionProvider } from './TransactionsContext'
import { ReviewsProvider } from './ReviewsContext'
import { ListingProvider } from './ListingContext'
import { VBCProvider } from './VBCContext'
import {
  AxiomContentContextProvider,
  DistributeProvider,
} from './DistributeContext'
import { DocumentsProvider } from './DocumentsContext'
import { LimitsProvider } from './LimitsContext'
import { ConnectionProvider } from './ConnectionContext'
import { TotalsProvider } from './TotalsContext'
import { ConversationsProvider } from './ConversationsContext'
import { ClaimsProvider } from './ClaimsContext'
import { AiProvider } from './AiContext'
import { ReceiverProvider } from './ReceiverContext'
import { MktReqsProvider } from './MktReqsContext'
import { ListingsSoldProvider } from './ListingsSoldContext'
import { ListingToursProvider } from './ListingToursContext'
import { DefaultsProvider } from './DefaultsContext'
import { CalcProvider } from './CalcContext'
import { PairwiseProvider } from './PairwiseContext'
import { MerchantProvider } from './MerchantContext'
import { SalesProvider } from './SalesContext'
import { AiDailyProvider } from './AiDailyContext'

const compose = (providers) =>
  providers.reduce((Prev, Curr) => ({ children }) => (
    <Prev>
      <Curr>{children}</Curr>
    </Prev>
  ))

export const ProviderCombined = compose([
  UserProvider,
  TransactionProvider,
  ReviewsProvider,
  ListingProvider,
  ListingsSoldProvider,
  VBCProvider,
  DistributeProvider,
  DocumentsProvider,
  LimitsProvider,
  ConnectionProvider,
  TotalsProvider,
  ConversationsProvider,
  ClaimsProvider,
  AiProvider,
  ReceiverProvider,
  MktReqsProvider,
  ListingToursProvider,
  DefaultsProvider,
  CalcProvider,
  PairwiseProvider,
  MerchantProvider,
  SalesProvider,
  AiDailyProvider,
])
