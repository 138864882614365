import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAiDaily } from '../../contexts/AiDailyContext'
import { useProfile } from '../../contexts/UserContext'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import uiStyles from '../ui/uiStyles'

const AiDailyFirst = () => {
  const { profile } = useProfile()
  const { axiomFetch } = useAxiomFetch()
  const { upd_daily_signup } = useAiDaily()
  const navigate = useNavigate()
  const signup = async (daily_text = 0) => {
    await axiomFetch('/marketing/content/ai/daily/signup', 'POST', {
      send_daily_text: daily_text,
    })
    upd_daily_signup({ cont_id: profile.CONT_ID, send_daily_text: daily_text })
  }
  return (
    <div className='h-full'>
      <div className='flex flex-col h-full justify-between'>
        <div className='px-3'>
          <h1 className={`${uiStyles.text_black_title} mb-[10px]`}>
            AXIOM Daily Post
          </h1>

          <div className='flex flex-col gap-2 text-[20px]'>
            <p>Hey {profile?.CONT_NAME},</p>
            <p>It looks like this is your first time 😀.</p>
            <div className=''>
              <p className='flex items-baseline whitespace-nowrap gap-[5px]'>
                Fill out the{' '}
                <button
                  className={`${uiStyles.hover_btn_small} whitespace-nowrap`}
                  onClick={() => {
                    return navigate('/user/ai/daily/questions')
                  }}
                >
                  Bio Questions
                </button>{' '}
                to get
              </p>
              <p className='mt-[2px]'>customized content 🧐.</p>
            </div>

            <p>We'll have a customized post for you every day 📆.</p>
            <p>
              turn on texts and we will text the content to you each day 🚀. You
              can always change your mind later.
            </p>
          </div>
          <div className='flex justify-end gap-[10px] p-3'>
            <button
              className={uiStyles.white_btn_small}
              onClick={() => {
                signup(0)
              }}
            >
              Start without texts
            </button>
            <button
              className={uiStyles.hover_btn_small}
              onClick={() => {
                signup(1)
              }}
            >
              Start with texts
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AiDailyFirst
