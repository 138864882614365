import { useState, useEffect } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { BsBoxArrowRight } from 'react-icons/bs'
import { TfiPencilAlt } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useVBCs } from '../../../contexts/VBCContext'
import { set_storage } from '../../../helpers'
import { List } from '../../helpers'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import DBCSend from '../VBCDetails/DBCSend'
import ReceiverDBCSend from '../VBCDetails/ReceiverDBCSend'
import EditBuyer from './EditBuyer'
import RemoveBuyer from './RemoveBuyer'

const AddBuyer = ({ send_to_buyers = false }) => {
  const {
    listingTours,
    saveListingTours,
    get_buyers,
    get_homes,
    is_buyer_tour,
  } = useListingTours()
  const { VBCs } = useVBCs()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [singleName, setSingleName] = useState('')
  const [singleNumber, setSingleNumber] = useState('')
  const modals = {
    edit_buyer: (
      <EditBuyer
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
      />
    ),
    remove_buyer: (
      <RemoveBuyer
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
      />
    ),
    share_buyer: (
      <DBCSend
        sendId={
          (VBCs?.cards || [])?.find((item) => item?.RECEIVER_TYPE_ID === 2)?.ID
        }
        vbc_msg={async () => {
          return 'Check out my custom Realty Smart Business Card where I have your tour of homes ready to go.'
        }}
        single_name={singleName}
        single_number={singleNumber}
      />
    ),
  }
  const [buyers, setBuyers] = useState([])
  const [inputValue, setInputValue] = useState(
    listingTours?.buyer_tours?.buyer_edit || {}
  )
  useEffect(() => {
    if (!listingTours?.buyer_tours) {
      navigate('/user/buyer_tours')
    }
  }, [])
  useEffect(() => {
    const data = listingTours?.data?.event_tour_info
      ?.filter(
        (item) =>
          item.evt_id?.toString() ===
            listingTours?.buyer_tours?.event_edit?.event_id?.toString() &&
          item?.cont_id > 0 &&
          !item?.mls_num
      )
      ?.sort((a, b) => a.tour_order - b.tour_order)
      ?.map((item) => {
        item.title = item.agent_name
        item.content = item.agent_cell_phone
        item.swipe_right = () => {
          return (
            <div className='flex gap-[30px]'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  saveListingTours((prev) => {
                    const ret_data = {
                      ...prev?.buyer_tours,
                      buyer_edit: { ...item, new_buyer: false },
                    }
                    set_storage('buyer_tours', ret_data)
                    return {
                      ...prev,
                      buyer_tours: ret_data,
                    }
                  })

                  setModalType('edit_buyer')
                  setShowModal(true)
                }}
              >
                <TfiPencilAlt size={25} />
              </div>

              {is_buyer_tour() ? (
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    saveListingTours((prev) => {
                      const ret_data = {
                        ...prev?.buyer_tours,
                        buyer_edit: item,
                        full_run: true,
                      }
                      set_storage('buyer_tours', ret_data)
                      console.log('send', item)
                      setSingleName(item?.agent_name)
                      setSingleNumber(item?.agent_cell_phone || '')
                      return {
                        ...prev,
                        buyer_tours: ret_data,
                      }
                    })
                    setModalType('share_buyer')
                    setShowModal(true)
                  }}
                >
                  <BsBoxArrowRight size={25} />
                </div>
              ) : (
                ''
              )}
              <div
                className='cursor-pointer'
                onClick={() => {
                  saveListingTours((prev) => {
                    const ret_data = { ...prev?.buyer_tours, buyer_edit: item }
                    set_storage('buyer_tours', ret_data)
                    return {
                      ...prev,
                      buyer_tours: ret_data,
                    }
                  })
                  setModalType('remove_buyer')
                  setShowModal(true)
                }}
              >
                <AiFillDelete size={25} />
              </div>
            </div>
          )
        }
        return item
      })
    setBuyers(data)
  }, [listingTours?.data?.event_tour_info])
  return (
    <div>
      <p className={`px-3 mb-[5px] ${uiStyles.text_black_subtitle}`}>
        {send_to_buyers ? 'Share With People on Tour' : 'People on Tour'}
      </p>
      {is_buyer_tour() ? (
        <p className='px-3 mb-[5px] text-[13px] text-thirdary'>
          After adding people you can share your vbc with them by swiping and
          clicking share!
        </p>
      ) : (
        ''
      )}
      <div className='overflow-y-scroll min-h-[100px] max-h-[300px]'>
        <List
          no_items_msg={'Add someone on the tour.'}
          list={buyers}
          reset_slider={true}
        />
      </div>
      <div className='px-3 mt-3 flex justify-end gap-[10px]'>
        <button
          className={`${uiStyles.hover_btn}`}
          onClick={() => {
            saveListingTours((prev) => {
              const ret_data = {
                ...prev?.buyer_tours,
                buyer_edit: { new_buyer: true },
              }
              set_storage('buyer_tours', ret_data)
              return {
                ...prev,
                buyer_tours: ret_data,
              }
            })
            setModalType('edit_buyer')
            setShowModal(true)
          }}
        >
          {is_buyer_tour() ? 'Create New Buyer' : 'Add New Agent'}
        </button>
      </div>
      {/* <div className={`${uiStyles.line_3px} mt-[10px]`}></div>
      <div className='px-3 mt-3 flex justify-end gap-[10px]'>
        {get_homes(listingTours?.buyer_tours?.event_edit?.event_id)?.length ? (
          <div className='flex gap-[10px]'>
            <button
              className={`${uiStyles.hover_btn}`}
              onClick={() => {
                navigate('/user/buyer_tours/add_home')
              }}
            >
              Add Homes
            </button>
            <button
              className={`${uiStyles.white_btn}`}
              onClick={() => {
                return navigate('/user/buyer_tours')
              }}
            >
              Done
            </button>
          </div>
        ) : (
          <button
            className={`${uiStyles.hover_btn}`}
            onClick={() => {
              navigate('/user/buyer_tours/add_home')
            }}
          >
            Add Homes
          </button>
        )}
      </div> */}
      {showModal ? (
        <Modal
          modalName='create_buyer_tour'
          showModal={showModal}
          setShowModal={setShowModal}
          // width={'w-[350px]'}
          side_padding={['add_buyer'].includes(modalType) ? 'px-0' : ''}
          height={
            modalType === 'edit_buyer'
              ? 'h-[300px]'
              : modalType?.includes('remove')
              ? 'h-[175px]'
              : 'h-[550px]'
          }
        >
          {modals?.[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default AddBuyer
