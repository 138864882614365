import { useRef, useEffect, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { usePairwise } from '../../../contexts/PairwiseContext'
import {
  formatPhoneNumber,
  format_input,
  get_storage,
  set_storage,
  validate_number,
} from '../../../helpers'
import LabelInput from '../../form/LabelInput'
import { Schedule } from '../../form/Schedule'
import Select from '../../form/Select'
import uiStyles from '../../ui/uiStyles'
import {
  GoogleMap,
  Autocomplete,
  useJsApiLoader,
  CircleF,
  MarkerF,
  InfoBoxF,
  InfoWindowF,
} from '@react-google-maps/api'
import { libraries } from '../home/utils'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import { useProfile } from '../../../contexts/UserContext'
import { AiFillCloseCircle } from 'react-icons/ai'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useMktReqs } from '../../../contexts/MktReqsContext'
import PairwiseDocumentsView from './PairwiseDocumentsView'
import RTP from '../TransactionsLobby/RTP'
import PairwiseRTP from './PairwiseRTP'
import Modal from '../../Modal'
import PairwiseIneligible from './PairwiseIneligible'
import { useClaims } from '../../../contexts/ClaimsContext'
import PairwiseDocumentsSign from './PairwiseDocumentsSign'

const PairwiseAccount = () => {
  const { claims } = useClaims()
  return (
    <>
      {claims?.client_proxy_login ? (
        <div className='px-3'>
          This page can only be used by the client. The client must be
          officially logged in.
        </div>
      ) : (
        <PairwiseDocumentsSign account_update={true} />
      )}
    </>
  )
}

export default PairwiseAccount
