import { useEffect, useState, useRef } from 'react'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import LabelInput from '../form/LabelInput'
import { useAiDaily } from '../../contexts/AiDailyContext'
import { set_storage } from '../../helpers'
import uiStyles from '../ui/uiStyles'
import { MdAdsClick } from 'react-icons/md'

const AiGallery = ({ setShowModal, setModalType, prevModalType }) => {
  const {
    aiDaily,
    upd_daily_signup,
    saveAiDaily,
    gen_daily_resp,
    save_resp,
    save_content,
    save_post,
    save_slide,
  } = useAiDaily()
  const { axiomFetch } = useAxiomFetch()
  const [search, setSearch] = useState({ page: 1, page_size: 10, text: '' })
  const searchRef = useRef(null)

  useEffect(() => {
    searchRef.current.focus()
  }, [])
  useEffect(() => {
    const controller = new AbortController()

    const get_data = async () => {
      console.log(search?.text)
      const data = await axiomFetch(
        `/marketing/content/ai/daily/images/ai_gallery?text=${
          search?.text || ''
        }&page=${search?.page || 1}&page_size=${search?.page_size || 10}`,
        'GET',
        {},
        {},
        false,
        controller?.signal,
        400
      )
      if (data?.name === 'AbortError') {
        return
      }
      console.log('slides', data)

      save_post({ gallery: Array.isArray(data) ? data : [] })
    }
    get_data()
    return () => controller?.abort()
  }, [search?.text, search?.page, search?.page_size])

  const change_page = (add_page) => {
    setSearch((prev) => {
      if (prev?.page + add_page < 1) {
        return { ...prev }
      }
      return { ...prev, page: prev?.page + add_page }
    })
  }
  return (
    <div>
      <LabelInput
        ref={searchRef}
        name='search'
        value={search?.text || ''}
        handleChange={(e) => {
          setSearch((prev) => ({
            ...prev,
            page: 1,
            text: e?.target?.value || '',
          }))
        }}
        placeholder='search...'
        show_delete_all={true}
      />
      <div className='flex justify-around my-1'>
        <button
          className={uiStyles.hover_btn_small}
          onClick={() => change_page(-1)}
        >
          Prev
        </button>
        <div>{search.page}</div>
        <button
          className={uiStyles.hover_btn_small}
          onClick={() => change_page(1)}
        >
          Next
        </button>
      </div>
      <div className='flex flex-col gap-1'>
        {Array.isArray(aiDaily?.post?.gallery) ? (
          aiDaily?.post?.gallery?.map((item) => {
            return (
              <div id={item?.img_id} className='relative'>
                <div className='absolute z-20 right-0 mt-[-5px] mr-[-5px]'>
                  {<MdAdsClick color={'gray'} />}
                </div>
                <img
                  src={item?.img_url}
                  onClick={() => {
                    if (prevModalType === 'video') {
                      save_slide({
                        ...(aiDaily?.post?.slide || {}),
                        image: item?.img_url,
                      })
                      setShowModal(false)
                    } else if (prevModalType === 'image') {
                      save_content({ resp_img: item?.img_url })
                      setShowModal(false)
                    }
                  }}
                />
              </div>
            )
          })
        ) : (
          <div>No Images found</div>
        )}
      </div>
    </div>
  )
}

export default AiGallery
