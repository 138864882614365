import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import Hero from '../Hero'

import { content_types, DUMMYMARKETINGLOBBY } from '../../data/data'
import Header2 from '../Header2'
import Wrapper from '../layout/Wrapper'
import Transaction from '../ui/Transaction'
import ReviewVerified from '../../assets/images/review-verified.jpg'
import axiomLogo from '../../assets/images/axiom_driggs_logo.svg'
import { useTransactions } from '../../contexts/TransactionsContext'

import { useReviews } from '../../contexts/ReviewsContext'
import { useListing } from '../../contexts/ListingContext'
import SwipeConfig from '../ui/SwipeConfig'
import ListItem from '../ui/ListItem'
import { useProfile } from '../../contexts/UserContext'
import PropertyItem from '../ui/PropertyItem'
import { Link, useLocation } from 'react-router-dom'
import facebook from '../../assets/images/facebook_icon.svg'
import facebook_white from '../../assets/images/facebook_white_icon.svg'
import instagram from '../../assets/images/instagram_icon.svg'
import twitter from '../../assets/images/twitter_icon.svg'
import linkedin from '../../assets/images/linkedin_icon.svg'
import sendgrid from '../../assets/images/sendgrid_icon.svg'
import google from '../../assets/images/google_icon.svg'
import plusIcon from '../../assets/images/plus_icon.svg'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import images_homescreen from '../../assets/images/add_homescreen'
import { useNavigate } from 'react-router-dom'
import { Videos } from '../../data/data'
import { useConnections } from '../../contexts/ConnectionContext'
import { useClaims } from '../../contexts/ClaimsContext'
import uiStyles from '../ui/uiStyles'
import { useConversations } from '../../contexts/ConversationsContext'
import { NotificationsIcon } from './conversations/NotificationsIcon'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import { useAi } from '../../contexts/AiContext'
import { useDistribute } from '../../contexts/DistributeContext'
import Modal from '../Modal'
import images_logos from '../../assets/images/axiom_logos'
import { useMktReqs } from '../../contexts/MktReqsContext'
import { MarketingMaterialSlider } from '../../components/pages/marketingMaterials/MarketingMaterialSlider'
import { MarketingMaterialListingSlider } from './marketingMaterials/MarketingMaterialListingSlider'
import { useListingTours } from '../../contexts/ListingToursContext'
import { useReceiver } from '../../contexts/ReceiverContext'
import { Swiper, SwiperSlide } from 'swiper/react'
import { TourSlider } from './tours'
import images_admin from '../../assets/images/admin'
import images_internal from '../../assets/images/internal'
import { get_storage, open_url_tab, set_storage } from '../../helpers'
import WeekenderSplash from './marketingMaterials/WeekenderSplash'
import images_homes from '../../assets/images/home'
import useHelpers from '../../hooks/useHelpers'
const portalElement = document.getElementById('overlays')

function UserHome() {
  const { distribute, saveDistribute } = useDistribute()
  const { open_url } = useHelpers()
  const { convMessages, conversations, campaigns, loadingConversations } =
    useConversations()
  const { axiomFetch } = useAxiomFetch()
  const { claims } = useClaims()
  const { saveAi } = useAi()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selected, setSelected] = useState(1)
  const { profile } = useProfile()
  const { transactions } = useTransactions()
  const { reviews } = useReviews()
  const { listing } = useListing()
  const { mktReqs, saveMktReqs } = useMktReqs()
  const { receiver, saveReceiver } = useReceiver()
  const { listingTours, saveListingTours } = useListingTours()
  const [loading, setLoading] = useState({ weekender: false })
  const [weekenderLoader, setWeekenderLoader] = useState(false)
  const firstTransaction = transactions && transactions[0]
  const newListing = listing && listing[0]

  const newrReview = reviews && reviews[0]
  const marketingApproval = DUMMYMARKETINGLOBBY?.filter(
    (item) => item.id === 'my_marketing'
  )?.[0]
  const axiom_ai_card = DUMMYMARKETINGLOBBY?.filter(
    (item) => item.id === 'axiom_content'
  )?.[0]
  const conversations_card = DUMMYMARKETINGLOBBY?.filter(
    (item) => item.id === 'conversations'
  )?.[0]
  const { connections, saveConnections } = useConnections()
  const [socialConnection, setSocialConnection] = useState('')
  const [introVidWatched, setIntroVidWatched] = useState(false)
  const [engageContent, setEngageContent] = useState([])
  const [fPosts, setFPosts] = useState([])
  const [showPostCount, setShowPostCount] = useState(0)
  const [agentSlots, setAgentSlots] = useState([])
  const [agentAiSlots, setAgentAiSlots] = useState([])
  const [agentUrlSlots, setAgentUrlSlots] = useState([])
  const [showModal, setShowModal] = useState(
    localStorage?.getItem('add_homescreen') === 'done' ? false : true
  )
  const [modalType, setModalType] = useState('home_screen_share')

  const modals = {
    home_screen_share: (
      <div className='flex'>
        <div className='w-[300px] flex flex-col items-center p-2 rounded m-1 opacity-100'>
          <p className='text-[15px] text-center mb-[10px]'>
            To add this app to your homescreen click share
          </p>
          <img src={images_homescreen.share} className='w-[25px]' />
          <p>Then scroll down and click</p>
          <img className='w-[220px]' src={images_homescreen.add_to_screen} />
          <div className='flex justify-end mt-[30px] gap-[5px]'>
            <button
              className={uiStyles.white_btn}
              onClick={() => {
                localStorage.setItem('add_homescreen', 'later')
                setShowModal(false)
              }}
            >
              Ask me again later
            </button>
            <button
              data-cy='got_it_button'
              className={uiStyles.hover_btn}
              onClick={() => {
                localStorage.setItem('add_homescreen', 'done')
                setShowModal(false)
              }}
            >
              Got it!
            </button>
          </div>
        </div>
      </div>
    ),
  }
  const get_count = (id) => {
    let total = 0
    if (id === 'conversations') {
      for (const conversation of conversations) {
        total += convMessages?.[conversation?.sid]?.unread_count || 0
      }
      for (const campaign of Object.values(campaigns)) {
        for (const conv of campaign?.conversations) {
          total += convMessages[conv?.sid]?.unread_count || 0
        }
      }
    }
    return total
  }

  useEffect(() => {
    localStorage.removeItem('uuid')
    set_storage('vbc_calc', '')
    set_storage('home_view_calc', '')
    const redirect = get_storage('redirect')
    if (redirect) {
      localStorage.removeItem('redirect')
      navigate(redirect)
    }
  }, [])

  // useEffect(() => {
  //   if (get_storage('need_refresh')) {
  //     localStorage.removeItem('need_refresh')
  //     window.location.reload()
  //   }
  // }, [])

  useEffect(() => {
    const controller = new AbortController()
    const check_connections = async () => {
      const connections = await axiomFetch(
        claims?.is_internal === 'Y'
          ? '/usage/checkSocialConnections'
          : '/usage/checkSocialConnections',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      saveConnections((prev) => ({ ...prev, ...connections }))
      if (connections?.axiom_intro_video) {
        setIntroVidWatched(true)
      }
      if (!connections?.facebook) {
        setSocialConnection('facebook')
        // } else if (!connections?.instagram) {
        //   setSocialConnection('instagram')
      } else if (!connections?.sendgrid) {
        setSocialConnection('sendgrid')
      } else {
        setSocialConnection('')
      }
      if (controller.signal.aborted) {
        setSocialConnection('')
      }
    }
    check_connections()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_slot_order = async () => {
      const setup_slots = (agent_lobby_slots) => {
        if (agent_lobby_slots?.length) {
          const agent_url_slots = agent_lobby_slots?.filter((item) =>
            item?.SLOT_LINK?.includes('/')
          )
          setAgentUrlSlots(agent_url_slots)

          const agent_ai_slots = agent_lobby_slots?.filter(
            (item) => item?.SLOT_AI_NUM > 0
          )
          setAgentAiSlots(agent_ai_slots)

          const marketing_slots = agent_lobby_slots
            ?.filter((item) => item?.SLOT_LINK?.startsWith('marketing**'))
            .map((item) => item?.SLOT_LINK?.split('**')[1])

          const get_types = async (types) => {
            // const types = await axiomFetch('/marketing/content/types', 'GET', {}, {}, false, controller.signal)
            let result = []
            result = types?.map(async (type) => {
              const content = await axiomFetch(
                `/marketing/content/types/${type}/items`,
                'GET',
                {},
                {},
                false,
                controller.signal
              )
              return content?.[0]
            })

            result = await Promise.all(result)
            if (result?.length) {
              setEngageContent((prev) => [...prev, ...result])
            }
          }

          if (marketing_slots?.length) {
            get_types(marketing_slots)
          }
          set_storage('agent_lobby_slots', agent_lobby_slots)
          setAgentSlots(agent_lobby_slots)
        }
      }
      if (get_storage('agent_lobby_slots')) {
        setup_slots(get_storage('agent_lobby_slots'))
      }
      const agent_slots = await axiomFetch(
        '/lobbies/agent_lobby_slots',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )

      setup_slots(agent_slots)
      //
    }
    get_slot_order()
    return () => {
      controller?.abort()
    }
  }, [])

  const find_agent_slot = function ({ name, link }) {
    let slot
    if (name) {
      slot =
        agentSlots?.length &&
        agentSlots?.filter((item) =>
          item?.SLOT_NAME?.toLowerCase()?.includes(name)
        )[0]
    } else if (link) {
      slot =
        agentSlots?.length &&
        agentSlots?.filter((item) => item?.SLOT_LINK?.toLowerCase() === link)[0]
    }
    return slot
  }

  const icons = {
    facebook,
    linkedin,
    twitter,
    instagram,
    sendgrid,
    google,
    facebook_white,
  }

  const connection_slot = (media) => {
    return (
      <div className='swipeable flex gap-[55px] py-[10px]'>
        <div className='swipeable pl-[10px] flex gap-2'>
          <img
            alt='axiom'
            className='swipeable w-[40px] object-contain'
            src={
              find_agent_slot({ link: 'media_connect' })?.SLOT_THUMB_IMG ||
              images_logos.axiom_small_png
            }
          />
          <img alt='plus' className='swipeable w-[20px]' src={plusIcon} />
          <img
            alt={media}
            className='swipeable w-[45px] h-[45px]'
            src={icons[media]}
          />
        </div>
        <div className='swipeable flex flex-col'>
          <p className='swipeable text-gray-800 text-xs font-base font-semibold mb-[2px]'>
            Connect to <span className='swipeable capitalize'>{media}</span>
          </p>
          <p className='swipeable text-xs text-gray-500 font-base font-extralight'>
            Let's get your <span className='swipeable capitalize'>{media}</span>{' '}
            account connected to AXIOM!
          </p>
        </div>
      </div>
    )
  }

  const engage_slots = {}

  for (const content of engageContent || []) {
    engage_slots[content?.ITEM_ID] = {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={() => {
            // saveDistribute((prev) => ({
            //   ...prev,
            // type: 'engage',
            // engage_type: content?.CONTENT_TYPE_ID,
            // engage_item: content,
            // }))
            // navigate('/user/axiom-ai')
            open_url(content?.ITEM_LINK)
          }}
        >
          View{' '}
        </div>
      ),
      component: (
        <ListItem
          image={content?.IMAGE_LINK}
          title={content?.HEADLINE}
          content={content?.SUB_HEADLINE}
        />
      ),
      order: find_agent_slot({ link: `marketing**${content?.CONTENT_TYPE_ID}` })
        ?.SLOT_ORDER,
      show:
        claims?.is_internal === 'Y'
          ? false
          : find_agent_slot({ link: `marketing**${content?.CONTENT_TYPE_ID}` })
          ? true
          : false,
    }
  }

  const agent_url_slots = {}

  for (const slot of agentUrlSlots || []) {
    agent_url_slots[slot?.SLOT_LINK] = {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={() => {
            // updateOptions({
            //   type: 'engage',
            //   engage_type: content?.CONTENT_TYPE_ID,
            //   engage_item: content,
            // })
            if (slot?.SLOT_LINK === '/user/ai/assistant') {
              saveAi((prev) => ({
                ...prev,
                assistant: { ...prev?.assistant, is_assistant: 'Y' },
                type: 'social',
                social: 'facebook',
                state: {
                  ...prev?.state,
                  message: '',
                  long_message: '',
                  short_message: '',
                  subject: '',
                  original_prompt: '',
                  last_prompt: '',
                  prompt: '',
                  // stopHeight: 500 * height_change,
                  isLoading: false,
                  run: false,
                },
                image_upload: {
                  ...prev?.image_upload,
                  upload_url: '',
                  gallery: {
                    ...prev?.image_upload?.gallery,
                    active: {
                      ...prev?.image_upload?.gallery?.active,
                      url: '',
                    },
                  },
                },
                // ai?.image_upload?.upload_url || ai?.image_upload?.gallery?.active?.url
              }))

              for (const content_type of content_types) {
                saveDistribute((prev) => ({
                  ...prev,
                  content: {
                    ...prev.content,
                    [content_type]: {
                      ...(prev?.content?.[content_type] || {}),
                      message: '',
                      long_message: '',
                      short_message: '',
                      posted_id: '',
                      photo_url: '',
                    },
                  },
                }))
              }
            }
            if (slot?.SLOT_LINK === '/user/marketing-material') {
              saveMktReqs((prev) => ({
                ...prev,
                home_url: '/user',
                create_order: {
                  ...prev?.create_order,
                  order_type: 2,
                  sub_topic_promotion: 61,
                  slot_group_type: 1,
                },
                my_orders_toggle: 'N',
              }))
            }
            navigate(`${slot?.SLOT_LINK}`, {
              state: { parent: '/user' },
            })
          }}
        >
          View{' '}
        </div>
      ),
      component: (
        <ListItem
          select={() => {
            // updateOptions({
            //   type: 'engage',
            //   engage_type: content?.CONTENT_TYPE_ID,
            //   engage_item: content,
            // })
            if (slot?.SLOT_LINK === '/user/ai/assistant') {
              saveAi((prev) => ({
                ...prev,
                assistant: { ...prev?.assistant, is_assistant: 'Y' },
                type: 'social',
                social: 'facebook',
                state: {
                  ...prev?.state,
                  message: '',
                  long_message: '',
                  short_message: '',
                  subject: '',
                  original_prompt: '',
                  last_prompt: '',
                  prompt: '',
                  // stopHeight: 500 * height_change,
                  isLoading: false,
                  run: false,
                },
                image_upload: {
                  ...prev?.image_upload,
                  upload_url: '',
                  gallery: {
                    ...prev?.image_upload?.gallery,
                    active: {
                      ...prev?.image_upload?.gallery?.active,
                      url: '',
                    },
                  },
                },
                // ai?.image_upload?.upload_url || ai?.image_upload?.gallery?.active?.url
              }))

              for (const content_type of content_types) {
                saveDistribute((prev) => ({
                  ...prev,
                  content: {
                    ...prev.content,
                    [content_type]: {
                      ...(prev?.content?.[content_type] || {}),
                      message: '',
                      long_message: '',
                      short_message: '',
                      posted_id: '',
                      photo_url: '',
                    },
                  },
                }))
              }
            }
            if (slot?.SLOT_LINK === '/user/marketing-material') {
              saveMktReqs((prev) => ({
                ...prev,
                home_url: '/user',
                create_order: {
                  ...prev?.create_order,
                  order_type: 2,
                  sub_topic_promotion: 61,
                  slot_group_type: 1,
                },
                my_orders_toggle: 'N',
              }))
            }
            if (slot.SLOT_LINK === '/homes') {
              saveReceiver((prev) => ({ ...prev, vbc_edit_mode: 'from_home' }))
              localStorage.setItem('vbc_edit_mode', 'from_home')
              saveListingTours((prev) => {
                const buyer_tours = {
                  ...prev?.buyer_tours,
                  property_edit: {
                    ...prev?.buyer_tours.property_edit,
                    search_type: 'from_home',
                  },
                }
                set_storage('buyer_tours', buyer_tours)
                return { ...prev, buyer_tours }
              })
            }
            navigate(`${slot?.SLOT_LINK}`, {
              state: { parent: '/user' },
            })
          }}
          click_me={true}
          image={slot?.SLOT_THUMB_IMG}
          title={slot?.SLOT_NAME}
          content={slot?.SLOT_DESC}
        />
      ),
      order: slot?.SLOT_ORDER,
      show: claims?.is_internal === 'Y' ? false : slot ? true : false,
    }
  }

  const agent_ai_slots = {}
  for (const slot of agentAiSlots || []) {
    agent_ai_slots[slot?.SLOT_AI_NUM] = {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={() => {
            saveAi((prev) => ({
              ...prev,
              slot: slot,
              mls: {
                ...prev?.mls,
                setting: 'mlsNo',
                add_mls_to_prompt: 0,
                start_ai: false,
              },
              state: {
                run: slot?.AI_GO_PROP === 'Y' ? false : true,
                prompt: slot?.AI_PRPT_PRPT,
              },
            }))
            navigate(`/user/ai`)
          }}
        >
          View{' '}
        </div>
      ),
      component: (
        <ListItem
          image={slot?.SLOT_THUMB_IMG || slot?.AI_PRPT_THUMB}
          title={slot?.SLOT_NAME || slot?.AI_PRPT_NM}
          content={
            slot?.SLOT_DESC || slot?.AI_PRPT_DESC?.split('loading:')?.[0]
          }
        />
      ),
      order: slot?.SLOT_ORDER,
      show: claims?.is_internal === 'Y' ? false : slot ? true : false,
    }
  }

  let slots = {
    transaction: {
      swipe_right: () => (
        <Link
          to={`my-transactions-lobby/transactions/${firstTransaction?.file_id}/documents`}
          state={{ parent: pathname }}
        >
          View
        </Link>
      ),
      component: (
        <Transaction
          title='Current Transaction'
          fileid={firstTransaction?.ext_id}
          address={firstTransaction?.address1}
          city={firstTransaction?.city}
          state={firstTransaction?.state}
          escrowOfficer={firstTransaction?.escrow_officer}
          lender={firstTransaction?.lender}
          isClosed={!!firstTransaction?.close_disb_dt}
          postal={firstTransaction?.postal_code}
        />
      ),
      order: find_agent_slot({ link: 'last_transaction' })?.SLOT_ORDER,
      show:
        claims?.is_internal === 'Y'
          ? false
          : find_agent_slot({ link: 'last_transaction' })
          ? firstTransaction
          : false,
    },
    review: {
      swipe_right: () => (
        <a href={`${newrReview?.POST_PATH}`} target='_blank' rel='noreferrer'>
          View
        </a>
      ),
      component: (
        <ListItem
          select={() => open_url(newrReview?.POST_PATH)}
          click_me={true}
          image={ReviewVerified}
          title={`Agent Review of  ${profile && profile?.CONT_NAME} - by ${
            newrReview?.property
          }`}
          // content={ratingHTML(newrReview)}
          link={false}
        />
      ),
      order: find_agent_slot({ link: 'last_review' })?.SLOT_ORDER,
      show:
        claims?.is_internal === 'Y'
          ? false
          : find_agent_slot({ link: 'last_review' })
          ? newrReview
          : false,
    },
    // new_listing: {
    //   swipe_right: () => (
    //     <Link to={`my-listing/${newListing?.mls_id}`}>View</Link>
    //   ),
    //   component: <PropertyItem listing={newListing} />,
    //   order: find_agent_slot({ link: 'new_listing' })?.SLOT_ORDER,
    //   show:
    //     claims?.is_internal === 'Y'
    //       ? false
    //       : find_agent_slot({ link: 'new_listing' })
    //       ? newListing
    //       : false,
    // },
    mkt_listings: {
      component: (
        <div className='bg-gray-100'>
          <MarketingMaterialListingSlider
            my_listings_look={true}
            state={mktReqs}
            setState={saveMktReqs}
            image_link={() => {
              saveReceiver((prev) => {
                localStorage.setItem('vbc_edit_mode', 'true')
                return {
                  ...prev,
                  edit_mode: 'view',
                  homes: {
                    ...prev?.homes,
                    current_home: mktReqs?.current_listing,
                  },
                }
              })

              navigate('/homes/view', {
                state: { parent: '/user/' },
              })
            }}
          />
        </div>
      ),
      order: find_agent_slot({ link: 'new_listing' })?.SLOT_ORDER,
      show:
        claims?.is_internal === 'Y'
          ? false
          : find_agent_slot({ link: 'new_listing' })
          ? listing?.length
          : false,
    },
    // listing: {
    //   swipe_right: () => <Link to={marketingApproval.link}>View</Link>,
    //   component: listing && (
    //     <ListItem
    //       image={marketingApproval.thumbnail}
    //       title={marketingApproval.title}
    //       content={marketingApproval.description}
    //     />
    //   ),
    //   order: 4,
    //   show: claims?.is_internal === 'Y' ? false : true,
    // },
    connect: {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={() => {
            saveDistribute((prev) => ({
              ...prev,
              social: ['facebook', 'instagram'].includes(socialConnection)
                ? socialConnection
                : 'facebook',
              type: ['facebook', 'instagram'].includes(socialConnection)
                ? 'social'
                : 'email',
            }))
            navigate('/user/axiom-ai-distribute')
          }}
        >
          View{' '}
        </div>
      ),
      component: connection_slot(socialConnection),
      order: 5,
      show: claims?.is_internal === 'Y' ? false : socialConnection,
    },
    axiom_intro: {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={async () => {
            await axiomFetch('/usage/updateVideoWatchHist', 'POST', {
              url: Videos?.[0]?.url,
              unique_name: Videos?.[0]?.unique_name,
            })
            setIntroVidWatched(true)
            open_url_tab(Videos?.[0]?.url)
          }}
        >
          View
        </div>
      ),
      component: (
        <ListItem
          key={`${
            find_agent_slot({ link: 'intro_video' })?.SLOT_LINK ||
            Videos?.[0]?.unique_name
          }`}
          image={
            find_agent_slot({ link: 'intro_video' })?.SLOT_THUMB_IMG ||
            Videos?.[0]?.thumbnail ||
            ''
          }
          title={
            find_agent_slot({ link: 'intro_video' })?.SLOT_NAME ||
            Videos?.[0]?.title ||
            ''
          }
          content={
            find_agent_slot({ link: 'intro_video' })?.SLOT_DESC ||
            Videos?.[0]?.description ||
            ''
          }
          fill={'object-fill'}
        />
      ),
      order: find_agent_slot({ link: 'intro_video' })?.SLOT_ORDER,
      show:
        claims?.is_internal === 'Y'
          ? false
          : find_agent_slot({ link: 'intro_video' })
          ? !introVidWatched
          : false,
    },
    axiom_ai: {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={() => {
            saveAi((prev) => ({
              ...prev,
              type: 'social',
              social: 'facebook',
            }))
            navigate('/user/axiom-ai')
          }}
        >
          View{' '}
        </div>
      ),
      component: (
        <ListItem
          image={
            find_agent_slot({ link: 'axiom_ai' })?.SLOT_THUMB_IMG ||
            axiom_ai_card?.thumbnail
          }
          title={
            find_agent_slot({ link: 'axiom_ai' })?.SLOT_NAME ||
            axiom_ai_card?.title
          }
          content={
            find_agent_slot({ link: 'axiom_ai' })?.SLOT_DESC ||
            axiom_ai_card?.description
          }
        />
      ),
      order: find_agent_slot({ link: 'axiom_ai' })?.SLOT_ORDER,
      show:
        claims?.is_internal === 'Y'
          ? false
          : find_agent_slot({ link: 'axiom_ai' })
          ? true
          : false,
    },
    listing_tours: {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={() => {
            navigate('/user/listing_tours')
          }}
        >
          View{' '}
        </div>
      ),
      component: (
        <div className='bg-gray-100'>
          <TourSlider
            state={listingTours}
            setState={saveListingTours}
            slot_settings={{
              image:
                find_agent_slot({ link: 'listing_tours' })?.SLOT_THUMB_IMG ||
                axiom_ai_card?.thumbnail,
              title:
                find_agent_slot({ link: 'listing_tours' })?.SLOT_NAME ||
                axiom_ai_card?.title,
              content:
                find_agent_slot({ link: 'listing_tours' })?.SLOT_DESC ||
                axiom_ai_card?.description,
            }}
          />
        </div>
      ),
      order: find_agent_slot({ link: 'listing_tours' })?.SLOT_ORDER,
      no_swiper: true,
      show:
        claims?.is_internal === 'Y'
          ? false
          : find_agent_slot({ link: 'listing_tours' })
          ? listingTours?.data?.event_pages?.length
            ? true
            : false
          : false,
    },
    conversations: {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={() => {
            saveAi((prev) => ({
              ...prev,
              type: 'social',
              social: 'facebook',
            }))
            navigate('/user/conversations')
          }}
        >
          View{' '}
        </div>
      ),
      component: (
        <ListItem
          image={
            find_agent_slot({ link: 'conversations' })?.SLOT_THUMB_IMG ||
            conversations_card?.thumbnail
          }
          title={
            find_agent_slot({ link: 'conversations' })?.SLOT_NAME ||
            conversations_card?.title
          }
          content={
            find_agent_slot({ link: 'conversations' })?.SLOT_DESC ||
            conversations_card?.description
          }
          notifications={
            conversations_card?.notifications ? (
              <NotificationsIcon
                count={get_count(conversations_card?.id)}
                loading={loadingConversations}
              />
            ) : (
              ''
            )
          }
        />
      ),
      order: find_agent_slot({ link: 'conversations' })?.SLOT_ORDER,
      show:
        claims?.is_internal === 'Y'
          ? false
          : find_agent_slot({ link: 'conversations' })
          ? true
          : false,
    },
    weekender: {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={async (e) => {
            let group = mktReqs?.materials?.mkt_req_weekend_img?.find(
              (group) => group.goup_id === 61
            )
            saveMktReqs((prev) => ({
              ...prev,
              create_order: {
                ...prev?.create_order,
                current_selection: group,
              },
            }))
            if (group?.items?.length > 1) {
              return navigate('layouts')
            } else {
              const layout =
                group?.items?.length === 1 ? group?.items?.[0] : group
              setLoading((prev) => ({ ...prev, weekender: true }))
              let session = { sess_info: [] }
              const get_session = async () => {
                let try_count = 0
                session = await axiomFetch(
                  `/marketing/materials/${'flyer'}`,
                  'POST',
                  {
                    layout_id: layout?.layout_id,
                    mls_id:
                      mktReqs?.create_order?.order_type === 2
                        ? mktReqs?.current_listing?.MLS_ID
                        : 0,
                    sess_id: mktReqs?.create_order?.session?.sess_id,
                    sess_info: mktReqs?.create_order?.session?.sess_info,
                    double_sided: layout?.double_sided === 'Y' ? true : false,
                  }
                )

                if (
                  session?.sess_info?.some((item) =>
                    item?.saved_val?.includes('Unexpected token')
                  ) &&
                  try_count < 3
                ) {
                  try_count++
                  return await get_session()
                } else {
                  return session
                }
              }
              session = await get_session()
              if (session?.sess_info) {
                session.sess_info = session?.sess_info?.map((item) => {
                  if (item?.orig_val === 'None') {
                    item.orig_val = ''
                    item.saved_val = ''
                  }
                  return item
                })
              }
              if (session?.sess_info?.some((item) => !item.saved_val)) {
                session.empty_fields = true
                session.edit_fields_type = 'data'
              }
              saveMktReqs((prev) => ({
                ...prev,
                home_url: '/user',
                create_order: {
                  ...prev?.create_order,
                  current_layout_selection: layout,
                  session,
                  sessions: [session],
                  switch_layout: false,
                  selected_fields: '',
                },
              }))
              setLoading((prev) => ({ ...prev, weekender: false }))
              navigate('/user/marketing-material/layouts/customize', {
                state: { parent: '/user' },
              })
            }
            // saveAi((prev) => ({
            //   ...prev,
            //   type: 'social',
            //   social: 'facebook',
            // }))
            // navigate('/user/conversations')
          }}
        >
          View{' '}
        </div>
      ),
      component: (
        <div>
          {weekenderLoader ? (
            <SmallLoadingRel />
          ) : (
            <div className='bg-gray-100 mb-[2px]' key={`weekenders`}>
              {/* <div className=''>
                <div className='border-b-2 border-gray-500 mx-[30px]'>
                  <p className='text-gray-800 font-bold text-center '>
                    {
                      mktReqs?.materials?.mkt_slot_group?.find(
                        (group) => group.group_id === 61
                      )?.group_name
                    }
                  </p>
                </div>
               </div> */}
              <div className='flex items-center'>
                {mktReqs?.materials?.mkt_req_weekend_img?.length ? (
                  <MarketingMaterialSlider
                    state={mktReqs}
                    setState={saveMktReqs}
                    setLoading={setWeekenderLoader}
                    current_item={mktReqs?.materials?.weekenders || []}
                    weekender={true}
                    sub_items={mktReqs?.materials?.weekenders?.items?.map(
                      (weekenders) => {
                        return weekenders.single_layout
                      }
                    )}
                  />
                ) : (
                  <WeekenderSplash />
                )}
              </div>
            </div>
            // <ListItem
            //   image={
            //     find_agent_slot({ link: 'weekender' })?.SLOT_THUMB_IMG ||
            //     conversations_card?.thumbnail
            //   }
            //   title={
            //     find_agent_slot({ link: 'weekender' })?.SLOT_NAME ||
            //     conversations_card?.title
            //   }
            //   content={
            //     find_agent_slot({ link: 'weekender' })?.SLOT_DESC ||
            //     conversations_card?.description
            //   }

            // />
          )}
        </div>
      ),
      order: find_agent_slot({ link: 'weekender' })?.SLOT_ORDER,
      no_swiper: true,
      show:
        claims?.is_internal === 'Y'
          ? false
          : find_agent_slot({ link: 'weekender' })
          ? true
          : false,
    },
    ...engage_slots,
    ...agent_url_slots,
    ...agent_ai_slots,
    cli_search: {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={() => {
            return navigate('/user/internal/client/search')
          }}
        >
          Search{' '}
        </div>
      ),
      component: (
        <ListItem
          image={images_internal.client_search}
          click_me
          select={() => {
            return navigate('/user/internal/client/search')
          }}
          title={'Client Search'}
          content={
            'Search for clients and then login as the client or perform other actions for the client.'
          }
          fill={''}
        />
      ),
      order: 100000,
      show: claims?.is_internal === 'Y' ? true : false,
    },
    int_calc: {
      swipe_right: () => (
        <div className='flex justify-center gap-[20px]'>
          <div
            className='cursor-pointer'
            onClick={() => {
              return navigate('/user/calculators')
            }}
          >
            View{' '}
          </div>
        </div>
      ),
      component: (
        <ListItem
          image={
            'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1708711962822.jpeg'
          }
          title={'AXIOM Estimators'}
          content={'Calculate home data for buying and selling.'}
          fill={''}
        />
      ),
      order: 100000,
      show: claims?.is_internal === 'Y' ? true : false,
    },
    ai_daily: {
      swipe_right: () => (
        <div className='flex justify-center gap-[20px]'>
          <div
            className='cursor-pointer'
            onClick={() => {
              return navigate('/user/ai/daily')
            }}
          >
            View{' '}
          </div>
          {/* <div>|</div>
          <div
            className='cursor-pointer'
            onClick={() => {
              navigate('/user/sales', {
                state: {
                  parent: '/user',
                },
              })
            }}
          >
            Post{' '}
          </div> */}
        </div>
      ),
      component: (
        <ListItem
          select={() => navigate('/user/ai/daily')}
          click_me={true}
          image={
            'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1727797198032.jpeg'
          }
          title={"Today's Daily Post"}
          content={
            'Get daily social media posts generated specifically by your input.'
          }
          fill={''}
        />
      ),
      order: 100000,
      show: claims?.is_internal === 'Y' ? true : false,
    },
    int_facebook: {
      swipe_right: () => (
        <div className='flex justify-center gap-[20px]'>
          <div
            className='cursor-pointer'
            onClick={() => {
              return navigate('/user/internal/facebook')
            }}
          >
            View{' '}
          </div>
          <div>|</div>
          <div
            className='cursor-pointer'
            onClick={() => {
              saveAi((prev) => ({
                ...prev,
                assistant: { ...prev?.assistant, is_assistant: 'N' },
                type: 'social',
                social: 'facebook',
                state: {
                  ...prev?.state,
                  message: '',
                  long_message: '',
                  short_message: '',
                  subject: '',
                  original_prompt: '',
                  last_prompt: '',
                  prompt: '',
                  // stopHeight: 500 * height_change,
                  isLoading: false,
                  run: false,
                },
                image_upload: {
                  ...prev?.image_upload,
                  upload_url: '',
                  gallery: {
                    ...prev?.image_upload?.gallery,
                    active: {
                      ...prev?.image_upload?.gallery?.active,
                      url: '',
                    },
                  },
                },
                // ai?.image_upload?.upload_url || ai?.image_upload?.gallery?.active?.url
              }))
              saveDistribute((prev) => ({
                ...prev,
                content: {
                  ...prev.content,
                  facebook: {
                    ...(prev?.content?.facebook || {}),
                    message: '',
                    long_message: '',
                    short_message: '',
                    posted_id: '',
                    photo_url: '',
                  },
                },
              }))
              navigate('/user/axiom-ai', {
                state: {
                  parent: '/user',
                },
              })
            }}
          >
            Post{' '}
          </div>
        </div>
      ),
      component: (
        <ListItem
          image={images_logos.driggs_insider_logo}
          title={'Driggs Insider'}
          content={"See what's new in the Driggs Insider."}
          fill={''}
        />
      ),
      order: 100000,
      show: claims?.is_internal === 'Y' ? true : false,
    },
    sales: {
      swipe_right: () => (
        <div className='flex justify-center gap-[20px]'>
          <div
            className='cursor-pointer'
            onClick={() => {
              return navigate('/user/sales/what_next')
            }}
          >
            View{' '}
          </div>
          {/* <div>|</div>
          <div
            className='cursor-pointer'
            onClick={() => {
              navigate('/user/sales', {
                state: {
                  parent: '/user',
                },
              })
            }}
          >
            Post{' '}
          </div> */}
        </div>
      ),
      component: (
        <ListItem
          select={() => navigate('/user/sales/what_next')}
          click_me={true}
          image={
            'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1714764579071.jpeg'
          }
          title={'Sales'}
          content={"See what's next."}
          fill={''}
        />
      ),
      order: 100000,
      show: claims?.is_internal === 'Y' ? true : false,
    },
    property_search: {
      swipe_right: () => (
        <div className='flex justify-center gap-[20px]'>
          <div
            className='cursor-pointer'
            onClick={() => {
              //listingTours?.buyer_tours?.property_edit?.search_type
              saveReceiver((prev) => ({ ...prev, vbc_edit_mode: 'from_home' }))
              localStorage.setItem('vbc_edit_mode', 'from_home')
              saveListingTours((prev) => {
                const buyer_tours = {
                  ...prev?.buyer_tours,
                  property_edit: {
                    ...prev?.buyer_tours.property_edit,
                    search_type: 'from_home',
                  },
                }
                set_storage('buyer_tours', buyer_tours)
                return { ...prev, buyer_tours }
              })
              return navigate('/homes')
            }}
          >
            View{' '}
          </div>
          {/* <div>|</div>
          <div
            className='cursor-pointer'
            onClick={() => {
              navigate('/user/sales', {
                state: {
                  parent: '/user',
                },
              })
            }}
          >
            Post{' '}
          </div> */}
        </div>
      ),
      component: (
        <ListItem
          select={() => {
            saveReceiver((prev) => ({ ...prev, vbc_edit_mode: 'from_home' }))
            localStorage.setItem('vbc_edit_mode', 'from_home')
            saveListingTours((prev) => {
              const buyer_tours = {
                ...prev?.buyer_tours,
                property_edit: {
                  ...prev?.buyer_tours.property_edit,
                  search_type: 'from_home',
                },
              }
              set_storage('buyer_tours', buyer_tours)
              return { ...prev, buyer_tours }
            })
            navigate('/homes')
          }}
          click_me={true}
          image={
            'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1717802546721.jpeg'
          }
          title={'Advanced Property Search'}
          content={'Find properties for your clients.'}
          fill={''}
        />
      ),
      order: 100000,
      show: claims?.is_internal === 'Y' ? true : false,
    },
  }

  if (profile?.is_admin === 'Y') {
    slots = {
      admin_markets: {
        swipe_right: () => (
          <div
            className='cursor-pointer'
            onClick={() => {
              navigate('/user/admin/markets')
            }}
          >
            View{' '}
          </div>
        ),
        component: (
          <ListItem
            image={images_admin.services}
            title={'Markets'}
            content={'create markets to only show what matters to the client.'}
            // fill={post?.PHOTO ? 'object-cover object-top' : 'object-contain'}
          />
        ),
        order: 1,
        order_desc: true,
        show: profile?.is_admin === 'Y' ? true : false,
      },
    }
  }
  return (
    <Wrapper>
      <Header2 />
      <Hero showName={true} />
      {Object.keys(slots)?.length ? (
        <div className='flex flex-col gap-[2px]'>
          {Object.entries(slots)
            ?.filter((item) => item?.[1]?.show)

            ?.sort((a, b) => {
              if (a?.[1]?.order_desc || b?.[1]?.order_desc) {
                return b?.[1]?.order - a?.[1]?.order
              } else {
                return a?.[1]?.order - b?.[1]?.order
              }
            })
            .map(([key, slot], index) =>
              slot?.component ? (
                loading[key] ? (
                  <SmallLoadingRel key={key} />
                ) : slot?.swipe_right ? (
                  slot?.no_swiper ? (
                    slot?.component || <></>
                  ) : (
                    <SwipeConfig
                      index={index}
                      selectedIndex={selected}
                      setSelectedIndex={setSelected}
                      widthConfigRight={44}
                      RightComponents={slot?.swipe_right || ''}
                      key={key}
                    >
                      {slot?.component || <></>}
                    </SwipeConfig>
                  )
                ) : (
                  <div key={key}>{slot?.component || <></>}</div>
                )
              ) : (
                <div key={key}></div>
              )
            )}
        </div>
      ) : (
        <SmallLoadingRel />
      )}
      {showModal ? (
        <Modal
          modalName={modalType}
          showModal={showModal}
          setShowModal={setShowModal}
          height='h-[300px]'
        >
          {modals?.[modalType || 'home_screen_share'] || ''}
        </Modal>
      ) : (
        ''
      )}
    </Wrapper>
  )
}

export default UserHome
