import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { AiOutlineClose } from 'react-icons/ai'
import Usage from './usage/Usage'

const Backdrop = (props) => {
  return (
    <div className='fixed left-0 right-0 bottom-0 top-0 backdrop backdrop-blur-[4px] backdrop-saturate-200 z-50'>
      <div className='backdrop bg-blue-300 w-full h-full opacity-50'></div>
    </div>
  )
}

const ModalOverlay = (props) => {
  return (
    <div className={`fixed h-[100%]`}>
      {props?.showClose ? (
        <div className='ml-2 mt-2 cursor-pointer flex z-10'>
          <AiOutlineClose
            className='backdrop z-10'
            size={20}
            onClick={() => {
              if (props.clear_interval) {
                clearInterval(props.clear_interval)
              }
              props.setShowModal(false)
            }}
          />
        </div>
      ) : (
        ''
      )}
      <div
        className={`child py-4 ${
          props?.side_padding ? props?.side_padding : 'px-3'
        } space-y-4 min-w-[320px]`}
      >
        {props.children}
      </div>
    </div>
  )
}

const portalElement = document.getElementById('overlays')

const Modal2 = (props) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => document.body.classList.remove('overflow-hidden')
  }, [])
  useEffect(() => {
    if (props.showModal) {
      document
        .querySelector('.backdrop')
        .scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [props.showModal])
  const height = props.height || 'h-[500px]'
  const width = props.width || 'max-w-[320px]'
  const side_padding = props.side_padding || 'px-3'
  return (
    <>
      <Usage modalName={props?.modalName || 'modal'} />
      {props.showModal ? (
        <div
          onClick={(e) => {
            if (props.clear_interval) {
              clearInterval(props.clear_interval)
            }
            if (
              Array.from(e?.target?.className?.baseVal || 'none')?.includes(
                'backdrop'
              )
            ) {
              props.setShowModal(false)
            } else if (
              e?.target?.className &&
              (Array.isArray(e.target.className) ||
                typeof e.target.className === 'string') &&
              e?.target?.className?.includes('backdrop')
            ) {
              props.setShowModal(false)
            }
          }}
        >
          {ReactDOM.createPortal(
            <Backdrop onClose={props.onClose} />,
            portalElement
          )}
          {ReactDOM.createPortal(
            <ModalOverlay
              setShowModal={props?.setShowModal}
              showClose={props?.showClose || true}
              height={height}
              width={width}
              side_padding={side_padding}
            >
              {props.children}
            </ModalOverlay>,
            portalElement
          )}
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default Modal2
