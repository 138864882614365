import { useEffect, useState } from 'react'
import { useSales } from '../../contexts/SalesContext'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import SalesList from './SalesList'
import { SwiperSlide, Swiper } from 'swiper/react'
import ButtonsRow from '../ui/ButtonsRow'
import { List } from '../helpers'
import { validate_number } from '../../helpers'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import { ClientSearch } from '../internal'

const WhatNext = () => {
  const { sales, saveSales } = useSales()
  const [currSwiper, setCurrSwiper] = useState({})
  // const [deckSwiper, setDeckSwiper] = useState({})
  const [loading, setLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()

  return (
    <div>
      <div className='px-3 mb-[10px]'>
        <ButtonsRow
          items={['client_search', 'what_next', 'on_deck']}
          selected={sales?.selected || 'what_next'}
          handler={(item) => {
            const position = {
              client_search: 0,
              what_next: 1,
              on_deck: 2,
            }
            if (currSwiper) {
              currSwiper?.slideTo(position[item])
            }
            saveSales((prev) => {
              const sales = {
                ...prev,
                selected: item,
              }
              return sales
            })
          }}
        />
      </div>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        initialSlide={1}
        onSlideChange={(e) => {
          const position = {
            0: 'client_search',
            1: 'what_next',
            2: 'on_deck',
          }

          saveSales((prev) => ({
            ...prev,
            selected: position[e.realIndex],
          }))
        }}
        onSwiper={(swiper) => {
          try {
            // const index = 0
            // if (index > 0) {
            //   swiper.slideTo(index === -1 ? 0 : index, 0)
            //   return
            // }
            // if (index === 0 || index === -1) {
            //   swiper.slideTo(1, 900)
            // }
            // setTimeout(() => {
            //   if (index === 0 || index === -1) {
            //     swiper.slideTo(0, 900)
            //   }
            //   swiper.slideTo(index === -1 ? 0 : index, 0)
            //   setSwiper(swiper)
            // }, 175)

            if (sales?.selected === 'on_deck') {
              swiper.slideTo(2, 0)
            }
            // swiper.slideTo(1)
            if (swiper) {
              setCurrSwiper(swiper)
            }
          } catch (error) {
            console.log(error)
          }
        }}
      >
        <SwiperSlide>
          <div>
            {(sales?.selected || 'what_next') === 'client_search' ? (
              <ClientSearch type='no_swipe' />
            ) : (
              ''
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='px-3'>
            {sales?.what_next_clients?.filter(
              (item) => validate_number(item?.total_score) > 0
            )?.length && (sales?.selected || 'what_next') === 'what_next' ? (
              <div className=''>
                <SalesList
                  type='what_next'
                  list={sales?.what_next_clients
                    ?.filter((item) => validate_number(item?.total_score) > 0)
                    ?.sort(
                      (a, b) =>
                        validate_number(b?.total_score) -
                        validate_number(a?.total_score)
                    )}
                />
              </div>
            ) : (
              <div className='min-h-[550px]'>
                {' '}
                <p className='font-bold text-center text-[18px]'>
                  Looks like you cleared what's next!
                </p>
                <img
                  src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${5}.png`}
                />
              </div>
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='px-3'>
            {sales?.what_next_clients?.filter(
              (item) => validate_number(item?.total_score) > 0
            )?.length ? (
              <div className='min-h-[550px]'>
                <div>
                  <p className='font-bold text-center text-[18px]'>
                    Make Sure to clear What's next first!
                  </p>
                  <img
                    src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${1}.png`}
                  />
                </div>
              </div>
            ) : [
                ...(sales?.on_deck?.data?.open_house_listings || []),
                ...(sales?.on_deck?.data?.just_listings || []),
                ...(sales?.on_deck?.data?.old_listings || []),
              ]?.length && sales?.selected === 'on_deck' ? (
              sales?.on_deck?.loading ? (
                <SmallLoadingRel />
              ) : (
                <div>
                  <div className='px-3 mb-[10px]'>
                    <p className='font-bold'>Who's On Deck</p>
                    <ButtonsRow
                      padding={'p-1'}
                      items={['<_6_DOM', 'open_house', '>_59_DOM']}
                      selected={sales?.on_deck?.selected || '<_6_DOM'}
                      handler={(item) => {
                        const position = {
                          ['<_6_DOM']: 0,
                          open_house: 1,
                          ['>_59_DOM']: 2,
                        }
                        // if (deckSwiper) {
                        //   deckSwiper?.slideTo(position[item])
                        // }
                        saveSales((prev) => {
                          const sales = {
                            ...prev,
                            on_deck: {
                              ...prev?.on_deck,
                              selected: item,
                            },
                          }
                          return sales
                        })
                      }}
                    />
                  </div>
                  <div className='max-h-[550px] overflow-scroll'>
                    {/* <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    initialSlide={0}
                    onSlideChange={(e) => {
                      const position = {
                        0: 'open_house',
                        1: '<_6_DOM',
                        1: '>_59_DOM',
                      }

                      saveSales((prev) => ({
                        ...prev,
                        on_deck: {
                          ...prev?.on_deck,
                          selected: position[e.realIndex],
                        },
                      }))
                    }}
                    onSwiper={(swiper) => {
                      try {
                        // const index = 0
                        // if (index > 0) {
                        //   swiper.slideTo(index === -1 ? 0 : index, 0)
                        //   return
                        // }
                        // if (index === 0 || index === -1) {
                        //   swiper.slideTo(1, 900)
                        // }
                        // setTimeout(() => {
                        //   if (index === 0 || index === -1) {
                        //     swiper.slideTo(0, 900)
                        //   }
                        //   swiper.slideTo(index === -1 ? 0 : index, 0)
                        //   setSwiper(swiper)
                        // }, 175)

                        if (sales?.on_deck?.selected === '<_6_DOM') {
                          swiper.slideTo(1, 0)
                        } else if (sales?.on_deck?.selected === '>_59_DOM') {
                          swiper.slideTo(2, 0)
                        }
                        // swiper.slideTo(1)
                        if (swiper) {
                          setDeckSwiper(swiper)
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    }}
                  > */}
                    {/* <SwiperSlide> */}
                    {/* <div className='min-h-[500px]'> */}
                    {sales?.on_deck?.data?.open_house_listings?.length &&
                    (sales?.on_deck?.selected || 'open_house') ===
                      'open_house' ? (
                      <SalesList
                        list={sales?.on_deck?.data?.open_house_listings}
                        type='on_deck'
                      />
                    ) : (
                      <>
                        {(sales?.on_deck?.selected || 'open_house') ===
                        'open_house' ? (
                          <div className='min-h-[350px]'>
                            <div>
                              <p className='font-bold text-center text-[18px]'>
                                Open House Cleared!
                              </p>
                              <img
                                src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${1}.png`}
                              />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                    {/* </div> */}
                    {/* </SwiperSlide> */}
                    {/* <SwiperSlide> */}
                    {/* <div className='min-h-[500px]'> */}
                    {sales?.on_deck?.data?.just_listings?.length &&
                    (sales?.on_deck?.selected || '<_6_DOM') === '<_6_DOM' ? (
                      <SalesList
                        list={sales?.on_deck?.data?.just_listings}
                        type='on_deck'
                      />
                    ) : (
                      <>
                        {(sales?.on_deck?.selected || '<_6_DOM') ===
                        '<_6_DOM' ? (
                          <div className='min-h-[350px]'>
                            <div>
                              <p className='font-bold text-center text-[18px]'>
                                Just Listed Cleared!
                              </p>
                              <img
                                src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${1}.png`}
                              />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}{' '}
                    {/* </div> */}
                    {/* </SwiperSlide> */}
                    {/* <SwiperSlide> */}
                    {/* <div className='min-h-[500px]'> */}
                    {sales?.on_deck?.data?.old_listings?.length &&
                    (sales?.on_deck?.selected || '>_59_DOM') === '>_59_DOM' ? (
                      <SalesList
                        list={sales?.on_deck?.data?.old_listings}
                        type='on_deck'
                      />
                    ) : (
                      <>
                        {(sales?.on_deck?.selected || '>_59_DOM') ===
                        '>_59_DOM' ? (
                          <div className='min-h-[350px]'>
                            <div>
                              <p className='font-bold text-center text-[18px]'>
                                Old Listings Cleared!
                              </p>
                              <img
                                src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${1}.png`}
                              />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                    {/* </div> */}
                    {/* </SwiperSlide> */}
                    {/* </Swiper> */}
                  </div>
                </div>
              )
            ) : (
              <div>
                <p className='font-bold text-center text-[18px]'>
                  Looks like you cleared the deck!
                </p>
                <img
                  src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${5}.png`}
                />
              </div>
            )}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default WhatNext
