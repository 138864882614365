import { useState } from 'react'
import { AiOutlineApple, AiOutlineCopy, AiOutlineGoogle } from 'react-icons/ai'
import { MdAdsClick } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useReceiver } from '../../../contexts/ReceiverContext'
import { useProfile } from '../../../contexts/UserContext'
import {
  date_today,
  format_currency,
  format_dollar_currency,
  getDateString,
  get_address_str,
  get_directions_url,
  open_url_tab,
  set_storage,
} from '../../../helpers'
import CopyText from '../../helpers/CopyText'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import SendResults from './SendResults'

const BuyerItinerary = ({
  types = [162],
  setShowModal,
  is_in_tour = false,
}) => {
  const { saveListingTours, organize_tours, get_homes, open_tour } =
    useListingTours()
  const [copied, setCopied] = useState(false)
  const [animationCopied, setAnimationCopied] = useState(false)
  const location = useLocation()
  const first_tour = location?.pathname.includes('card')
    ? organize_tours(types)?.filter((item) => date_today(item.event_date))?.[0]
    : organize_tours(types)?.[0]
  const homes = get_homes(first_tour?.event_id)
  const { profile } = useProfile()
  // is_in_tour = false
  const show_small =
    first_tour?.created_by_cont_id === profile.CONT_ID && !is_in_tour

  const [buyerModal, setBuyerModal] = useState(false)
  const [buyerModalType, setBuyerModaltype] = useState('')
  const modals = {
    send_results: (
      <SendResults setShowModal={setShowModal} setBuyerModal={setBuyerModal} />
    ),
  }
  return (
    <div className='flex flex-col justify-between'>
      <div className='h-[435px] overflow-y-scroll'>
        <div className='flex items-baseline justify-between'>
          <p
            className={`${uiStyles.text_black_title} overflow-scroll whitespace-nowrap scrollbar-hide mr-[10px]`}
          >
            {first_tour?.event_name}
          </p>
          <p
            className={`${uiStyles.text_gray} min-w-[50px] overflow-scroll whitespace-nowrap scrollbar-hide`}
          >
            {getDateString({
              date: first_tour?.event_date,
              format: 'mmm dd, yyyy hh:mi am',
            })}
          </p>
        </div>
        <div className={uiStyles.line_3px}></div>
        <div className='flex'>
          <p className='mr-[10px] font-bold'>Full Trip: </p>
          <div className='flex items-center gap-[10px]'>
            <div
              className={`cursor-pointer`}
              onClick={() => {
                return open_url_tab(
                  get_directions_url(
                    homes?.map((item) => ({
                      lat: item?.details?.lat,
                      lng: item?.details?.lng,
                    })),
                    'google',
                    true
                  )
                )
              }}
            >
              <AiOutlineGoogle className='w-[20px] h-[20px]' />
            </div>
            <div
              className={`cursor-pointer`}
              onClick={() => {
                return open_url_tab(
                  get_directions_url(
                    homes?.map((item) => ({
                      lat: item?.details?.lat,
                      lng: item?.details?.lng,
                    })),
                    'apple',
                    true
                  )
                )
              }}
            >
              <AiOutlineApple className='w-[20px] h-[20px]' />
            </div>
          </div>
        </div>
        {homes?.map((item, index) => {
          return (
            <div key={item?.mls_num + item?.evt_id} className='mt-[10px]'>
              <div className='flex gap-[10px]'>
                <div className={'relative'}>
                  <img
                    onClick={() => {
                      if (!is_in_tour) {
                        return
                      }
                      saveListingTours((prev) => {
                        const new_tour_state = { ...prev?.tour_state }
                        new_tour_state.current_page = 5
                        new_tour_state.product_page = index + 1
                        new_tour_state.listing_page = index + 1
                        set_storage('listing_tours_state', new_tour_state)

                        return {
                          ...prev,
                          tour_state: new_tour_state,
                        }
                      })
                      window.scrollTo(0, 0)
                      setShowModal(false)
                    }}
                    src={item?.details?.home_photo1}
                    className='w-[75px] h-[75px] object-cover object-center rounded-lg shadow-md cursor-pointer'
                  />
                  {is_in_tour ? (
                    <MdAdsClick className='absolute top-0 right-0 w-[15px] h-[15px]' />
                  ) : (
                    ''
                  )}{' '}
                </div>
                <div className='text-sm '>
                  <p className='whitespace-nowrap overflow-x-scroll max-w-[200px] scrollbar-hide'>
                    {' '}
                    {item?.details?.prop_add}
                  </p>
                  <div className='flex'>
                    <p className='mr-[10px]'>Directions: </p>
                    <div className='flex items-center gap-[10px]'>
                      <div
                        className={`cursor-pointer`}
                        onClick={() => {
                          return open_url_tab(
                            get_directions_url(
                              [
                                {
                                  lat: item?.details?.lat,
                                  lng: item?.details?.lng,
                                },
                              ],
                              'google',
                              true
                            )
                          )
                        }}
                      >
                        <AiOutlineGoogle className='w-[20px] h-[20px]' />
                      </div>
                      <div
                        className={`cursor-pointer`}
                        onClick={() => {
                          return open_url_tab(
                            get_directions_url(
                              [
                                {
                                  lat: item?.details?.lat,
                                  lng: item?.details?.lng,
                                },
                              ],
                              'apple',
                              true
                            )
                          )
                        }}
                      >
                        <AiOutlineApple className='w-[20px] h-[20px]' />
                      </div>
                      <CopyText text={item?.details?.prop_add} />
                    </div>
                  </div>

                  <p>
                    List Price:{' '}
                    {format_dollar_currency(item?.details?.list_price)}
                  </p>
                  <div className='flex gap-[10px]'>
                    <p>Beds: {item?.details?.beds}</p>
                    <p>Baths: {item?.details?.baths}</p>
                    <p className='whitespace-nowrap overflow-scroll max-w-[100px] scrollbar-hide'>
                      SqFt: {format_currency(item?.details?.sqft)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className='flex justify-end gap-[10px] mt-[10px]'>
        <button
          className={uiStyles?.[show_small ? 'white_btn_small' : 'white_btn']}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Close
        </button>
        {/* {first_tour?.created_by_cont_id === profile.CONT_ID ? (
          <button
            className={
              uiStyles?.[show_small ? 'hover_btn_greyed_out' : 'hover_btn']
            }
            onClick={() => {
              if (is_in_tour) {
                setBuyerModal(true)
                setBuyerModaltype('send_results')
              }
            }}
          >
            Text Results
          </button>
        ) : (
          ''
        )} */}
        {!is_in_tour ? (
          <button
            className={uiStyles?.[show_small ? 'hover_btn_small' : 'hover_btn']}
            onClick={() => {
              return open_tour(first_tour)
            }}
          >
            Open Tour
          </button>
        ) : (
          ''
        )}
      </div>
      {buyerModal ? (
        <Modal
          modalName={'tour_complete'}
          setShowModal={() => {
            setBuyerModal(false)
            setTimeout(() => {
              setShowModal(true)
            }, [0])
          }}
          showModal={buyerModal}
          showClose={true}
          height={'h-[300px]'}
        >
          {modals[buyerModalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default BuyerItinerary
