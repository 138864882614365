import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDocuments } from '../../../contexts/DocumentsContext'
import { useTransactions } from '../../../contexts/TransactionsContext'
import { format_currency, format_input } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import LabelInput from '../../form/LabelInput'
import uiStyles from '../../ui/uiStyles'

const CommitmentUpd = ({ setShowModal, currDoc, setCurrDoc }) => {
  const [data, setData] = useState({})
  const [origData, setOrigData] = useState({})
  const { axiomFetch } = useAxiomFetch()
  const { fileid } = useParams()
  const { getTransactionByID, saveTransactions, transactions } =
    useTransactions()
  const { documents, saveDocuments } = useDocuments()
  const currentTransaction = getTransactionByID(fileid)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      proposed_insured_buyer:
        currDoc?.new_vesting || currentTransaction?.new_vesting,
      proposed_insured_lender:
        currentTransaction?.first_lender_asgn_clause || '',
      loan_amount: format_currency(currentTransaction?.loan_cmt_cov_amt),
    }))
    setOrigData((prev) => ({
      ...prev,
      proposed_insured_buyer:
        currDoc?.new_vesting || currentTransaction?.new_vesting,
      proposed_insured_lender:
        currentTransaction?.first_lender_asgn_clause || '',
      loan_amount: format_currency(currentTransaction?.loan_cmt_cov_amt),
    }))
  }, [currentTransaction.file_id])
  return (
    <div>
      <h1 className={`${uiStyles.text_black_title} mb-[10px]`}>
        Update Commitment
      </h1>
      <LabelInput
        placeholder='Proposed Insured Buyer'
        show_delete_all={true}
        delete_all_position={'top-[-5px] right-[-5px]'}
        name={'proposed_insured_buyer'}
        value={data?.proposed_insured_buyer}
        handleChange={(e) => {
          console.log(e)
          setData((prev) => ({
            ...prev,
            proposed_insured_buyer: e.target.value,
          }))
        }}
      />
      <LabelInput
        placeholder='Proposed Insured Lender'
        show_delete_all={true}
        delete_all_position={'top-[-5px] right-[-5px]'}
        name={'proposed_insured_lender'}
        value={data?.proposed_insured_lender}
        handleChange={(e) => {
          console.log(e)
          setData((prev) => ({
            ...prev,
            proposed_insured_lender: e.target.value,
          }))
        }}
      />
      <LabelInput
        placeholder='Loan Amount'
        show_dollar={true}
        show_delete_all={true}
        delete_all_position={'top-[-5px] right-[-5px]'}
        name={'loan_amount'}
        value={data?.loan_amount}
        handleChange={(e) => {
          const { name, value } = format_input({
            e,
            type: '$',
            decimal: 2,
            original: data?.loan_amount || null,
          })
          setData((prev) => ({
            ...prev,
            [name]: value,
          }))
        }}
      />
      {loading ? (
        <div className={uiStyles.loading_msg}>updating commitment...</div>
      ) : (
        <div className='flex gap-[5px] justify-end'>
          <button
            className={uiStyles.hover_btn}
            onClick={async () => {
              setLoading(true)
              setCurrDoc((prev) => ({
                ...prev,
                new_vesting: data?.proposed_insured_buyer,
              }))
              saveDocuments((prev) => [
                ...prev.filter(
                  (item) => item?.FILE_DOCUMENT_ID !== currDoc.FILE_DOCUMENT_ID
                ),
                { ...currDoc, new_vesting: data?.proposed_insured_buyer },
              ])
              saveTransactions((prev) => [
                ...prev?.filter(
                  (item) => item.file_id !== currentTransaction?.file_id
                ),
                {
                  ...currentTransaction,
                  first_lender_asgn_clause: data?.proposed_insured_lender,
                  loan_cmt_cov_amt: data?.loan_amount,
                },
              ])
              await axiomFetch(
                `/escrow/transaction/${fileid}/documents/${currDoc?.FILE_DOCUMENT_ID}/commitment`,
                'POST',
                { ...data, original_data: origData }
              )
              setLoading(false)
              setShowModal(false)
            }}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  )
}

export default CommitmentUpd
