import React from 'react'
import uiStyles from '../ui/uiStyles'

const CheckToggle = ({
  toggle_left_label,
  toggle_right_label,
  toggle_on,
  name,
  value,
  handle_change,
  label = '',
  ...restProps
}) => {
  const circle_toggle = (
    <div className='flex justify-center gap-[5px] items-center'>
      {toggle_left_label ? (
        <div className={`flex justify-end ${'text-[#344054]'}`}>
          {toggle_left_label}
        </div>
      ) : (
        ''
      )}
      <div
        className={`flex transition-all delay-100 toggle h-[20px] rounded-2xl bg-gradient-to-b ${
          toggle_on
            ? 'from-accent-150 to-accent-250'
            : 'from-gray-200 to-gray-200'
        } px-[10px] py-[2px]`}
      >
        <span
          className={`bg-white cursor-pointer handle transition-all delay-50 w-[16px] h-[16px] rounded-full ${
            toggle_on
              ? 'transform translate-x-[8px]'
              : 'transform translate-x-[-8px]'
          }`}
        ></span>
      </div>
      {toggle_right_label ? (
        <div
          className={`flex min-w-[120px] justify-start ${
            toggle_on ? 'text-gray-500' : `font-bold ${'text-[#344054]'}`
          }`}
        >
          {toggle_right_label}
        </div>
      ) : (
        ''
      )}
    </div>
  )
  return (
    <div className='flex items-center'>
      <label className='capitalize relative'>
        <input
          {...restProps}
          tabIndex={-1}
          className='absolute left-0 right-0 top-0 h-full opacity-0 cursor-pointer'
          type='checkbox'
          name={name}
          value={value}
          checked={toggle_on ? true : false}
          onChange={handle_change}
        />
        {circle_toggle}
      </label>
      <span className={`${uiStyles.text_gray} ml-1 font-medium`}>{label}</span>
    </div>
  )
}

export default CheckToggle
