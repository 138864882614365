import React from 'react'
import { useAiDaily } from '../../contexts/AiDailyContext'
import uiStyles from '../ui/uiStyles'

const TextEdit = () => {
  const {
    aiDaily,
    upd_daily_signup,
    saveAiDaily,
    gen_daily_resp,
    save_resp,
    save_post,
    save_content,
    save_slide,
  } = useAiDaily()
  return (
    <div>
      {' '}
      <textarea
        className={`${uiStyles.text_area} min-h-[400px]`}
        onChange={(e) => {
          save_content({ resp_txt: e.target.value })
        }}
        value={aiDaily?.post?.content?.resp_txt}
      ></textarea>
    </div>
  )
}

export default TextEdit
