const uiStyles = {
    content_wrap: 'flex flex-col gap-3 text-black m-5',
    hover_btn: "cursor-pointer font-semibold text-sm text-white bg-gradient-to-b from-accent-100 to-accent-600 px-6 py-2.5 rounded-lg hover:from-accent-150 hover:to-accent-250",
    hover_btn_small: "cursor-pointer font-semibold text-[12px] text-white bg-gradient-to-b from-accent-100 to-accent-600 px-[10px] py-[5px] rounded-lg hover:from-accent-150 hover:to-accent-250",
    green_btn: "cursor-pointer font-semibold text-sm text-white bg-gradient-to-b from-accent-150 to-accent-250 px-6 py-2.5 rounded-lg",
    red_btn: "cursor-pointer font-semibold text-sm text-white bg-gradient-to-b from-[#fa394d] to-[#f58490] px-6 py-2.5 rounded-lg",
    red_btn_small: "cursor-pointer font-semibold text-[12px] text-white bg-gradient-to-b from-[#fa394d] to-[#f58490] px-[10px] py-[5px] rounded-lg",
    white_btn: "cursor-pointer font-semibold text-sm text-[#344054] bg-white border border-gray-300 rounded-lg px-6 py-2.5 rounded-lg",
    white_btn_small: "cursor-pointer font-semibold text-[12px] text-[#344054] border border-gray-300 bg-white px-[10px] py-[5px] rounded-lg",
    hover_btn_greyed_out: "cursor-not-allowed font-semibold text-sm text-white bg-gradient-to-b from-gray-100 to-gray-400 px-6 py-2.5 rounded-lg hover:from-gray-100 hover:to-gray-400",
    hover_btn_small_greyed_out: "cursor-not-allowed font-semibold text-[12px] text-white bg-gradient-to-b from-gray-100 to-gray-400 px-[10px] py-[5px] rounded-lg hover:from-gray-100 hover:to-gray-400",
    text_area: "text-primary bg-white border border-gray-300 rounded-md py-2 pl-3 pr-6 w-full font-base text-base field",
    input: "text-primary bg-white border border-gray-300 rounded-md py-2 pl-2 pr-2 min-w-[180px] w-full font-base text-base",
    input_num: "text-primary bg-white border border-gray-300 rounded-md py-2 pl-2 pr-2 min-w-[50px] max-w-[50px] w-full font-base text-base",
    input_small: "text-primary bg-white border border-gray-300 rounded-md w-full font-base text-base px-2",
    input_med: "text-primary bg-white border border-gray-300 rounded-md py-2 pl-2 w-full font-base text-base px-2",
    select: "text-primary bg-white border border-gray-300 rounded-md py-3 px-3 w-full font-base text-base",
    label: "text-sm text-primary",
    success_msg: "min-w-[250px] transition-all duration-500 opacity-1 border-l-4 border-accent-150 shadow-lg text-black p-4 text-center text-md",
    loading_msg: "min-w-[250px] transition-all duration-500 opacity-1 border-l-4 border-accent-600 shadow-lg text-black p-4 text-center text-md",
    failure_msg: "min-w-[250px] transition-all duration-500 opacity-1 border-l-4 border-red-500 shadow-lg text-black p-4 text-center text-md",
    card_blue_gradient: '[background:_linear-gradient(147deg,_#73DFE7_50.49%,_#0063F7_100%)] [border-radius:_20px]',
    card_gray: 'bg-gray-100 rounded-lg shadow-xl p-1 mt-[10px]',
    card_gray_gradient: 'bg-gradient-to-r from-gray-300 to-gray-100 rounded-lg shadow-md p-1',
    card_black_gradient: 'bg-gradient-to-r from-black to-gray-600 rounded-lg shadow-md p-1',
    text_gray: 'text-thirdary text-[14px]',
    text_black: 'text-primary text-14px]',
    text_black_subtitle: 'text-primary text-[18px] font-semibold',
    text_black_title: 'text-primary text-[24px] text-[#101828] font-semibold',
    text_white: 'text-primary text-14px text-[#fff]',
    text_white_subtitle: 'text-primary text-[20px] text-[#fff] font-semibold',
    text_white_title: 'text-primary text-[24px] text-[#fff] font-semibold',
    line: 'w-full border-t-[1px] border-gray-300',
    line_3px: 'w-full border-t-[3px] border-gray-300',
    line_light: 'w-full border-t-[1px] border-[#BCC1C5]',
    profile_img: 'w-[64px] rounded-full border-3 border-[red]',
    rec_img: 'w-[180px] h-[75px]',
    green_grd_text: 'font-semibold text-[14px] text-transparent bg-clip-text [-webkit-background-clip:_text] [-webkit-text-fill-color:_transparent] bg-gradient-to-b from-[#00CFDE] to-[#05A660]',
    blue_grd_text: 'font-semibold text-[14px] text-transparent bg-clip-text [-webkit-background-clip:_text] [-webkit-text-fill-color:_transparent] bg-gradient-to-b from-[#73DFE7] to-[#0063F7]',
    blue_grd_text_darker: 'font-semibold text-[14px] text-transparent bg-clip-text [-webkit-background-clip:_text] [-webkit-text-fill-color:_transparent] bg-gradient-to-b from-[#5B8DEF] to-[#0063F7]',
    blue_grd_subtitle: 'font-semibold text-[18px] text-transparent bg-clip-text [-webkit-background-clip:_text] [-webkit-text-fill-color:_transparent] bg-gradient-to-b from-[#73DFE7] to-[#0063F7]',
    blue_grd_subtitle_darker: 'font-semibold text-[18px] text-transparent bg-clip-text [-webkit-background-clip:_text] [-webkit-text-fill-color:_transparent] bg-gradient-to-b from-[#5B8DEF] to-[#0063F7]',
}

export default uiStyles