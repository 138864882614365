import React from 'react'
import { useAiDaily } from '../../contexts/AiDailyContext'
import { set_storage, validate_number } from '../../helpers'
import uiStyles from '../ui/uiStyles'
import { MdAdsClick } from 'react-icons/md'
import ImageEdit from './ImageEdit'

const VideoEdit = ({ setModalType, setShowModal }) => {
  const {
    aiDaily,
    upd_daily_signup,
    saveAiDaily,
    gen_daily_resp,
    save_resp,
    save_slide,
    save_post,
    save_content,
    upd_vid_resp,
    get_daily_prompt_by_id,
  } = useAiDaily()

  const slides = Array.isArray(aiDaily?.post?.slides)
    ? aiDaily?.post?.slides
    : []

  console.log('slides', slides, aiDaily)
  return (
    <div>
      <div className='flex flex-col mb-4'>
        <div>Video</div>
        <video
          key={aiDaily?.post?.content?.resp_vid}
          id={aiDaily?.post?.content?.resp_vid}
          className=''
          controls
        >
          <source type='video/mp4' src={aiDaily?.post?.content?.resp_vid} />
        </video>
      </div>
      <div className='flex justify-end'>
        {aiDaily?.post?.prompt_loading ? (
          <div>
            Creating new daily video. This make take a bit.{' '}
            {aiDaily?.post?.timer}
          </div>
        ) : (
          <button
            className={uiStyles.hover_btn_small}
            onClick={async () => {
              save_post({ prompt_loading: true, timer: 120 })
              await upd_vid_resp()
              save_post({ pending_resp_id: aiDaily?.post?.content?.resp_id })
            }}
          >
            regenerate video
          </button>
        )}
      </div>
      <div className='flex flex-col gap-3'>
        {slides?.map((item, index) => {
          return (
            <div className='flex flex-col gap-2'>
              <div>slide {item?.slide_order}</div>
              <div className='relative'>
                <div className='absolute z-20 right-0 mt-[-5px] mr-[-5px]'>
                  {<MdAdsClick />}
                </div>
                <img src={item?.image} />
                <div className='flex justify-end mt-2 gap-2'>
                  {aiDaily?.post?.content?.mls_id ? (
                    <button
                      onClick={() => {
                        save_post({ slide: item })
                        setModalType('mls_gallery')
                        setShowModal(true)
                      }}
                      className={uiStyles.hover_btn_small}
                    >
                      MLS Gallery
                    </button>
                  ) : (
                    ''
                  )}
                  <button
                    onClick={() => {
                      save_post({ slide: item })
                      setModalType('gallery')
                      setShowModal(true)
                    }}
                    className={uiStyles.hover_btn_small}
                  >
                    AI Gallery
                  </button>
                </div>
                {item?.image ? (
                  <textarea
                    className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[transparent] text-white w-full text-center outline-none resize-none`}
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                      textShadow: '3px 3px 3px rgba(0, 0, 0, 1)',
                    }}
                    onChange={(e) => {
                      save_slide({
                        ...item,
                        caption: e.target.value,
                      })
                    }}
                    value={item?.caption || ''}
                  ></textarea>
                ) : (
                  ''
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default VideoEdit
