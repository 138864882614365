import React from 'react'
import { useAiDaily } from '../../contexts/AiDailyContext'
import { MdAdsClick } from 'react-icons/md'

const AiDailyMlsGallery = ({ setShowModal, prevModalType }) => {
  const {
    aiDaily,
    upd_daily_signup,
    saveAiDaily,
    gen_daily_resp,
    save_resp,
    save_content,
    save_post,
    save_slide,
  } = useAiDaily()

  return (
    <div className='flex flex-col gap-1'>
      {aiDaily?.post?.mls_data?.map((item) => {
        return (
          <div key={item?.default_seq_id} className='relative'>
            <div className='absolute z-20 right-0 mt-[-5px] mr-[-5px]'>
              {<MdAdsClick color={'gray'} />}
            </div>
            <img
              src={item?.media_url}
              onClick={() => {
                if (prevModalType === 'video') {
                  save_slide({
                    ...(aiDaily?.post?.slide || {}),
                    image: item?.media_url,
                  })
                  setShowModal(false)
                } else if (prevModalType === 'image') {
                  save_content({ resp_img: item?.media_url })
                  setShowModal(false)
                }
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default AiDailyMlsGallery
