import React from 'react'
import Usage from '../usage/Usage'

export default function Wrapper({
  children,
  className,
  show = true,
  ...restProps
}) {
  return (
    <>
      {show ? (
        <div className='flex flex-col h-full'>
          <div
            {...restProps}
            id='wrapper'
            className={`${
              className ? className : ''
            } flex flex-col bg-white shadow-md overflow-scroll scrollbar-hide rounded-md relative w-full h-full min-h-[80vh]`}
          >
            {children}
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  )
}
