import { useForm } from 'react-hook-form'

import FormButton from '../onBoarding/ButtonForm'
import { USERTYPE } from '../../constants'
import Title from '../onBoarding/Title'
import Branding from '../../assets/images/favLarge.png'
import Hand from '../../assets/images/hand2.png'
import Button from '../onBoarding/Button'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Navigate } from 'react-router-dom'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import Loading from '../Loading'
import { DBC } from '../../pages'
import jwtDecode from 'jwt-decode'
import { useAuth0 } from '@auth0/auth0-react'
import Blue from '../layout/Blue'
import Driggs from '../../assets/images/driggsSmall.png'
import Usage from '../usage/Usage'
import Profile from '../../pages/User/settings/Profile'
import Settings from './Settings'
import Header2 from '../Header2'
import Header1 from '../Header1'
import TitleIcon from '../ui/TitleIcon'
import { useProfile } from '../../contexts/UserContext'
import { useClaims } from '../../contexts/ClaimsContext'
import { get_storage, set_storage } from '../../helpers'

const plansData = [
  {
    title: 'FREE FOR LIFE',
    name: USERTYPE.FREE,
    description: 'For individuals that need the basics. ',
    color: 'bg-gradient-to-r from-orange-500 to-orange-300',
    grayed_color: 'bg-gradient-to-r from-gray-400 to-gray-500',
    grayed_out: true,
  },
  {
    title: 'FREE DURING BETA',
    name: USERTYPE.PROFESSIONAL,
    description:
      'For individuals that need advanced content creation and sharing.',
    color: 'bg-gradient-to-r from-green-500 to-green-300',
    only_selection: true,
    additional_text: [
      'Engage: 8 New Articles p/mth',
      'AXIOM AI: 60 pieces p/mth',
      'SMS: 600 texts messages p/mth',
      'Email: 8 New Articles p/mth',
      'Social Shares: Unlimited',
    ],
  },
  {
    title: 'THE WORKS!',
    name: USERTYPE.ENTERPRISE,
    description:
      'For individuals and/or teams that need advanced content creation, sharing, leads, and transactional management.',
    color: 'bg-gradient-to-r from-blue-500 to-blue-300',
    grayed_color: 'bg-gradient-to-r from-gray-400 to-gray-500',
    grayed_out: true,
  },
]
export default function Onboarding() {
  const { axiomFetch } = useAxiomFetch()
  const { profile, saveProfile } = useProfile()
  const { getAccessTokenSilently } = useAuth0()
  const [receiverCheck, setReceiverCheck] = useState({
    isLoading: true,
  })
  const { saveClaims } = useClaims()
  const [isSubscribed, setIsSubscribed] = useState('')
  const [settings, setSettings] = useState('none')
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm({
    defaultValues: {
      subscription: plansData[1].name,
    },
  })

  const onSubmit = (data) => {
    setIsSubscribed(!!data.subscription)
    setTimeout(() => {
      setSettings('initial')
    }, [2000])
  }

  const onCompleteOnBoarding = async () => {
    // navigate('/user', { replace: true })
  }

  useEffect(() => {
    const controller = new AbortController()
    const controller_2 = new AbortController()
    setReceiverCheck((prev) => ({ ...prev, isLoading: true }))
    const save_profile = async () => {
      setReceiverCheck((prev) => ({ ...prev, isLoading: true }))
      const data = await axiomFetch(
        '/client/profile',
        'GET',
        {},
        {},
        false,
        controller_2.signal
      )
      console.log('data', data)
      if (data?.CONT_ID) {
        const save_admin = (data) => {
          if (data?.DEPARTMENT?.toLowerCase() === 'admin') {
            data.is_admin = window?.localStorage?.getItem('is_admin')
              ? window?.localStorage?.getItem('is_admin')
              : 'Y'
          } else {
            data.is_admin = 'N'
          }
          return data
        }
        saveProfile((prev) => ({
          ...prev,
          ...(get_storage('profile') || {}),
          ...save_admin(data?.[0]),
        }))
      }
      console.log(data?.[0])
      if (
        data?.[0]?.AXIOM_FIRST_LOGIN_DT &&
        data?.[0]?.AXIOM_FIRST_LOGIN_DT !== '2001-01-01T00:00:00.000Z'
      ) {
        return navigate('/user')
      }
    }
    const check_receiver = async () => {
      const token = await getAccessTokenSilently().catch((err) => null)
      if (!token) {
        // await save_profile()
        // setReceiverCheck((prev) => ({ ...prev, isLoading: true }))
        return
      }
      const claims = jwtDecode(token)
      console.log('onboarding', claims)

      saveClaims((prev) => {
        set_storage('claims', { ...prev, ...claims })
        return { ...prev, ...claims }
      })
      if (claims?.secure_signin) {
        return navigate('/user/my-transactions-lobby')
      }
      console.log('claims', claims)
      if (
        claims?.vbc_receiver?.VBC_INSTANCE_ID ||
        get_storage('vbc_receiver')?.VBC_INSTANCE_ID
        // || claims?.type_of_person_id === 2 ||
        // claims?.type_of_person_id === 3
      ) {
        console.log('hello')
        set_storage('vbc_receiver', claims?.vbc_receiver || '')
        setReceiverCheck(claims?.vbc_receiver || {})
      } else {
        console.log('hello')
        await save_profile()
        setReceiverCheck((prev) => ({ ...prev, isLoading: false }))
        return
      }
    }
    check_receiver()
    return () => {
      controller?.abort()
      controller_2?.abort()
    }
  }, [])

  if (
    receiverCheck?.isLoading ||
    (!profile?.CONT_ID && !receiverCheck?.VBC_INSTANCE_ID)
  ) {
    return <Loading />
  }

  if (Number(receiverCheck?.VBC_INSTANCE_ID) > 0) {
    // return <DBC receiver={receiverCheck} />
    return (
      <Navigate
        to={`/card`}
        state={{ id: receiverCheck?.VBC_INSTANCE_ID || 0 }}
      />
    )
  }

  if (
    get_storage('profile')?.AXIOM_FIRST_LOGIN_DT &&
    get_storage('profile')?.AXIOM_FIRST_LOGIN_DT !== '2001-01-01T00:00:00.000Z'
  ) {
    return <Loading />
  }

  const subscriptionsHTML = plansData.map((plan, index) => (
    <div key={index} className='subscription-item relative text-gray-800 '>
      {/* <input
        className='absolute left-0 top-0 right-0 bottom-0 w-full h-full opacity-0'
        type='radio'
        {...register('subscription')}
        value={plan.name}
      /> */}
      <div>
        <button
          // so it doesn't submit the form. need to update this in the future.
          type='button'
          className={`min-w-[130px] ${
            index && index !== '1' && 'mt-5'
          } mb-3 px-4 py-2 rounded-md text-white font-semibold ${
            plan.grayed_out ? plan.grayed_color : plan.color
          }`}
        >
          {plan.title}
        </button>
      </div>
      <div
        className={`flex justify-center flex-col items-center text-center shadow-lg p-3 rounded-md ${
          plan.only_selection ? 'bg-[#78E3B4]' : ''
        } `}
      >
        <h4 className='uppercase font-semibold  mb-1'>{plan.name}</h4>
        <p className='text-base leading-4'>{plan.description}</p>
        {plan?.additional_text?.length ? (
          <div className='flex flex-col items-center mt-[10px]'>
            {plan?.additional_text.map((text, index) => (
              <p
                key={index}
                className='text-[16px] leading-[18px] text-center font-semibold'
              >
                {text}
              </p>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  ))

  return (
    <>
      {settings === 'initial' ? (
        <>
          <div className='font-base text-sm text-gray-500 rounded-2xl max-w-[375px] mx-auto bg-user-wrapper h-[100vh] relative p-3'>
            <div className='p-[15px] h-full bg-white rounded-lg'>
              <Profile
                type={settings}
                setType={setSettings}
                setIsSubscribed={setIsSubscribed}
              />
            </div>
          </div>
        </>
      ) : settings === 'full' ? (
        <>
          <div className='font-base text-sm text-gray-500 rounded-2xl max-w-[375px]  mx-auto bg-user-wrapper h-[100vh] relative p-3'>
            <div className='p-[15px] h-full bg-white rounded-lg'>
              <Profile
                type={settings}
                setType={setSettings}
                setIsSubscribed={setIsSubscribed}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <Usage pageTab={'subscription'} />
          <div className=' text-white rounded-2xl max-w-[375px]  mx-auto bg-secondary h-[100vh] relative '>
            {!isSubscribed && (
              <div className='h-full'>
                <div className='absolute left-0 top-0 right-0 bottom-0 opacity-10 h-full'>
                  <img src={Branding} className='w-full  h-full' alt='' />
                </div>

                <div className='p-5 h-full'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='relative z-20 bg-white rounded-lg text-center py-5 px-3 mb-10'>
                      <Title color='text-primary' marginBottom={'mb-3'}>
                        AXIOM
                        <br />
                        Subscriptions
                      </Title>
                      <p className='text-gray-500 text-base mb-3'>
                        Select Your Subscription
                      </p>
                      {subscriptionsHTML}
                    </div>
                    <div className='text-center relative z-20'>
                      <FormButton type='submit'>Next</FormButton>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {isSubscribed && (
              <div className='h-full'>
                <Usage pageTab={'rock_on'} />
                <div className='absolute left-0 top-0 right-0 bottom-0 opacity-1 '>
                  <img src={Branding} className='w-full ' alt='' />
                </div>
                <div className='absolute left-0  top-0 h-full right-0 -bottom-10'>
                  <img
                    src={Hand}
                    className='w-full h-[750px] absolute bottom-0'
                    alt=''
                  />
                </div>
                <div className='relative flex flex-col justify-start items-center min-h-[calc(100vh)] pt-[100px]'>
                  <p className='mb-[5px] text-center text-[27px] font-semibold'>
                    You're all Set!
                  </p>
                  <p className='text-center text-[27px] font-semibold'>
                    Welcome To AXIOM
                  </p>
                  {/* <div className='mb-5'>
                    <Button clickHandler={onCompleteOnBoarding}>Next</Button>
                  </div> */}
                </div>
              </div>
            )}
            <img
              src={Driggs}
              alt='Driggs'
              className='max-w-[50px] max-h-[50px] absolute left-4 bottom-3'
            />
          </div>
        </>
      )}
    </>
  )
}
