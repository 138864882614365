import React, { useEffect, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
export default function ProgressBar({
  stringId,
  color1 = '#32C5FF',
  color2 = '#B620E0',
  barColor = '#f2f2f2',
  percentage = 50,
  strokeWidth = 10,
}) {
  const [count, setCount] = useState(0)
  const gradientTransform = `rotate(90)`

  useEffect(() => {
    let start = 0
    // first three numbers from props
    const end = parseInt(percentage)
    // if zero, return
    if (start === end) return

    // find duration per increment
    let totalMilSecDur = parseFloat(0.5)
    let incrementTime = (totalMilSecDur / end) * 1000

    // timer increments start counter
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1
      setCount(start)
      if (start === end) clearInterval(timer)
    }, incrementTime)
    return () => clearInterval(timer)
  }, [percentage])
  return (
    <>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id={stringId} gradientTransform={gradientTransform}>
            <stop offset='50%' stopColor={color1} />
            <stop offset='100%' stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgressbar
        strokeWidth={strokeWidth}
        value={count}
        styles={{
          path: { stroke: `url(#${stringId})`, height: '100%' },
          trail: {
            stroke: `${barColor}`,
          },
        }}
      />
    </>
  )
}
