import React from 'react'
import { useAiDaily } from '../../contexts/AiDailyContext'
import { set_storage } from '../../helpers'
import uiStyles from '../ui/uiStyles'

const ImageEdit = ({ setShowModal, setModalType, img_url }) => {
  const {
    aiDaily,
    upd_daily_signup,
    saveAiDaily,
    gen_daily_resp,
    save_resp,
    save_content,
    save_post,
    save_slide,
  } = useAiDaily()

  return (
    <div>
      <img src={img_url || aiDaily?.post?.content?.resp_img || ''} />
      <div className='flex justify-end mt-2 gap-2'>
        {aiDaily?.post?.content?.mls_id ? (
          <button
            onClick={() => {
              setModalType('mls_gallery')
              setShowModal(true)
            }}
            className={uiStyles.hover_btn_small}
          >
            MLS Gallery
          </button>
        ) : (
          ''
        )}
        <button
          onClick={() => {
            setModalType('gallery')
            setShowModal(true)
          }}
          className={uiStyles.hover_btn_small}
        >
          AI Gallery
        </button>
      </div>
    </div>
  )
}

export default ImageEdit
