import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ProgressBar from '../ui/ProgressBar'
import uiStyles from '../ui/uiStyles'
import { useProfile } from '../../contexts/UserContext'
import AiDailyFirst from './AiDailyFirst'
import { useAiDaily } from '../../contexts/AiDailyContext'
import { CircleCheckToggle } from '../form/CircleCheckToggle'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import CheckToggle from '../form/CheckToggle'
import {
  formatPhoneNumber,
  get_storage,
  set_storage,
  short_url,
  validate_number,
} from '../../helpers'
import LabelInput from '../form/LabelInput'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import { AiFillEdit, AiFillSave } from 'react-icons/ai'
import CopyText from '../helpers/CopyText'
import { MdOutlineSaveAlt, MdOutlineSaveAs } from 'react-icons/md'
import DownloadBtn from '../form/DownloadBtn'
import ImageEdit from './ImageEdit'
import VideoEdit from './VideoEdit'
import TextEdit from './TextEdit'
import Modal from '../Modal'
import Modal2 from '../Modal2'
import AiGallery from './AiGallery'
import AiDailyHist from './AiDailyHist'
import AiDailyMlsGallery from './AiDailyMlsGallery'

const AiDaily = () => {
  const { axiomFetch } = useAxiomFetch()
  const timer_ref = useRef(null)
  const { profile } = useProfile()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [showPrevModal, setShowPrevModal] = useState(false)
  const [prevModalType, setPrevModalType] = useState('')
  const {
    aiDaily,
    upd_daily_signup,
    saveAiDaily,
    gen_daily_resp,
    save_resp,
    save_post,
    save_content,
    save_slide,
  } = useAiDaily()
  const navigate = useNavigate()
  const params = useParams()
  console.log(params)
  console.log(aiDaily?.data?.ai_prompts_daily_resp)

  const modals = {
    image: (
      <ImageEdit
        setModalType={setModalType}
        setShowModal={setShowModal}
        prevModalType={prevModalType}
        setShowPrevModal={setShowPrevModal}
        setPrevModalType={setPrevModalType}
      />
    ),
    video: (
      <VideoEdit
        setModalType={setModalType}
        setShowModal={setShowModal}
        prevModalType={prevModalType}
        setShowPrevModal={setShowPrevModal}
        setPrevModalType={setPrevModalType}
      />
    ),
    text: (
      <TextEdit
        setModalType={setModalType}
        setShowModal={setShowModal}
        prevModalType={prevModalType}
        setShowPrevModal={setShowPrevModal}
        setPrevModalType={setPrevModalType}
      />
    ),
    gallery: (
      <AiGallery
        setModalType={setModalType}
        setShowModal={setShowModal}
        prevModalType={prevModalType}
        setShowPrevModal={setShowPrevModal}
        setPrevModalType={setPrevModalType}
      />
    ),
    mls_gallery: (
      <AiDailyMlsGallery
        setModalType={setModalType}
        setShowModal={setShowModal}
        prevModalType={prevModalType}
        setShowPrevModal={setShowPrevModal}
        setPrevModalType={setPrevModalType}
      />
    ),
    hist: (
      <AiDailyHist
        setModalType={setModalType}
        setShowModal={setShowModal}
        prevModalType={prevModalType}
        setShowPrevModal={setShowPrevModal}
        setPrevModalType={setPrevModalType}
      />
    ),
  }
  useEffect(() => {
    if (timer_ref?.current) {
      clearInterval(timer_ref?.current)
    }

    timer_ref.current = setInterval(async () => {
      if (aiDaily?.post?.timer > 0) {
        const resp_id = aiDaily?.post?.pending_resp_id
        const check_resp = await axiomFetch(
          `/marketing/content/ai/daily/check_resp?resp_id=${resp_id}`,
          'GET'
        )
        if (check_resp?.resp_vid) {
          save_resp(check_resp)
          save_post({ timer: 0, prompt_loading: false, pending_resp_id: null })
        } else {
          saveAiDaily((prev) => {
            const result = {
              ...prev,
              post: {
                ...prev?.post,
                timer: validate_number(prev?.post?.timer) - 1,
              },
            }
            set_storage('ai_daily', result)
            return result
          })
        }
      } else {
        save_post({ timer: 0, prompt_loading: false, pending_resp_id: null })
        clearInterval(timer_ref?.current)
      }
    }, 1000)
  }, [aiDaily?.post?.timer])
  useEffect(() => {
    const found_content =
      aiDaily?.data?.ai_prompts_daily_resp?.find(
        (item) => item?.resp_uuid?.toString() === params?.id
      ) || aiDaily?.data?.ai_prompts_daily_resp?.[0]

    save_post({
      email: profile?.CONT_EMAIL || '',
      cell_phone: profile?.CONT_CELL_NUM || '',
      email_loading: false,
      text_loading: false,
      content: found_content,
    })
  }, [aiDaily?.data?.ai_prompts_daily_resp?.[0], params?.id])

  useEffect(() => {
    const controller = new AbortController()

    const get_data = async () => {
      const data = await axiomFetch(
        `/marketing/content/ai/daily/slides?vid_id=${aiDaily?.post?.content?.ai_prompts_vid_id}`,
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      if (data?.name === 'AbortError') {
        return
      }
      console.log('slides', data, aiDaily?.post?.content)
      save_post({ slides: data })
    }
    get_data()
    return () => controller?.abort()
  }, [aiDaily?.post?.content?.ai_prompts_vid_id])

  useEffect(() => {
    const controller = new AbortController()

    const get_data = async () => {
      const data = await axiomFetch(
        `/marketing/content/ai/daily/mls?mls_id=${aiDaily?.post?.content?.mls_id}`,
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      if (data?.name === 'AbortError') {
        return
      }
      console.log('slides', data, aiDaily?.post?.content)
      save_post({ mls_data: data })
    }
    get_data()
    return () => controller?.abort()
  }, [aiDaily?.post?.content?.mls_id])

  useEffect(() => {
    localStorage.removeItem('redirect')
    save_post({ prompt_loading: false })
  }, [])

  const send_email = async () => {
    save_post({ email_loading: true })
    const html = `
<p>Hi ${profile?.CONT_NAME},</p>

<p>Here is your daily post.</p>
<ul>
<li><strong>Text:</strong> ${aiDaily?.post?.content?.resp_txt || ''}</li>
<li><strong>Image:</strong> ${
      aiDaily?.post?.content?.resp_img
        ? await short_url(aiDaily?.post?.content?.resp_img)
        : ''
    }</li>
<li><strong>Video:</strong> ${
      aiDaily?.post?.content?.resp_vid
        ? await short_url(aiDaily?.post?.content?.resp_vid)
        : ''
    }</li>
</ul>
`
    const body = {
      msg: {
        to: [aiDaily?.post?.email || '', profile?.REP_EMAIL || '']?.filter(
          (item) => item
        ),
        // to: ['andrewj@driggstitle.com'],
        from: 'youready@theaxiomapp.com',
        subject: `Today's Daily Post - ${profile?.CONT_NAME}`,
        html,
      },
    }
    await axiomFetch('/social/email/sendEmail', 'POST', body)
    save_post({ email_loading: false })
  }
  const send_text = async () => {
    save_post({ text_loading: true })
    await axiomFetch('/marketing/conversations/axiom/create', 'POST', {
      conversations: [
        {
          name:
            aiDaily?.post?.cell_phone?.replace(/\D/g, '')?.slice(0, 10) ===
            profile.CONT_CELL_NUM
              ? profile?.CONT_NAME ||
                aiDaily?.post?.cell_phone?.replace(/\D/g, '')?.slice(0, 10)
              : aiDaily?.post?.cell_phone?.replace(/\D/g, '')?.slice(0, 10),
          sched_dt: '',
          attributes: {
            campaign: 'ai_daily',
            start_index: 0,
          },
          participants: [
            {
              identity: profile?.CONT_ID?.toString(),
              type: 'user',
              order: 2,
              msgs: [
                {
                  msg: `Here is your daily post: \n text: ${
                    aiDaily?.post?.content?.resp_txt || ''
                  } \n image: ${
                    aiDaily?.post?.content?.resp_img
                      ? await short_url(aiDaily?.post?.content?.resp_img)
                      : ''
                  } \n video: ${
                    aiDaily?.post?.content?.resp_vid
                      ? await short_url(aiDaily?.post?.content?.resp_vid)
                      : ''
                  }`,
                },
              ],
            },
            {
              phone:
                aiDaily?.post?.cell_phone
                  ?.replace(/\D/g, '')
                  ?.slice(0, 10)
                  ?.toString() || '',
              type: 'receiver',
              order: 1,
            },
          ],
        },
      ],
    })
    save_post({ text_loading: false })
  }
  return aiDaily?.data?.ai_prompts_signup?.cont_id ? (
    <div className='h-full'>
      <div className='flex flex-col h-full justify-between'>
        <div>
          <div className='flex justify-between px-3'>
            <CheckToggle
              name={`send_daily_text`}
              toggle_left_label='send daily texts'
              toggle_right_label=''
              toggle_on={aiDaily?.data?.ai_prompts_signup.send_daily_text}
              value={aiDaily?.data?.ai_prompts_signup.send_daily_text}
              handle_change={async (e) => {
                const toggle_value = e.target.value == 1 ? 0 : 1
                upd_daily_signup({ send_daily_text: toggle_value })
                await axiomFetch('/marketing/content/ai/daily/signup', 'POST', {
                  send_daily_text: toggle_value,
                })
              }}
            />
            <button
              className={uiStyles.hover_btn_small}
              onClick={() => {
                return navigate('/user/ai/daily/questions')
              }}
            >
              Bio Questions
            </button>
          </div>
          <div className='flex flex-col items-center justify-center '>
            <h1 className={`mt-[20px] mb-[10px] ${uiStyles.text_black_title}`}>
              {aiDaily?.data?.ai_prompts_daily?.find(
                (item) =>
                  item?.prompt_id ===
                  aiDaily?.post?.content?.ai_prompts_daily_id
              )?.prompt_title || "Today's Daily Post"}
            </h1>

            <div className='flex flex-col gap-[5px]'>
              {aiDaily?.post?.content?.resp_txt ? (
                <div className='px-3 flex flex-col'>
                  <div className=''>{aiDaily?.post?.content?.resp_txt}</div>
                  <div className='flex justify-end cursor-pointer gap-4 items-center'>
                    <AiFillEdit
                      size={23}
                      onClick={() => {
                        setPrevModalType('text')
                        setShowPrevModal(true)
                      }}
                    />

                    <CopyText
                      text={aiDaily?.post?.content?.resp_txt || ''}
                      overlay={true}
                    />
                  </div>
                </div>
              ) : (
                <div className='px-3'>No Text</div>
              )}
              {aiDaily?.post?.content?.resp_img ? (
                <div className='px-3 flex flex-col'>
                  <img className='' src={aiDaily?.post?.content?.resp_img} />
                  <div className='flex justify-end cursor-pointer gap-4 items-center'>
                    <AiFillEdit
                      size={23}
                      onClick={() => {
                        setPrevModalType('image')
                        setShowPrevModal(true)
                      }}
                    />
                    <DownloadBtn url={aiDaily?.post?.content?.resp_img} />
                    <CopyText
                      text={aiDaily?.post?.content?.resp_img || ''}
                      overlay={true}
                    />
                  </div>
                </div>
              ) : (
                <div className='px-3'>No Image</div>
              )}
              {aiDaily?.post?.content?.resp_vid ? (
                <div className='px-3 flex flex-col'>
                  <video
                    key={aiDaily?.post?.content?.resp_vid}
                    id={aiDaily?.post?.content?.resp_vid}
                    className=''
                    controls
                  >
                    <source
                      type='video/mp4'
                      src={aiDaily?.post?.content?.resp_vid}
                    />
                  </video>
                  <div className='flex justify-end cursor-pointer gap-4 items-center'>
                    <AiFillEdit
                      size={23}
                      onClick={() => {
                        setPrevModalType('video')
                        setShowPrevModal(true)
                      }}
                    />
                    <DownloadBtn url={aiDaily?.post?.content?.resp_vid} />
                    <CopyText
                      text={aiDaily?.post?.content?.resp_vid || ''}
                      overlay={true}
                    />
                  </div>
                </div>
              ) : (
                <div className='px-3'>No Video</div>
              )}
            </div>
          </div>
        </div>
        <div className='px-3 w-full'>
          <div className='flex w-full justify-between gap-[10px] items-center mt-[10px]'>
            <LabelInput
              name='email'
              width={'w-[240px]'}
              value={aiDaily?.post?.email}
              show_delete_all={true}
              delete_all_position='top-[0px] right-[0px]'
              handleChange={(e) => {
                save_post({ email: e.target.value })
              }}
            />
            {aiDaily?.post?.email_loading ? (
              <SmallLoadingRel />
            ) : (
              <button className={uiStyles.hover_btn_small} onClick={send_email}>
                Email Me
              </button>
            )}
          </div>
          <div className='flex w-full justify-between gap-[10px] items-center mt-[10px]'>
            <LabelInput
              name='cell_phone'
              width={'w-[240px]'}
              value={formatPhoneNumber(aiDaily?.post?.cell_phone)}
              show_delete_all={true}
              delete_all_position='top-[0px] right-[0px]'
              handleChange={(e) => {
                save_post({ cell_phone: e.target.value })
              }}
            />
            {aiDaily?.post?.text_loading ? (
              <SmallLoadingRel />
            ) : (
              <button className={uiStyles.hover_btn_small} onClick={send_text}>
                Text Me
              </button>
            )}
          </div>
          <div className='flex justify-end pt-[10px] mb-[10px] gap-3'>
            {aiDaily?.post?.prompt_loading ? (
              <div>
                Creating new daily post. This make take a bit.{' '}
                {aiDaily?.post?.timer}
              </div>
            ) : (
              <>
                <button
                  className={uiStyles.hover_btn_small}
                  onClick={async () => {
                    setModalType('hist')
                    setShowModal(true)
                  }}
                >
                  Post History
                </button>
                <button
                  className={uiStyles.hover_btn_small}
                  onClick={async () => {
                    save_post({ prompt_loading: true, timer: 120 })
                    const resp = await gen_daily_resp()
                    save_post({ pending_resp_id: resp?.resp_id })
                  }}
                >
                  Regenerate Prompt
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {showPrevModal ? (
        <Modal
          modalName={prevModalType}
          showModal={showPrevModal}
          setShowModal={setShowPrevModal}
          showClose={true}
          noMax={true}
        >
          {modals[prevModalType]}
        </Modal>
      ) : (
        ''
      )}
      {showModal ? (
        <Modal
          modalName={modalType}
          showModal={showModal}
          setShowModal={setShowModal}
          showClose={true}
          noMax={true}
        >
          {modals[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  ) : (
    <AiDailyFirst />
  )
}

export default AiDaily
