import React, { useCallback, useEffect, useRef, useState } from 'react'
import Wrapper from '../../layout/Wrapper'

import SubNav from '../../SubNav'
import ProgressBar from '../../ui/ProgressBar'
import TransactionsMenu from '../../ui/TransactionsMenu'
import { useParams } from 'react-router'
import { useProfile } from '../../../contexts/UserContext'
import { useAuth0 } from '@auth0/auth0-react'
import { ServiceTransactions } from '../../../services/ServiceTransactions'
import { useQuery } from '@tanstack/react-query'
import Loading from '../../Loading'
import SmallLoading from '../../ui/SmallLoading'

export default function Status() {
  const { fileid } = useParams()
  const { profile } = useProfile()
  const { getAccessTokenSilently } = useAuth0()
  const TransactionsfetchController = useRef(null)
  const getDocuments = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceTransactions = new ServiceTransactions(token)
    if (!TransactionsfetchController.current) {
      TransactionsfetchController.current =
        serviceTransactions.getController().controller
    }
    return serviceTransactions.get(fileid, '/statuses')
  }, [fileid, getAccessTokenSilently])

  const statusQuery = useQuery({
    queryKey: ['status', fileid],
    queryFn: getDocuments,
    refetchOnWindowFocus: false,
    enabled: !!profile,
  })

  useEffect(() => {
    return () =>
      TransactionsfetchController.current &&
      TransactionsfetchController.current.abort()
  }, [])

  const statusHTML = statusQuery?.data?.length
    ? statusQuery?.data?.map((item, index) => {
        return (
          <div key={index} className='mb-5'>
            {item.status_name && (
              <h3 className='text-primary font-semibold text-lg'>
                {item.status_name}
              </h3>
            )}
            {item.status_date && (
              <div className=''>
                {new Date(item.status_date).toLocaleDateString('us')}
              </div>
            )}
          </div>
        )
      })
    : ''
  // if (statusQuery.isFetching) {
  //   return <Loading />;
  // }
  return (
    <>
      {statusQuery?.isLoading ? (
        <SmallLoading />
      ) : (
        <>
          <div className=''>{statusHTML || ''}</div>
        </>
      )}
    </>
  )
}
