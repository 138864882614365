import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAiDaily } from '../../contexts/AiDailyContext'
import { validate_number } from '../../helpers'
import uiStyles from '../ui/uiStyles'
import ProgressBarChildren from '../ui/ProgressBarChildren'
import AiDailyProgressText from './AiDailyProgressText'
import Textarea from '../form/Textarea'
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi'
const AiDailyQuestionsCategory = () => {
  const params = useParams()
  const navigate = useNavigate()
  const cat_id = params?.cat_id || 1
  const {
    aiDaily,
    saveAiDaily,
    get_cat_per_ans,
    set_quest_ans,
    upd_quest_ans,
    set_quest,
  } = useAiDaily()
  console.log(aiDaily)
  const category =
    aiDaily?.data?.bio_cat?.find(
      (item) => validate_number(item.cat_id) === validate_number(cat_id)
    ) || {}

  useEffect(() => {
    const next_question = category?.questions
      ?.filter((item) => !item?.quest_ans)
      ?.sort(
        (a, b) =>
          validate_number(a.quest_order) - validate_number(b.quest_order)
      )?.[0]
    console.log('next question', next_question)
    set_quest(next_question)
  }, [])
  console.log(cat_id, category)

  function next_question(direction) {
    const curr_ord = category?.current_question?.quest_order
    console.log(curr_ord + direction)
    console.log(
      category?.questions?.find(
        (item) => item.quest_order === curr_ord + direction
      )
    )
    const next_order = curr_ord + direction
    const total_questions = category?.questions?.length
    let quest_to_find = next_order
    if (next_order > total_questions) {
      quest_to_find = 1
    } else if (next_order === 0) {
      quest_to_find = total_questions
    } else {
      quest_to_find = next_order
    }
    const next_quest = category?.questions?.find((item) => {
      return item.quest_order === quest_to_find
    })
    console.log('next', next_quest)
    set_quest(next_quest)
  }

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        <div className='flex flex-col gap-[15px] justify-center items-center'>
          <h1 className={uiStyles.text_black_title}>
            {category?.cat_name || ''}
          </h1>
          <ProgressBarChildren
            stringId={category?.cat_uuid}
            width={100}
            percentage={get_cat_per_ans(category)}
          >
            <AiDailyProgressText cat={category} />
          </ProgressBarChildren>
        </div>
        <div className='flex justify-center'>
          <div className='flex justify-center items-center mt-4'>
            <div className='flex flex-wrap justify-center gap-2'>
              {category?.questions?.map((item) => {
                return (
                  <div
                    className={`flex items-center justify-center gap-2 ${
                      category?.current_question?.quest_id === item?.quest_id
                        ? `${uiStyles.card_black_gradient} text-white`
                        : item?.quest_ans
                        ? `${uiStyles.card_blue_gradient} text-white`
                        : `${uiStyles.card_gray_gradient} text-primary`
                    }  w-[20px] h-[20px] border-r-[50%] cursor-pointer`}
                    id={item?.quest_id}
                    onClick={() => {
                      set_quest(item)
                    }}
                  >
                    <p>{item?.quest_order}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='px-3 mt-4'>
          {[category?.current_question]?.map((item) => {
            return (
              <div
                key={`quest_${item?.quest_uuid}`}
                className={`${uiStyles.card_gray_gradient} flex flex-col items-center`}
              >
                <p className={`${uiStyles.text_black_subtitle} my-[10px]`}>
                  {item?.quest}
                </p>
                <textarea
                  className={`${uiStyles.text_area} h-[200px]`}
                  value={item?.quest_ans}
                  onChange={(e) => {
                    const ans = e.target.value || ''
                    item.quest_ans = ans
                    set_quest_ans(item)
                    upd_quest_ans({
                      bio_cat_id: item?.bio_cat_id,
                      bio_quest_id: item.quest_id,
                      ans: ans,
                    })
                  }}
                >
                  {item?.quest_ans}
                </textarea>
              </div>
            )
          })}
        </div>
      </div>
      <div className='flex px-3 justify-between items-center w-full mt-[10px] text-black pb-[10px]'>
        <button>
          <FiArrowLeftCircle
            className='w-[25px] h-[25px] cursor-pointer'
            onClick={() => {
              next_question(-1)
            }}
          />
        </button>
        <button
          className={
            uiStyles?.[
              get_cat_per_ans(category) === 100
                ? 'hover_btn_small'
                : 'white_btn_small'
            ]
          }
          onClick={() => {
            return navigate(-1)
          }}
        >
          {get_cat_per_ans(category) === 100 ? 'Complete' : 'Done For Now'}
        </button>
        <button>
          <FiArrowRightCircle
            className='w-[25px] h-[25px] cursor-pointer'
            onClick={() => {
              next_question(1)
            }}
          />
        </button>
      </div>
    </div>
  )
}

export default AiDailyQuestionsCategory
