import { useState } from 'react'
import { IoMdDownload } from 'react-icons/io'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'

const DownloadBtn = ({ url, func_url }) => {
  const [loadingDownload, setLoadingDownload] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  return (
    <div>
      {loadingDownload ? (
        <div>
          <SmallLoadingRel />
        </div>
      ) : (
        <div
          className='cursor-pointer'
          onClick={async () => {
            setLoadingDownload(true)
            if (func_url) {
              url = await func_url()
            }
            const blob = await axiomFetch(
              `/calculators/flyer_download?url=${encodeURIComponent(url)}`,
              'GET',
              {},
              {},
              false,
              null,
              0,
              null,
              true
            )
              .then((response) => response.blob())
              .catch((err) => console.log(err))
            const blob_url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = blob_url
            const name = url?.split('/').pop()
            link.setAttribute(
              'download',
              `${
                name ||
                url?.replace(
                  'https://driggstitle.com/reviews/node/node_to_oracle/',
                  ''
                )
              }`
            )
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
            setLoadingDownload(false)
          }}
        >
          <IoMdDownload size={25} />
        </div>
      )}
    </div>
  )
}

export default DownloadBtn
