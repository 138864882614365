import { useEffect, useRef, useState } from 'react'
import { MdNotificationAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import ch_images from '../../../assets/images/conversations'
import { useListingTours } from '../../../contexts/ListingToursContext'
import {
  formatPhoneNumber,
  getCurrentTime,
  getDateString,
  set_storage,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import LabelInput from '../../form/LabelInput'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const BuyerToursCreate = ({
  state,
  setState,
  page,
  setPage,
  setShowModal,
  market_name,
  type,
}) => {
  const { listingTours, saveListingTours, is_buyer_tour } = useListingTours()
  console.log('create_state', listingTours)
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState(
    listingTours?.buyer_tours?.event_edit || {}
  )
  const [loading, setLoading] = useState(false)
  const [aiLoading, setAiLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const startRef = useRef(null)
  useEffect(() => {
    startRef.current.focus()
  }, [])
  const handleChange = (e) => {
    let { name, value } = e.target
    // if (name === 'cell') {
    //   value = value?.replace(/\D/g, '')?.slice(0, 10)?.toString() || ''
    // }
    console.log(name, value)

    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const save_event = async () => {
    console.log(listingTours?.buyer_tours?.event_edit?.event_type_id)
    const data = {
      ...inputValue,
      event_type_id:
        listingTours?.buyer_tours?.event_edit?.event_type_id || type || 162,
      tour_name: inputValue?.event_name || '',
      event_date:
        inputValue?.event_date ||
        getDateString({ format: 'yyyy-mm-ddt24h:mi' }),
    }
    setLoading(true)
    console.log('data', data)
    const result = await axiomFetch('/tours/event/create', 'POST', data)
    if (result.data) {
      result.data.acct_id = Number(result.data.acct_id)
      result.data.event_type_id = Number(result.data.event_type_id)
      result.data.created_by_cont_id = Number(result.data.created_by_cont_id)
    }

    saveListingTours((prev) => {
      const event_pages = prev?.buyer_tours?.event_edit?.event_id
        ? [
            ...prev?.data?.event_pages?.filter(
              (item) =>
                item.event_id !== prev?.buyer_tours?.event_edit?.event_id
            ),
            result?.data,
          ]
        : [...prev?.data?.event_pages, result?.data || {}]
      const filter_buyers = (buyers = [], buyer) => {
        const tour_info = Array.isArray(buyers) ? buyers : []
        return tour_info?.filter((item) => {
          return !(
            item?.evt_id === buyer?.evt_id && item?.cont_id === buyer?.cont_id
          )
        })
      }
      const event_tour_info = prev?.buyer_tours?.event_edit?.event_id
        ? [
            ...filter_buyers(
              filter_buyers(prev?.data?.event_tour_info, result?.buyers?.[0]),
              result?.buyers?.[1]
            ),
            ...result?.buyers,
          ]
        : [...prev?.data?.event_tour_info, ...(result?.buyers || [])]
      set_storage('listing_tours', {
        ...prev.data,
        event_pages,
        event_tour_info,
      })
      return {
        ...prev,
        data: { ...prev.data, event_pages, event_tour_info },
      }
    })
    if (setShowModal) {
      setLoading(false)
      setShowModal(false)
    } else {
      saveListingTours((prev) => {
        const ret_data = {
          ...prev?.buyer_tours,
          event_edit: { ...result?.data, new_tour: true },
        }
        set_storage('buyer_tours', ret_data)
        return {
          ...prev,
          buyer_tours: ret_data,
        }
      })
      return navigate('/user/buyer_tours/add_home')
    }
  }

  return (
    <div className='px-3'>
      <h1 className='mb-[5px]'>Create Tour</h1>
      <div className='overflow-y-scroll max-h-[370px] min-h-[175px]'>
        <LabelInput
          ref={startRef}
          left_label={'Name'}
          placeholder='Prescott Tour'
          name={'event_name'}
          value={inputValue?.event_name || ''}
          handleChange={handleChange}
        />
        <LabelInput
          left_label={'time'}
          text_size='text-sm'
          type='datetime-local'
          name={'event_date'}
          value={
            inputValue?.event_date
              ? getDateString({
                  date: inputValue?.event_date,
                  format: 'yyyy-mm-ddt24h:mi',
                })
              : getDateString({ format: 'yyyy-mm-ddt24h:mi' })
          }
          handleChange={handleChange}
        />
        {(listingTours?.buyer_tours?.event_edit?.event_type_id ||
          type ||
          162) === 162 ? (
          <>
            <h1 className='mb-[5px]'>Add Buyers</h1>

            <LabelInput
              left_label={'Buyer Name'}
              placeholder='John Doe'
              name={'agent_name_1'}
              value={inputValue?.agent_name_1 || ''}
              handleChange={handleChange}
            />
            <LabelInput
              left_label={'Buyer Cell'}
              text_size='text-sm'
              type='text'
              placeholder={formatPhoneNumber('5551234567')}
              name={'agent_cell_phone_1'}
              value={formatPhoneNumber(inputValue?.agent_cell_phone_1)}
              handleChange={handleChange}
            />
            <div className={`${uiStyles.line} mb-[10px]`}></div>
            <LabelInput
              left_label={'Buyer Name'}
              placeholder='John Doe'
              name={'agent_name_2'}
              value={inputValue?.agent_name_2 || ''}
              handleChange={handleChange}
            />
            <LabelInput
              left_label={'Buyer Cell'}
              text_size='text-sm'
              type='text'
              placeholder={formatPhoneNumber('5551234567')}
              name={'agent_cell_phone_2'}
              value={formatPhoneNumber(inputValue?.agent_cell_phone_2)}
              handleChange={handleChange}
            />
          </>
        ) : (
          ''
        )}
        {/* {setShowModal ? (
          <div className={`flex justify-end gap-[10px] items-center`}>
            <button
              className={`${uiStyles.hover_btn_small} flex items-center gap-[10px]`}
              onClick={() => {
                navigate('/user/buyer_tours/announcements')
              }}
            >
              <span>Add Announcements</span>{' '}
              <span>
                {' '}
                <MdNotificationAdd />
              </span>{' '}
            </button>
          </div>
        ) : (
          ''
        )} */}
      </div>

      <div className={`flex justify-end gap-[10px] items-center`}>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            if (setShowModal) {
              setShowModal(false)
            }
            navigate('/user/buyer_tours')
          }}
        >
          Back
        </button>
        {!loading ? (
          <button className={`${uiStyles.hover_btn}`} onClick={save_event}>
            {listingTours?.buyer_tours?.event_edit?.event_id
              ? 'Save/Next'
              : 'Next'}
          </button>
        ) : (
          <div className={`${uiStyles.loading_msg} min-w-0 w-[150px]`}>
            Loading...
          </div>
        )}
      </div>
    </div>
  )
}

export default BuyerToursCreate
