import { useState, useEffect } from 'react'
import { usePairwise } from '../../../contexts/PairwiseContext'
import useHelpers from '../../../hooks/useHelpers'
import { GrDocument, GrDocumentMissing } from 'react-icons/gr'
import uiStyles from '../../ui/uiStyles'
import LabelInput from '../../form/LabelInput'
import { useProfile } from '../../../contexts/UserContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'

const DocTemps = () => {
  const { pairwise, savePairwise } = usePairwise()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const { profile } = useProfile()
  const { open_url } = useHelpers()
  console.log('pairwise', pairwise)
  const { axiomFetch } = useAxiomFetch()
  const docs = pairwise?.data?.pair_axiom_doc_temps || []
  useEffect(() => {
    setEmail(profile.CONT_EMAIL || '')
  }, [profile.CONT_EMAIL])
  return (
    <div className='px-3'>
      <div className='mb-[5px]'>
        <h1 className={uiStyles.text_black_subtitle}>Documents</h1>
      </div>
      {docs?.map((item) => {
        return (
          <div
            key={item.pair_doc_uuid}
            className='flex gap-[5px] mb-[10px]'
            onClick={() => {
              open_url(item?.doc_pdf)
            }}
          >
            <GrDocument size={20} />
            <a className='underline cursor-pointer'>{item?.doc_name}</a>
          </div>
        )
      })}
      <div className='flex justify-between mb-[10px] items-center gap-[5px]'>
        <LabelInput
          left_label_width={'w-[30%]'}
          left_label='Email'
          name='email'
          value={email}
          show_delete_all={true}
          delete_all_position='top-[-5px] right-[-5px]'
          handleChange={(e) => {
            setEmail(e.target.value)
          }}
        />
        {loading ? (
          <div className='w-[25px]'>
            <SmallLoadingRel />
          </div>
        ) : (
          <button
            className={`${uiStyles.hover_btn_small} mb-[-7px]`}
            onClick={async () => {
              setLoading(true)
              const html = `
            <p>Hi,</p>

            <p>Here are the pairwise documents. Be Sure to fill them out inside of AXIOM.</p>
            <ul>
            ${docs
              .map((item) => {
                return `<li>${item.doc_name} - ${item.doc_pdf}</li>`
              })
              .join('')}
            </ul>
            `
              const body = {
                msg: {
                  to: [email, profile?.REP_EMAIL || '']?.filter((item) => item),
                  // to: ['andrewj@driggstitle.com'],
                  from: 'youready@theaxiomapp.com',
                  subject: `Pairwise Documents - ${profile?.CONT_NAME}`,
                  html,
                },
              }
              await axiomFetch('/social/email/sendEmail', 'POST', body)
              setLoading(false)
            }}
          >
            Email Docs
          </button>
        )}
      </div>
    </div>
  )
}

export default DocTemps
