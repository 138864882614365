import { Suspense } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { Outlet } from 'react-router-dom'
import Loading from './Loading'
import { useLocation } from 'react-router-dom'
import { ProviderCombined } from '../contexts'
import { set_storage } from '../helpers'

export const AuthLayout = () => {
  const location = useLocation()
  const params = new URLSearchParams(location?.search)
  const use_password = params?.has('password')
  let redirect_uri = window?.location?.origin + (location?.search || '')

  return (
    <Suspense fallback={<Loading />}>
      <Auth0Provider
        domain={process.env.REACT_APP_DOMAIN}
        clientId={
          use_password
            ? process.env.REACT_APP_CLIENTID_2
            : process.env.REACT_APP_CLIENTID
        }
        redirectUri={redirect_uri}
        audience={process.env.REACT_APP_AUDIENCE}
        useRefreshTokens={true}
        cacheLocation={'localstorage'}
        useRefreshTokensFallback={false}
      >
        <ProviderCombined>
          <Outlet />
        </ProviderCombined>
      </Auth0Provider>
    </Suspense>
  )
}
