import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useListingTours } from '../../../contexts/ListingToursContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const SendResults = ({ setShowModal, setBuyerModal }) => {
  const { axiomFetch } = useAxiomFetch()
  const { listingTours, saveListingTours, complete_tour } = useListingTours()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const finish_tour = async () => {
    setLoading(true)
    await complete_tour()
    setLoading(false)
    navigate('/user')
  }
  return (
    <div className='flex flex-col justify-between h-[250px]'>
      <p>
        We will generate a completed report and send it to you and your buyers.
        Are you sure you want to complete the tour?
      </p>
      <div className='flex justify-end gap-[5px]'>
        <button
          className={uiStyles.white_btn}
          onClick={() => {
            setBuyerModal(false)
            setTimeout(() => {
              setShowModal(true)
            })
          }}
        >
          Back
        </button>
        {loading ? (
          <SmallLoadingRel />
        ) : (
          <button
            className={`${uiStyles.hover_btn} text-[13px]`}
            onClick={finish_tour}
          >
            Confirm Tour Completed
          </button>
        )}
      </div>
    </div>
  )
}

export default SendResults
