import React, { useEffect } from 'react'
import uiStyles from '../ui/uiStyles'
import { useAiDaily } from '../../contexts/AiDailyContext'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { useNavigate } from 'react-router-dom'
import { useProfile } from '../../contexts/UserContext'

const AiDailySignup = () => {
  const { aiDaily, upd_daily_signup, saveAiDaily, gen_daily_resp, save_resp } =
    useAiDaily()
  const profile = useProfile()
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  useEffect(() => {
    const sign = async () => {
      localStorage.removeItem('redirect')
      upd_daily_signup({
        send_daily_text: 1,
        cont_id: profile?.CONT_ID,
        create_daily_post: 1,
      })
      await axiomFetch('/marketing/content/ai/daily/signup', 'POST', {
        send_daily_text: 1,
      })
    }
    sign()
  }, [])
  return (
    <div className='px-3'>
      <h1 className={`${uiStyles?.text_black_title} leading-9`}>
        Congrats, you've been signed up for "The Daily Post" 🎉
      </h1>
      <div className='flex justify-end gap-[10px] mt-[10px]'>
        <button
          className={uiStyles?.hover_btn}
          onClick={() => {
            navigate('/user/ai/daily')
          }}
        >
          Go To Daily Post
        </button>
      </div>
    </div>
  )
}

export default AiDailySignup
