import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  date_in_future,
  date_today,
  date_today_future,
  date_within_days,
  set_storage,
  validate_number,
} from '../helpers'
import useAxiomFetch from '../hooks/useAxiomFetch'
import { useClaims } from './ClaimsContext'
import { useProfile } from './UserContext'

export const ListingToursContext = createContext()

export const ListingToursProvider = ({ children, userData }) => {
  const [listingTours, setListingTours] = useState({})
  const location = useLocation()
  const { claims } = useClaims()
  const { profile } = useProfile()
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const saveListingTours = useCallback((data) => {
    setListingTours(data)
  }, [])

  const save_home = async (data, nav) => {
    const already_exists = () => {
      if (
        (listingTours?.data?.event_tour_info || [])?.find(
          (item) =>
            item?.evt_id === (data?.evt_id || data?.event_id) &&
            item?.mls_num?.toString() === data?.mls_id?.toString()
        )
      ) {
        return true
      } else {
        return false
      }
    }
    let result = {}
    if (!already_exists()) {
      result = await axiomFetch('/tours/property/add', 'POST', data || {})
      const event_tour_result = result?.event_tour_info?.[0]
      const event_signup_result = result?.event_signup?.[0]
      saveListingTours((prev) => {
        const event_tour_info = [
          ...prev?.data?.event_tour_info?.filter(
            (item) =>
              !(
                item?.evt_id?.toString() ===
                  event_tour_result?.evt_id?.toString() &&
                item.mls_num?.toString() ===
                  event_tour_result?.mls_num?.toString()
              )
          ),
          event_tour_result,
        ]
        const event_signup = [
          ...prev?.data?.event_signup?.filter(
            (item) =>
              !(
                item?.event_id?.toString() ===
                  event_signup_result?.event_id?.toString() &&
                item?.list_id?.toString() ===
                  event_signup_result?.list_id?.toString()
              )
          ),
          event_signup_result,
        ]

        set_storage('listing_tours', {
          ...prev?.data,
          event_tour_info,
          event_signup,
        })
        return {
          ...prev,
          data: { ...prev?.data, event_tour_info, event_signup },
        }
      })
    }
    if (nav) {
      return navigate(nav)
    } else {
      return result
    }
  }
  const get_buyers = (event_id) => {
    return listingTours?.data?.event_tour_info?.filter(
      (item) => item?.evt_id === event_id && item.cont_id > 0 && !item.mls_num
    )
  }
  const get_homes = (event_id) => {
    return listingTours?.data?.event_tour_info?.filter(
      (item) => item?.evt_id === event_id && item.cont_id > 0 && item.mls_num
    )
  }

  const get_tours_by_type = (type) => {
    return listingTours?.data?.event_pages?.filter(
      (item) => item.event_type_id === type
    )
  }
  const get_tour_type = (type) => {
    return listingTours?.data?.tour_types?.find((item) => item.type_id === type)
  }
  const get_event_type_id = (event_id) => {
    return listingTours?.data?.event_pages?.find(
      (item) => item.event_id === event_id
    )?.event_type_id
  }

  const get_event_tour_type_id = (event_id) => {
    return listingTours?.data?.event_pages?.find(
      (item) => item.event_id === event_id
    )?.tour_experience_id
  }

  const is_assigned_tour = (tour_type_id) => {
    return listingTours?.data?.tour_types_asg
      ?.map((item) => item?.tour_type_id?.toString())
      ?.includes(tour_type_id?.toString())
  }

  const get_show_tours = () => {
    return (listingTours?.data?.tour_types || [])?.filter((item) => {
      if (item?.type_id === 1) {
        return false
      } else if (
        !is_assigned_tour(item?.type_id) &&
        item?.event_type_id === 163 &&
        !claims?.user_id
      ) {
        return false
      } else {
        return true
      }
    })
  }

  const get_experience_type_id = (type) => {
    return get_show_tours()?.find((item) => item.event_type_id === type)
      ?.type_id
  }

  const show_tour_experience_lobby = () => {
    return get_show_tours()?.length > 1
  }

  const is_buyer_tour = () => {
    return (listingTours?.buyer_tours?.event_type_id || 162) === 162
  }

  const get_sec_per_home = (event_id) => {
    const event = listingTours?.data?.event_pages?.find(
      (item) => Number(item?.event_id) === Number(event_id)
    )
    console.log('time_event', event)
    const time = Number(event?.mins_between_house || 10) * 60
    return time
  }

  const all_tour_types = [21, 162, 163]
  const organize_tours = (tour_types = all_tour_types) => {
    const upcoming_tours =
      listingTours?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id?.toString() !==
              listingTours?.tour_state?.event_id?.toString() &&
            (item?.demo_tour || 0) === 0 &&
            date_in_future(item?.event_timestamp)
        )
        ?.sort((a, b) => {
          return new Date(a.event_timestamp) - new Date(b.event_timestamp)
        }) || []

    const current_tour =
      listingTours?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id?.toString() ===
            listingTours?.tour_state?.event_id?.toString()
        )
        ?.sort((a, b) => {
          return new Date(a.event_timestamp) - new Date(b.event_timestamp)
        }) || []

    const today_tours =
      listingTours?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id?.toString() !==
              listingTours?.tour_state?.event_id?.toString() &&
            (item?.demo_tour || 0) === 0 &&
            date_today(item?.event_timestamp)
        )
        ?.sort((a, b) => {
          return new Date(a.event_timestamp) - new Date(b.event_timestamp)
        }) || []

    const old_tours =
      listingTours?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id?.toString() !==
              listingTours?.tour_state?.event_id?.toString() &&
            (item?.demo_tour || 0) === 0 &&
            date_within_days(item?.event_timestamp, 60) &&
            !date_today_future(item?.event_timestamp)
        )
        ?.sort((a, b) => {
          return new Date(b.event_timestamp) - new Date(a.event_timestamp)
        }) || []

    const demo_tours =
      listingTours?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id !== listingTours?.tour_state?.event_id &&
            item.demo_tour === 1
        )
        ?.sort((a, b) => {
          return new Date(a.event_timestamp) - new Date(b.event_timestamp)
        }) || []

    return [
      ...current_tour,
      ...today_tours,
      ...upcoming_tours,
      ...old_tours,
      ...demo_tours,
    ]?.filter((item) => tour_types.includes(item.event_type_id))
  }

  const open_tour = (item) => {
    setListingTours((prev) => {
      const new_tour_state =
        prev?.tour_state?.event_id === item?.event_id
          ? {
              ...prev?.tour_state,
              from_card: location?.pathname?.includes('card') ? true : false,
            }
          : {
              event_id: item?.event_id,
              completed: item?.tour_complete || 0,
              from_card: location?.pathname?.includes('card') ? true : false,
            }
      set_storage('listing_tours_state', new_tour_state)
      return {
        ...prev,
        tour_state: new_tour_state,
      }
    })
    return navigate('/user/listing_tours')
  }

  const go = (page_change) => {
    setListingTours((prev) => {
      const new_tour_state = {
        ...prev?.tour_state,
      }
      const get_page = () => {
        const new_page = (prev?.tour_state?.current_page || 0) + page_change
        if (new_page <= 0) {
          return 1
        }

        const tour_type_id = get_event_tour_type_id(
          validate_number(listingTours?.tour_state?.event_id)
        )

        console.log('tour_type', tour_type_id)
        const eo_id = listingTours?.data?.event_pages?.find(
          (item) =>
            Number(item?.event_id) ===
            Number(listingTours?.tour_state?.event_id)
        )?.eo_of_focus

        const product_page_applied =
          listingTours?.data?.tour_type_pages_applied?.find(
            (item) =>
              item.type_id === tour_type_id &&
              item.page_id === 4 &&
              item.page_ord > 0
          )

        console.log('product_page', product_page_applied)
        let product_pages_count = product_page_applied
          ? listingTours?.data?.tour_opening_dta?.filter(
              (item) =>
                item.tour_page_id === 4 &&
                product_page_applied?.applied_id === item.applied_page_id
            )?.length
          : 0
        if (tour_type_id > 1) {
          product_pages_count =
            listingTours?.data?.event_tour_ann?.filter(
              (item) =>
                Number(item.event_id) ===
                Number(listingTours?.tour_state?.event_id)
            )?.length || 0
        }

        console.log('product_pages_count', product_pages_count)

        const listing_pages = listingTours?.data?.event_tour_info?.filter(
          (item) =>
            item?.cont_id > 0 &&
            item?.mls_num &&
            item?.evt_id?.toString() ===
              listingTours?.tour_state?.event_id?.toString()
        )?.length

        if (!listing_pages && new_tour_state.current_page === 6) {
          new_tour_state.listing_page = 0
          new_tour_state.product_page = 0
          new_tour_state.eo_page = 0
          if (page_change > 1) {
            return 6
          }
          if (new_tour_state?.product_page > 0) {
            return 4
          }
          return 1
        }

        if (
          listing_pages <
          (prev?.tour_state?.listing_page || 0) + page_change
        ) {
          console.log('hello page 6')
          new_tour_state.listing_page =
            (prev?.tour_state?.listing_page || 0) + page_change
          return 6
        }

        if (new_page % 3 === 0) {
          if (new_tour_state?.product_page > 0 && page_change < 0) {
            if (!eo_id) {
              new_tour_state.product_page -= 1
              if (new_tour_state?.product_page === 0) {
                return 2
              }
              return 5
            }
            new_tour_state.product_page -= 1
          }
          if (page_change > 0) {
            if (!eo_id) {
              new_tour_state.product_page =
                (prev?.tour_state?.product_page || 0) + page_change
              if (
                product_pages_count <
                (prev?.tour_state?.product_page || 0) + page_change
              ) {
                new_tour_state.listing_page =
                  (prev?.tour_state?.listing_page || 0) + page_change
                return 5
              }
              return 4
            }
            new_tour_state.eo_page =
              (prev?.tour_state?.eo_page || 0) + page_change
          }
          return 3
        } else if (new_page % 4 === 0) {
          console.log('listing_page', new_tour_state?.listing_page)
          if (new_tour_state?.listing_page > 0 && page_change < 0) {
            new_tour_state.listing_page -= 1
            if (product_pages_count < (prev?.tour_state?.product_page || 0)) {
              if ((new_tour_state?.product_page || 0) > 0) {
                new_tour_state.product_page -= 1
              }
              if (!eo_id) {
                if (new_tour_state?.listing_page === 0) {
                  return 2
                } else {
                  return 5
                }
              }
              if (new_tour_state?.listing_page === 0) {
                return 3
              } else {
                return 5
              }
            }
          }
          if (page_change > 0) {
            new_tour_state.product_page =
              (prev?.tour_state?.product_page || 0) + page_change
          }
          return 4
        } else if (new_page % 5 === 0) {
          new_tour_state.listing_page =
            (prev?.tour_state?.listing_page || 0) + page_change
          return 5
        } else if (new_page < 3 && prev?.tour_state?.listing_page > 0) {
          if (new_tour_state?.eo_page > 0 && page_change < 0) {
            new_tour_state.eo_page -= 1
          }
          return 5
        }
        if (new_tour_state?.eo_page > 0 && page_change < 0) {
          new_tour_state.eo_page -= 1
        }
        return prev?.tour_state?.current_page + page_change <= 1
          ? 1
          : (prev?.tour_state?.current_page || 1) + page_change
      }

      new_tour_state.current_page = get_page()

      set_storage('listing_tours_state', new_tour_state)
      return {
        ...prev,
        tour_state: new_tour_state,
      }
    })
  }

  const complete_tour = async () => {
    const event_id = listingTours?.tour_state?.event_id
    const event_type_id = get_event_type_id(event_id)
    await axiomFetch('/tours/complete', 'POST', {
      event_id: listingTours?.tour_state?.event_id,
      event_type_id,
      completed: listingTours?.tour_state?.completed ? 0 : 1,
      buyers: get_buyers(event_id),
      agent: {
        agent_name: profile?.CONT_NAME,
        agent_cell_phone: profile?.CONT_CELL_NUM,
      },
    })
    if (listingTours?.tour_state?.completed) {
      setListingTours((prev) => {
        const new_tour_state = { ...prev?.tour_state, completed: 0 }
        set_storage('listing_tours_state', new_tour_state)
        return {
          ...prev,
          tour_state: new_tour_state,
        }
      })
    }
  }

  const value = useMemo(
    () => ({
      listingTours,
      saveListingTours,
      save_home,
      get_buyers,
      get_homes,
      get_tours_by_type,
      get_tour_type,
      get_event_type_id,
      get_event_tour_type_id,
      is_assigned_tour,
      show_tour_experience_lobby,
      get_experience_type_id,
      is_buyer_tour,
      get_show_tours,
      get_sec_per_home,
      organize_tours,
      all_tour_types,
      open_tour,
      go,
      complete_tour,
    }),
    [listingTours, saveListingTours, save_home, get_buyers]
  )

  return (
    <ListingToursContext.Provider value={value}>
      {children}
    </ListingToursContext.Provider>
  )
}

export const useListingTours = () => {
  const context = useContext(ListingToursContext)

  if (!context) {
    throw Error('TourContext must be used inside an TourListingContextProvider')
  }

  return context
}
