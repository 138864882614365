import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import images_homes from '../../../assets/images/home'
import { format_number, get_storage, search_street } from '../../../helpers'
import Modal from '../../Modal'
import Pagination from '../../ui/Pagination'
import PropertyItem from '../../ui/PropertyItem'
import SwipeConfig from '../../ui/SwipeConfig'
import uiStyles from '../../ui/uiStyles'
import { HomeFilter } from './HomeFilter'
import { decompressFromUTF16 } from 'lz-string'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useReceiver } from '../../../contexts/ReceiverContext'
export const HomeList = ({
  title,
  state,
  setState,
  right_component,
  addTourLoading,
  show_overflow = true,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [selected, setSelected] = useState(1)
  const [homeSearch, setHomeSearch] = useState('')
  const [timedout, setTimedout] = useState('')
  const [filteredHomes, setFilteredHomes] = useState([])
  const navigate = useNavigate()
  const filter_length = useRef(null)
  const images_length = filter_length?.current || 0
  const curr_page = state?.homes?.page || 1
  const start = state?.homes?.start || 25
  const max_page = Math.ceil(images_length / start)
  const pageTopRef = useRef(null)
  const { listingTours, saveListingTours, save_home } = useListingTours()
  const [addTourLoading2, setAddTourLoading2] = useState('')
  const { receiver, saveReceiver } = useReceiver()
  useEffect(() => {
    if (!state?.homes?.favorited_homes?.length) {
      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          favorited_homes: get_storage('favorited_homes') || [],
        },
      }))
    }
    if (!state?.homes?.homes?.length) {
      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          homes: get_storage('home_search') || [],
        },
      }))
    }
  }, [])

  useEffect(() => {
    const all_homes = () => {
      let homes = state?.homes?.homes?.map((item) => {
        const favorited_home = state?.homes?.favorited_homes?.find(
          (home) => item.mls_id === home?.mls_id
        )
        if (favorited_home) {
          item.favorited = 'Y'
        } else {
          item.favorited = 'N'
        }
        return item
      })
      let filters = state?.homes

      // price and bedrooms
      if (format_number(filters?.min_price)) {
        homes = homes?.filter(
          (item) =>
            format_number(item?.list_price) >= format_number(filters?.min_price)
        )
      }
      if (
        format_number(filters?.max_price) &&
        format_number(filters?.max_price) > format_number(filters?.min_price)
      ) {
        homes = homes?.filter(
          (item) =>
            format_number(item?.list_price) <= format_number(filters?.max_price)
        )
      }

      // bedrooms
      if (filters?.bedrooms !== 'any' && filters?.bedrooms) {
        homes = homes.filter(
          (item) =>
            format_number(item?.beds) >= format_number(filters?.bedrooms)
        )
      }
      // bathrooms
      if (filters?.bathrooms !== 'any' && filters?.bathrooms) {
        homes = homes.filter(
          (item) =>
            format_number(item?.baths) >= format_number(filters?.bathrooms)
        )
      }

      // // open house
      if (filters?.open_house === 'Y') {
        homes = homes?.filter((item) => item?.upcoming_open_house > 0) || []
      }

      homes = homes?.filter((item) => {
        if (filters?.for_sale !== 'Y' && filters?.open_house !== 'Y') {
          if (item?.mls_status?.toLowerCase() === 'active') {
            return false
          }
        }
        if (filters?.coming_soon !== 'Y') {
          if (item?.mls_status?.toLowerCase() === 'coming soon') {
            return false
          }
        }
        if (filters?.expired !== 'Y') {
          if (item?.mls_status?.toLowerCase() === 'expired') {
            return false
          }
        }
        if (filters?.sold !== 'Y') {
          if (item?.mls_status?.toLowerCase() === 'closed') {
            return false
          }
        }
        if (filters?.cancelled !== 'Y') {
          if (
            item?.mls_status?.toLowerCase() === 'cancelled' ||
            item?.mls_status?.toLowerCase() === 'deleted'
          ) {
            return false
          }
        }
        if (filters?.under_contract !== 'Y') {
          if (
            item?.mls_status?.toLowerCase() ===
              'ccbs (contract contingent on buyer sale)' ||
            item?.mls_status?.toLowerCase() ===
              'ucb (under contract-backups)' ||
            item?.mls_status?.toLowerCase() === 'pending'
          ) {
            return false
          }
        }
        if (filters?.off_market !== 'Y') {
          if (
            item?.mls_status?.toLowerCase() === 'temp off market' ||
            item?.mls_status?.toLowerCase() === 'hold'
          ) {
            return false
          }
        }

        return true
      })

      if (homeSearch) {
        console.log(search_street(homeSearch)?.replace(/\s/g, ''))
        homes = homes.filter((item) =>
          search_street(item?.prop_add)
            ?.replace(/\s/g, '')
            .includes(search_street(homeSearch)?.replace(/\s/g, ''))
        )
        homes.forEach((item) => {
          console.log(search_street(item?.prop_add)?.replace(/\s/g, ''))
        })
      }

      filter_length.current = homes?.length
      // resort for favorites.
      const homes_to_show = homes
        ?.sort((a, b) => a?.prop_add?.localeCompare(b?.prop_add))
        ?.sort(
          (a, b) => format_number(b?.list_price) - format_number(a?.list_price)
        )
        ?.slice(state?.homes?.skip || 0, state?.homes?.limit || start)
      setFilteredHomes(
        Array.isArray(homes_to_show) && homes_to_show?.length
          ? homes_to_show
          : []
      )
      return homes
        ?.sort((a, b) => a?.prop_add?.localeCompare(b?.prop_add))
        ?.sort(
          (a, b) => format_number(b?.list_price) - format_number(a?.list_price)
        )
        ?.slice(state?.homes?.skip || 0, state?.homes?.limit || start)
    }
    all_homes()

    return () => {
      return all_homes()
    }
  }, [homeSearch, state?.homes, state?.homes?.favorited_homes])

  useEffect(() => {
    setTimeout(() => {
      setTimedout(true)
    }, 5000)
  }, [])

  const filter_change = () => {
    const filters = state?.homes
    if (
      filters?.bathrooms !== 'any' ||
      filters?.bedrooms !== 'any' ||
      filters?.max_price ||
      filters?.min_price ||
      filters?.for_sale !== 'Y' ||
      filters?.open_house === 'Y' ||
      filters?.cancelled === 'Y' ||
      filters?.expired === 'Y' ||
      filters?.under_contract === 'Y' ||
      filters?.sold === 'Y' ||
      filters?.off_market === 'Y' ||
      filters?.coming_soon === 'Y'
    ) {
      return true
    } else {
      return false
    }
  }

  const get_page_num = (change) => {
    const save = (page) => {
      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          page,
        },
      }))
      return page
    }
    if (curr_page + change > max_page) {
      return save(1)
    } else if (curr_page + change <= 0) {
      return save(max_page)
    } else {
      return save(curr_page + change)
    }
  }

  const showNext = () => {
    const page = get_page_num(1)
    pageTopRef.current.scrollTop = 0
    const wrapper = document.getElementById('wrapper')
    wrapper.scrollTo({ top: 0 })
    const save = (skip, limit) => {
      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          skip: (prev?.homes?.skip || 0) + skip,
          limit: (prev?.homes?.limit || start) + limit,
        },
      }))
      return
    }
    if (page === max_page) {
      return save(start, images_length % start || start)
    }
    if (page === 1) {
      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          skip: 0,
          limit: start,
        },
      }))
      return
    }
    return save(start, start)
  }
  const showPrev = () => {
    let page = get_page_num(-1)
    pageTopRef.current.scrollTop = 0
    const wrapper = document.getElementById('wrapper')
    wrapper.scrollTo({ top: 0 })
    if (page + 1 === max_page) {
      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          skip: prev?.homes?.skip - start,
          limit: images_length - (images_length % start || start),
        },
      }))
      return
    }
    if (page === max_page) {
      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          skip: images_length - (images_length % start || start),
          limit: images_length,
        },
      }))
      return
    }
    setState((prev) => ({
      ...prev,
      homes: {
        ...prev?.homes,
        skip: prev?.homes?.skip - start,
        limit: prev?.homes?.limit - start,
      },
    }))
  }
  return (
    <div className=''>
      <div className='flex justify-center'>
        <p
          className={`${uiStyles.text_black_title} capitalize text-center mt-[5px]`}
        >
          {`${filter_length?.current || ''} ${title || 'homes for sale'}`}
        </p>
        <div
          className='ml-[20px] cursor-pointer'
          onClick={() => {
            navigate('/homes/favorites', {
              state: { parent: '/homes/list' },
            })
          }}
        >
          <img
            src={
              images_homes[
                state?.homes?.homes?.length ? 'heart_filled' : 'heart'
              ]
            }
            className={'w-[40px]'}
          />
        </div>
        <div
          className='mt-[5px] ml-[10px] cursor-pointer'
          onClick={() => {
            setShowModal((prev) => !prev)
          }}
        >
          <img src={images_homes.filter} />
        </div>
      </div>
      <div className='px-3 mt-[10px]'>
        <input
          className={`${uiStyles.input}`}
          value={homeSearch}
          onChange={(e) => {
            setHomeSearch(e.target.value)
          }}
          placeholder='search by address'
        />
      </div>
      {images_length > start ? (
        <div className='mx-[40px]'>
          <Pagination
            showlabel={false}
            showEndLabel={true}
            showPrev={images_length && images_length >= start}
            showNext={images_length && images_length >= start}
            clickNextHandler={showNext}
            clickPrevHandler={showPrev}
            pageNum={curr_page}
            pageEnd={max_page}
          />
        </div>
      ) : (
        <></>
      )}
      <div
        ref={pageTopRef}
        className={`mt-[10px] ${show_overflow ? 'overflow-scroll' : ''}`}
      >
        {filteredHomes?.length ? (
          filteredHomes?.map((item, index) => {
            return (
              <>
                {addTourLoading === item?.mls_id ? (
                  <SmallLoadingRel />
                ) : (
                  <SwipeConfig
                    index={index}
                    selectedIndex={selected}
                    setSelectedIndex={setSelected}
                    widthConfigRight={44}
                    RightComponents={() => right_component(item)}
                    key={item?.mls_id || index}
                  >
                    <PropertyItem
                      click_me={true}
                      image_link={async () => {
                        if (
                          listingTours?.buyer_tours?.property_edit
                            ?.search_type === 'address'
                        ) {
                          setAddTourLoading2(item.mls_id)
                          saveReceiver((prev) => ({
                            ...prev,
                            homes: { ...prev?.homes, current_home: item },
                          }))

                          await save_home(
                            {
                              mls_id: item?.mls_id?.toString(),
                              event_id:
                                listingTours?.buyer_tours?.event_edit?.event_id,
                              evt_id:
                                listingTours?.buyer_tours?.event_edit?.evt_id,
                            },
                            '/user/buyer_tours/add_home'
                          )
                          setAddTourLoading2('')
                        } else {
                          saveReceiver((prev) => ({
                            ...prev,
                            homes: { ...prev?.homes, current_home: item },
                          }))
                          navigate('/homes/view')
                        }
                      }}
                      listing={item}
                      item={item}
                      show_favorite={true}
                      favorited={item?.favorited === 'Y' ? true : false}
                    />
                  </SwipeConfig>
                )}
              </>
            )
          })
        ) : (
          <div className='px-3 py-4'>
            {homeSearch || filter_change() || timedout ? (
              'No Homes found for your current search.'
            ) : (
              <SmallLoadingRel />
            )}
          </div>
        )}
      </div>
      {images_length > start ? (
        <div className='mx-[40px]'>
          <Pagination
            showlabel={false}
            showEndLabel={true}
            showPrev={images_length && images_length >= start}
            showNext={images_length && images_length >= start}
            clickNextHandler={showNext}
            clickPrevHandler={showPrev}
            pageNum={curr_page}
            pageEnd={max_page}
          />
        </div>
      ) : (
        <></>
      )}
      {showModal ? (
        <Modal
          modalName={'home_filter'}
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={true}
        >
          <HomeFilter
            state={state}
            setState={setState}
            setShowModal={setShowModal}
          />
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}
