import { useState } from 'react'
import { AiOutlineCopy } from 'react-icons/ai'
import { useMktReqs } from '../../../contexts/MktReqsContext'
import { format_input, validate_number } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import useHelpers from '../../../hooks/useHelpers'
import LabelInput from '../../form/LabelInput'
import { Schedule } from '../../form/Schedule'
import Select from '../../form/Select'
import CopyText from '../../helpers/CopyText'
import uiStyles from '../../ui/uiStyles'
const MarketingAddData = ({ state, setState, title }) => {
  const {
    load_session_from_layout,
    generate_flyer_session,
    generate_pdf,
    replace_fields,
  } = useMktReqs()
  const { axiomFetch } = useAxiomFetch()
  const { open_url } = useHelpers()
  const [messageSent, setMessageSent] = useState({})
  const [copy, setCopied] = useState('')
  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, validate_number(close?.toString()) || 3000)
    }
  }

  const update_input = (options) => {
    const calculator_type = 'market_data'
    let { name, value } = format_input(options)
    let type =
      options?.type || state?.[calculator_type]?.types?.[`${name}`] || ''
    let max = validate_number(state?.[calculator_type]?.maximums?.[`${name}`])

    if (max && validate_number(value) > max) {
      value = max
    }
    if (type.includes('int_str')) {
      const length = validate_number(type)
      if (length) {
        value = validate_number(value.slice(0, length))
      } else {
        value = validate_number(value)
      }
    }
    setState((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
      return result
    })
  }

  const get_inputs = (calculator_type = 'market_data') => {
    const types = state?.[calculator_type]?.types || {}
    const names = state?.[calculator_type]?.names || {}
    const placeholders = state?.[calculator_type]?.placeholders || {}
    const values = state?.[calculator_type]?.values || {}
    const maximums = state?.[calculator_type]?.maximums || {}
    return Object.entries(values || {})
      ?.filter(([key, value]) => !['county'].includes(key))
      ?.map(([key, value]) => {
        const type = types?.[key]
        const name = names?.[key]
        const max = maximums?.[key]
        const placeholder = placeholders?.[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        initial_data.show_delete_all = true
        if (
          type === '%' ||
          type === '$' ||
          type === '%$' ||
          type === 'm' ||
          type === 'a' ||
          type === 'int'
        ) {
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (
          key === 'taxes' ||
          key === 'insurance_rate' ||
          key === 'hoa' ||
          key === 'other' ||
          key === 'utilities'
        ) {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            state?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent = state?.[calculator_type]?.types?.[key]
          if (key === 'hoa' || key === 'other' || key === 'utilities') {
            initial_data.toggle_values = ['a', 'm']
            initial_data.dollar_percent = 'm'
          }
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            state?.[calculator_type]?.values?.home_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )

        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          const options = state?.[calculator_type]?.option_values?.[key]
          const names = state?.[calculator_type]?.option_names?.[key]
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={value || 'Conventional'}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...(options || [])]?.map((item, index) => {
                return (
                  <option key={item} value={item}>
                    {names?.[index]}
                  </option>
                )
              })}
            </Select>
          )
        }
        if (max) {
          result = (
            <div className='relative'>
              <div>{result}</div>
              <div className='absolute top-[13px] right-[30px]'>
                {`max (${max})`}
              </div>
            </div>
          )
        }

        return <div key={key}>{result}</div>
      })
  }

  const generate_stats = async () => {
    try {
      const market_values = state?.market_data?.values
      setState((prev) => ({
        ...prev,
        create_order: { ...prev?.create_order, vbc_pdf_url: '' },
      }))
      if (!validate_number(market_values?.days_back)) {
        return handle_msg(
          'failure_msg',
          'A time period in Days is required',
          'market_stats',
          true
        )
      }
      if (
        !market_values?.city &&
        !market_values?.zip &&
        !market_values?.subdivision
      ) {
        return handle_msg(
          'failure_msg',
          'A zip or city is required.',
          'market_stats',
          true
        )
      }
      const data = {
        ...market_values,
      }
      const first_time_out_time = 3000
      handle_msg(
        'loading_msg',
        'Grabbing MLS Data...',
        'market_stats',
        first_time_out_time
      )
      setTimeout(() => {
        handle_msg(
          'loading_msg',
          'Crunching the numbers...',
          'market_stats',
          20000
        )
      }, first_time_out_time)
      await axiomFetch('/marketing/materials/load_market_stats', 'POST', data)

      if (state?.create_order?.vbc_market_stats) {
        handle_msg('loading_msg', 'Creating Layout...', 'market_stats', 20000)
        const quick_flyer = await generate_flyer_session({
          layout: state?.create_order?.current_selection,
        })
        quick_flyer.html = await replace_fields(
          quick_flyer?.layout_text,
          quick_flyer?.sess_info,
          quick_flyer
        )
        // quick_flyer.page_ranges = 'all'
        quick_flyer.upload_type = 'reviews'
        quick_flyer.file_name = quick_flyer?.layout_name
          ? quick_flyer.layout_name
              ?.toString()
              ?.replace(/\s+/g, ' ')
              ?.trim()
              ?.replace(/\s/g, '_')
              ?.replaceAll('-', '')
              ?.replace(/_+/g, '_')
          : ''
        // setState((prev) => ({
        //   ...prev,
        //   create_order: { ...prev?.create_order, vbc_market_stats: false },
        // }))
        console.log('quick_flyer', quick_flyer)
        handle_msg('loading_msg', 'Generating pdf...', 'market_stats', 20000)
        setCopied(false)
        quick_flyer.pdf_url = await generate_pdf(quick_flyer)
        setState((prev) => ({
          ...prev,
          create_order: {
            ...prev?.create_order,
            vbc_pdf_url: quick_flyer.pdf_url,
          },
        }))
        console.log('pdf', quick_flyer.pdf_url)
        open_url(quick_flyer.pdf_url)
        handle_msg(
          'success_msg',
          'Stats Marketing PDF Created!',
          'market_stats',
          true
        )
      } else {
        handle_msg(
          'loading_msg',
          'Creating Marketing Material...',
          'market_stats',
          20000
        )
        await load_session_from_layout({
          layout: state?.create_order?.current_selection,
        })
        handle_msg(
          'success_msg',
          'Stats Marketing Item Created!',
          'market_stats',
          true
        )
      }
    } catch (error) {
      handle_msg(
        'failure_msg',
        'Failed to generate stats',
        'market_stats',
        true
      )
    }
  }
  return (
    <div className='flex flex-col justify-between min-h-[530px]'>
      <div>
        <div className='px-3'>
          <p className={uiStyles.text_black_subtitle}>{title}</p>
        </div>
        <div className='px-3 mt-[10px]'>{get_inputs('market_data')}</div>
      </div>

      <div className='flex flex-col'>
        <div className='flex justify-end'>
          <div
            className={`mb-[20px] pr-[20px] text-end ${uiStyles.blue_grd_text}`}
          >
            {state?.create_order?.vbc_pdf_url ? (
              <div className='flex justify-end gap-[10px]'>
                <button
                  onClick={() => {
                    open_url(state?.create_order?.vbc_pdf_url)
                  }}
                >
                  Open PDF
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
          <CopyText text={state?.create_order?.vbc_pdf_url} />
        </div>
        {messageSent?.type === 'market_stats' ? (
          <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
            {messageSent.message}
          </div>
        ) : (
          <button
            className={`${uiStyles.hover_btn} rounded-none`}
            onClick={generate_stats}
          >
            Generate Stats
          </button>
        )}
      </div>
    </div>
  )
}

export default MarketingAddData
