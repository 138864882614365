import React from 'react'
import { useAiDaily } from '../../contexts/AiDailyContext'
import { getDateString } from '../../helpers'
import uiStyles from '../ui/uiStyles'
import { useNavigate } from 'react-router-dom'

const AiDailyHist = ({ setShowModal }) => {
  const {
    aiDaily,
    upd_daily_signup,
    saveAiDaily,
    gen_daily_resp,
    save_resp,
    save_post,
    save_content,
    save_slide,
    get_daily_prompt_by_id,
  } = useAiDaily()
  console.log('data', aiDaily.data)
  const navigate = useNavigate()
  return (
    <div className='flex flex-col gap-2 cursor-pointer'>
      {aiDaily?.data?.ai_prompts_daily_resp?.map((item) => {
        const curr_prompt = get_daily_prompt_by_id(item?.ai_prompts_daily_id)

        console.log('curr_prompt', curr_prompt)
        return (
          <div
            key={item?.resp_id}
            className={`${uiStyles.card_gray_gradient} ${
              aiDaily.post?.content?.resp_id === item?.resp_id
                ? 'border-[#0063F7] border-2'
                : ''
            }`}
            onClick={() => {
              navigate(`/user/ai/daily/${item?.resp_uuid}`)
              setShowModal(false)
            }}
          >
            <p>{curr_prompt?.prompt_title || `Day ${item?.day}`}</p>
            <p>
              created:{' '}
              {getDateString({
                date: item?.ins_dt,
                format: 'mmm dd, yyyy hh:mi am',
              })}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default AiDailyHist
