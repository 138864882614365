import { useState } from 'react'
import { AiOutlineCopy } from 'react-icons/ai'

const CopyText = ({ text = '', overlay = false, ...restProps }) => {
  const [copied, setCopied] = useState(false)
  return (
    <div {...restProps}>
      <div className={`flex relative ${copied ? '' : ''}`}>
        <div
          onClick={() => {
            console.log('hello')
            setCopied(true)
            navigator.clipboard.writeText(text)
            setTimeout(() => {
              setCopied(false)
            }, [500])
          }}
        >
          <AiOutlineCopy className='w-[20px] h-[20px] cursor-pointer' />
        </div>

        <p
          className={`${
            copied ? 'opacity-100 z-10' : 'opacity-100 z-[-1]'
          } transition-all duration-300  ${
            overlay ? 'absolute left-[-30px] rounded-lg p-1 bg-white' : ''
          }`}
        >
          Copied!
        </p>
      </div>
    </div>
  )
}

export default CopyText
